import React, {FC} from 'react';

import {HeadlineProps} from './interfaces';
import styles from './Headline.module.scss';

const Headline: FC<HeadlineProps> = ({className = '', children, level}) => {
    const Tag = `h${level}` as keyof JSX.IntrinsicElements;
    return (
        <Tag className={`${styles[`h${level}`]} ${className}`}>{children}</Tag>
    );
};

export default Headline;
