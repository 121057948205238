import React, {FC, memo} from 'react';
import {useGate, useStore} from 'effector-react';
import {ReactComponent as CloseIcon} from '@assets/icons/shared/Close.svg';
import {useCheckIfClickedOutside} from '@shared/hooks/useCheckIfClickedOutside';
import Text from '@shared/ui/Text';
import {$disabledForms, onCreateContrAgent, onEditUser} from '@models/Form';
import FormLoader from '@shared/ui/Loaders/FormLoader';
import Button from '@shared/ui/Button';
import {AccessesGate} from '@models/Sidebar/ContrАgents/Accesses';
import {
    $isOpenNewContrAgent,
    getContrAgentFx,
    getContrAgentsConfigFx,
    ModalContrAgentsGate, onArchive
} from '@models/Sidebar/ContrАgents';

import styles from '../PopupWindow.module.scss';

import ContAgentTabs from './components/ContrAgentTabs';
import ContrAgentsForm from './components/ContrAgentForm';
import ContrAgentLink from './components/ContrAgentLink';
import ContrAgentAccesses from './components/ContrAgentAccesses';
import {useContrAgentTabs} from './hooks/useContrAgentTabs';
import {usePopupContrAgent} from './hooks/usePopupContrAgent';
import {contrAgentsTabsItem, IPopupContrAgents} from './interfaces';

const PopupContAgent: FC<IPopupContrAgents> = ({onClose}) => {
    useGate(ModalContrAgentsGate);
    useGate(AccessesGate);
    const isLoadingConfig = useStore(getContrAgentsConfigFx.pending);
    const isLoadingUser = useStore(getContrAgentFx.pending);
    const stateModal = useStore($isOpenNewContrAgent);
    const disabledForm = useStore($disabledForms)['contr-agent-form'];

    const {
        popupRef, onMouseDown, contentRef
    } = useCheckIfClickedOutside({callback: onClose, ignoreContextMenu: true});
    const {tab, toggleTab} = useContrAgentTabs();
    const {form, statusId, onClear, onDelete} = usePopupContrAgent(onClose);

    return (
        <div
            ref={popupRef}
            className={styles.container}
            onMouseDown={onMouseDown}
        >
            <div ref={contentRef} className={`${styles.content} ${styles.autoWidth}`}>
                <div className={styles.header}>
                    <Text className={styles.title} type={'subtitle2'}>{`${stateModal === 'edit' ? 'Изменение' : 'Создание'} контрагента`}</Text>
                    <div className={`${styles.close} ${styles.icon}`} onClick={onClose}>
                        <CloseIcon className={styles.closeIcon}/>
                    </div>
                </div>

                <div className={styles.body}>
                    <ContAgentTabs tab={tab} toggleTab={toggleTab} isEditMode={stateModal === 'edit'}/>

                    {tab === contrAgentsTabsItem.MAIN ? (
                        (isLoadingConfig || isLoadingUser) ? (
                            <div className={styles.formLoader}>
                                <FormLoader repeat={7} rows={1}/>
                            </div>
                        ) : (
                            <ContrAgentsForm list={form}/>
                        )
                    ) : (
                        <ContrAgentAccesses/>
                    )}

                    {tab === contrAgentsTabsItem.MAIN && (
                        <React.Fragment>
                            <div className={styles.center}>
                                {stateModal === 'create' ? (
                                    <Button
                                        className={styles.contrAgentButton}
                                        onClick={onClear}
                                        theme={'cancel'}
                                        size={'md'}
                                        text={'Очистить'}
                                    />
                                ) : (
                                    <>
                                        <Button
                                            className={styles.contrAgentButton}
                                            onClick={onDelete}
                                            theme={'cancel'}
                                            size={'md'}
                                            text={'Удалить'}
                                        />
                                        {(statusId && statusId !== 1) && (
                                            <Button
                                                className={styles.contrAgentButton}
                                                onClick={() => onArchive(statusId === 2 ? 3 : 2)}
                                                theme={'cancel'}
                                                size={'md'}
                                                text={statusId === 2 ? 'Архивировать' : 'Разархивировать'}
                                            />
                                        )}
                                    </>
                                )}
                                <Button
                                    className={styles.contrAgentButton}
                                    onClick={stateModal === 'edit' ? onEditUser : onCreateContrAgent}
                                    size={'md'}
                                    text={stateModal === 'edit' ? 'Изменить' : 'Создать'}
                                    disabled={disabledForm}
                                />
                            </div>
                            <ContrAgentLink statusId={statusId}/>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};

export default memo(PopupContAgent);
