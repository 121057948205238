import {IGenericIndexes, IPopupChild, IPopupParent} from './interfaces';

export const maxPopupWidth = 610;

export const getIndexes = (list: IPopupParent[] | IPopupChild[]): IGenericIndexes => {
    const result: IGenericIndexes = {};

    list.forEach((row, index) => {
        if (row.endCategory) {
            result[`id_${index + 1}`] = true;
        }
    });

    return result;
};
