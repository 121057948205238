import {ChangeEvent} from 'react';
import {createEffect, createEvent, createStore} from 'effector';
import {createGate} from 'effector-react';
import {IPayloadTitlesInPackage} from '@models/Table/interfaces';
import {
    getPackages,
    setPackage,
    deletePackage,
    editPackage,
    addTitlesInPackage
} from '@shared/api/endpoints/sidebar';

import {IPackage} from './interfaces';

export const getPackagesFx = createEffect<any, IPackage[], Error>(getPackages);
export const setPackagesFx = createEffect<any, IPackage | void, Error>(setPackage);
export const deletePackageFx = createEffect<any, IPackage | void, Error>(deletePackage);
export const editPackageFx = createEffect<any, IPackage | void, Error>(editPackage);
export const addTitlesInPackageFx = createEffect<IPayloadTitlesInPackage, any, Error>(addTitlesInPackage);

export const onChangeNewPackage = createEvent<ChangeEvent<HTMLInputElement>>();
export const onInputFilter = createEvent<string>();
export const onSetPackage = createEvent<number>();
export const onResetPackageForm = createEvent<void>();
export const onSendPackage = createEvent<void>();
export const onDeletePackage = createEvent<number>();
export const onEditPackage = createEvent<void>();
export const updatePackages = createEvent<IPackage[]>();
export const setDefaultPackageName = createEvent<string>();
export const setEditPackageId = createEvent<number>();
export const onForcedLoadPackages = createEvent<void>();
export const onAddTitlesInPackage = createEvent<IPayloadTitlesInPackage>();

export const PackagesGate = createGate();

export const $packageQuery = createStore<string>('')
    .on(onInputFilter, (_, query) => query);

export const $packages = createStore<IPackage[]>([])
    .on(updatePackages, (_, data) => data)
    .on(setPackagesFx.doneData, (state, newPackage) =>
        newPackage ? [...state, newPackage] : state)
    .on(getPackagesFx.doneData, (_, data) => data);

export const $currentPackage = createStore<number>(-1)
    .on(onSetPackage, (_, title) => title);
export const $editPackageId = createStore<number>(-1)
    .on(setEditPackageId, (_, id) => id);

export const $newPackage = createStore<string>('')
    .on(setDefaultPackageName, (_, value) => value)
    .on(onResetPackageForm, () => '')
    .on(onChangeNewPackage, (_, e) => e.target.value);
