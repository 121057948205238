import {FC, memo} from 'react';
import {ReactComponent as LoaderIcon} from '@assets/icons/shared/Loader.svg';

import styles from './Button.module.scss';
import {ButtonProps} from './interfaces';

const Button: FC<ButtonProps> = (props) => {
    const {className, isLoading, size = 'lg', text, theme = 'default', ...buttonProps} = props

    if (isLoading) {
        return (
            <div className={`${styles.loader}${className ? ' ' + className : ''}`}>
                <LoaderIcon/>
            </div>
        )
    }

    return (
        <button
            {...buttonProps}
            className={`${styles.container} ${styles[theme]}${className ? ' ' + className : ''} ${styles[size]}`}
            type='button'
        >
            {text}
        </button>
    );
};

export default memo(Button);
