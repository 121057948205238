export const logoPlugStyles: {[key: string]: string} = {
    'А': '#FF5F00',
    'Б': '#FF3E24',
    'В': '#DC22EC',
    'Г': '#8133FF',
    'Д': '#FF5F00',
    'Е': '#FF3E24',
    'Ё': '#DC22EC',
    'Ж': '#8133FF',
    'З': '#FF5F00',
    'И': '#FF3E24',
    'Й': '#DC22EC',
    'К': '#8133FF',
    'Л': '#FF5F00',
    'М': '#FF3E24',
    'Н': '#DC22EC',
    'О': '#8133FF',
    'П': '#FF5F00',
    'Р': '#FF3E24',
    'С': '#DC22EC',
    'Т': '#8133FF',
    'У': '#FF5F00',
    'Ф': '#FF3E24',
    'Х': '#DC22EC',
    'Ц': '#8133FF',
    'Ч': '#FF5F00',
    'Ш': '#FF3E24',
    'Щ': '#DC22EC',
    'Ы': '#8133FF',
    'Э': '#FF5F00',
    'Ю': '#FF3E24',
    'Я': '#DC22EC'
};

export const textLvl = {
    'sm': 6,
    'sm-md': 6,
    'md': 3,
    'lg': 2,
    'edit': 3
};
