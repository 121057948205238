import React, {FC, memo, MouseEvent} from 'react';
import Text from '@shared/ui/Text';
import Logo from '@shared/ui/Logo';

import styles from './User.module.scss';
import {IUser} from './interfaces';

const User: FC<IUser> = ({id, login, logo, status, name, onSelect, onContextMenu}) => {
    const onLocalContextMenu = (e: MouseEvent<HTMLDivElement>) => {
        if (onContextMenu) {
            onSelect({id, login});
            onContextMenu(e);
        }
    };

    return (
        <div className={styles.container} onContextMenu={onLocalContextMenu}>
            <Logo src={logo} name={name}/>

            <div className={styles.information}>
                <Text type={'body2'} className={styles.name}>{name}</Text>
                <Text type={'caption'} className={styles.status}>{status}</Text>
            </div>
        </div>
    );
};

export default memo(User);
