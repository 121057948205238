import {createEvent, createStore} from 'effector';
import {navIds} from '@models/Sidebar/interfaces';

export const setList = createEvent<any>();
export const setActiveList = createEvent<any>();
export const setSideBarWidth = createEvent<number>();
export const setSectionId = createEvent<navIds>();
export const setIsHideSidebar = createEvent<any>();

export const $lists = createStore<any>({
    [navIds.Packages]: 'myPackages'
})
    .on(setActiveList, (state, {section, key}) => ({...state, [section]: key}))
    .on(setList, (state, {section, key}) => ({...state, [section]: state[section] === key ? 'none' : key}));
export const $sideBarWidth = createStore<number>(314)
    .on(setSideBarWidth, (_, width) => width);

export const $sectionId = createStore<navIds>(navIds.Packages)
    .on(setSectionId, (_, section) => section);

export const $isHideSidebar = createStore(false)
    .on(setIsHideSidebar, state => !state);

