import {useCallback, useEffect, useMemo, useState} from 'react';
import {useStore} from 'effector-react';
import {onResetForm} from '@models/Form';
import {
    $contrAgentConfig,
    $currentContrAgent,
    $isOpenNewContrAgent,
    onDeleteContrAgent
} from '@models/Sidebar/ContrАgents';
import {IFields} from '@shared/components/Fields/interfaces';
import {deepCopy} from '@shared/utils/deepCopy';

export const usePopupContrAgent = (onClose: () => void) => {
    const [form, setForm] = useState<IFields[]>([]);
    const {config} = useStore($contrAgentConfig);
    const currentUser = useStore($currentContrAgent);
    const stateModal = useStore($isOpenNewContrAgent);

    const statusId = useMemo(() => currentUser?.statusId, [currentUser]);

    const onClear = () => onResetForm('contr-agent-form');
    const onDelete = useCallback(() => {
        const isAccess = confirm('Вы действительно хотите удалить контрагента?');
        if (currentUser && isAccess) {
            onDeleteContrAgent(currentUser.id);
            onClose();
        }
    }, [currentUser]);

    useEffect(() => {
        if (stateModal === 'create') {
            onClear();
            return setForm(config as IFields[]);
        }

        if (stateModal === 'edit' && currentUser) {
            onClear();
            const customData = deepCopy(currentUser.customData);

            for (let i = 0; i < customData.length; i++) {
                if (customData[i].type === 'file') {
                    customData[i].value = currentUser.logo;
                }

                if (customData[i].id === 1) {
                    customData[i].value = currentUser.name;
                }
            }

            return setForm(customData);
        }
    }, [currentUser, config, stateModal]);

    return {
        form, statusId, onClear, onDelete
    };
};
