import {useEffect, useState} from 'react';

import {ITileLabels, IUseTile} from '../interfaces';

export const useTile = ({head, availableCols}: IUseTile) => {
    const [labels, setLabels] = useState<ITileLabels>({});

    useEffect(() => {
        const newLabels: ITileLabels = {};

        head.forEach(({id, title}) => {
            if (!availableCols || !id) {
                return undefined;
            }

            if (availableCols[id] && id !== 'type' && id !== 'status') {
                newLabels[id] = title;
            }
        });

        setLabels(newLabels);
    }, [head, availableCols]);

    return {labels};
};
