import {useCallback, useEffect} from 'react';
import {useStore} from 'effector-react';
import {deepCopy} from '@shared/utils/deepCopy';
import {SelectorItem} from '@shared/components/Selector/interfaces';
import {$form, onChangeForm} from '@models/Form';

import {IUseField} from '../interfaces';

export const useFieldMultipleSelect = ({id, value, formKey, onSave}: IUseField) => {
    const localValue = (useStore($form)?.[formKey]?.[id]  as SelectorItem[] | undefined);

    const onSelect = useCallback((value: SelectorItem, selectId: number) => {
        if (!localValue) {
            return undefined;
        }

        const state = deepCopy(localValue);

        state[selectId] = value;

        if (onSave) {
            onSave({value: state, fieldId: id});
        }
        onChangeForm({form: formKey, field: id, value: state});
    }, [id, localValue]);

    const onAdd = useCallback(() => {
        if (!localValue) {
            return undefined;
        }

        const state = deepCopy(localValue);
        onChangeForm({form: formKey, field: id, value: [...state, {id: -1, title: ''}]});
    }, [localValue]);

    const onDelete = useCallback((selectId: number) => {
        if (!localValue) {
            return undefined;
        }

        const state: SelectorItem[] = deepCopy(localValue);
        const newState: SelectorItem[] = state.filter((_, index) => index !== selectId);

        if (onSave) {
            onSave({value: newState, fieldId: id});
        }
        onChangeForm({form: formKey, field: id, value: newState});
    }, [localValue, id]);

    useEffect(() => {
        const newValue = ((value && (value as SelectorItem[]).length) ? value : [{id: -1, title: ''}]) as SelectorItem[];
        onChangeForm({form: formKey, field: id, value: localValue ?? newValue});
    }, [value]);

    return {
        localValue, onAdd, onSelect, onDelete
    };
};
