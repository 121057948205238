import {DragEvent, MouseEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {TCell} from '@models/Table/interfaces';
import {IUseRow} from '@shared/components/Table/components/Row/interfaces';

export const useRow = ({head, links, data, onDragEnd, onDragStart, onSelect, onContextMenu, isUseContext}: IUseRow) => {
    const [isDrag, setIsDrag] = useState<boolean>(false);
    const rowRef = useRef<HTMLDivElement>(null);

    const rowId = useMemo(() => String(data.id), [data]);

    const onLocalContextMenu = useCallback((e: MouseEvent<HTMLDivElement>) => {
        if (rowId !== null && isUseContext) {
            onContextMenu(e);
            onSelect(rowId);
        }
    }, [rowId, isUseContext]);

    const onLocalDragStart = (e: DragEvent<HTMLDivElement>) => {
        onDragStart(e, rowId);
        setIsDrag(true);
    };

    const onLocalDragEnd = () => {
        onDragEnd();
        setIsDrag(false);
    };

    const getType = useCallback((cell: string) => {
        let result: TCell = 'string';
        head.forEach(({id, type}) => {
            if (id === cell) {
                result = type;
            }
        });
        return result;
    }, [head]);

    useEffect(() => {
        if (rowId && rowRef) {
            links.current[String(rowId)] = rowRef;
        }
    }, [rowId, rowRef]);

    return {
        rowRef, rowId, isDrag, onLocalDragEnd, onLocalDragStart, onLocalContextMenu, getType
    };
};
