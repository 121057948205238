import {createEffect, createEvent, createStore} from 'effector';
import {createUser, editUser} from '@shared/api/endpoints/user';
import {IContrAgent, IContrAgentEdit} from '@models/Sidebar/ContrАgents/interfaces';

import {IDisabledForm, IDisabledForms, IForm, IFormPayload} from './interfaces';

export const createContrAgentFx = createEffect<FormData, any, Error>(createUser);
export const editUserFx = createEffect<IContrAgentEdit, IContrAgent | null, Error>(editUser);

export const onChangeForm = createEvent<IFormPayload>();
export const onActivateDropZone = createEvent<boolean>();
export const onCreateContrAgent = createEvent<any>();
export const onResetForm = createEvent<string>();
export const onEditUser = createEvent<any>();
export const onDisabledForm = createEvent<IDisabledForm>();

export const $form = createStore<IForm>({})
    .on(onResetForm, (state, key) => ({...state, [key]: {}}))
    .on(onChangeForm, (state, {form, field, value}) =>
        ({...state, [form]: {...state[form], [field]: value}}));

export const $disabledForms = createStore<IDisabledForms>({})
    .on(onDisabledForm, (state, {key, value}) => ({...state, [key]: value}));

export const $formDropZone = createStore<boolean>(false)
    .on(onActivateDropZone, (_, flag) => flag);
