import {$clipbins} from '@shared/api/config';
import {setAlert} from '@models/Alert';
import {ERROR_REQUEST} from '@shared/constants/common';
import {IPackage} from '@models/Sidebar/Packages/interfaces';
import {
    IPayloadTitlesInPackage,
    ITableConfig,
    ITableConfigRequest,
    ITableData,
    ITableRequest
} from '@models/Table/interfaces';
import {voidGridConfig, voidGridState} from '@shared/api/utils';
import {
    IChangeAccessPayload,
    IGetAvailablePackages,
    IUserPackages
} from '@models/Sidebar/ContrАgents/Accesses/interfaces';

export const getPackages = async (query = ''): Promise<IPackage[]> => {
    try {
        const {data, success, message} = await $clipbins.post('/list', {query});

        if (success) {
            return data;
        }

        setAlert({type: 'error', description: message, title: 'Ошибка при получении пакетов'});
        return [];
    } catch (e) {
        setAlert(ERROR_REQUEST);
        return []
    }
};

export const setPackage = async (name: string): Promise<IPackage | void> => {
    try {
        const {data, success, message} = await $clipbins.post('/create', {name});

        if (success) {
            return data;
        }

        setAlert({type: 'error', description: message, title: 'Ошибка при создании пакета'});
    } catch (e) {
        setAlert(ERROR_REQUEST);
    }
};

export const deletePackage = async (id: number): Promise<IPackage | void> => {
    try {
        const {data, success, message} = await $clipbins.delete('/delete', {id});

        if (success) {
            return data;
        }

        setAlert({type: 'error', description: message, title: 'Ошибка при удалении пакета'});
    } catch (e) {
        setAlert(ERROR_REQUEST);
    }
};

export const editPackage = async ({id, name}: {id: number, name: string}): Promise<IPackage | void> => {
    try {
        const {data, success, message} = await $clipbins.post('/edit', {id, name});

        if (success) {
            return data;
        }

        setAlert({type: 'error', description: message, title: 'Ошибка при изменении пакета'});
    } catch (e) {
        setAlert(ERROR_REQUEST);
    }
};

export const getPackageConfig = async ({categoryId = 1, sorting}: ITableConfigRequest): Promise<ITableConfig> => {
    try {
        if (categoryId === -1) {
            return voidGridConfig;
        }

        const {data, success, message} = await $clipbins.post('/config', {id: categoryId, ...sorting});

        if (success) {
            return data;
        }

        setAlert({type: 'error', description: message, title: 'Ошибка при получении схемы таблицы'});
        return voidGridConfig;
    } catch (err) {
        setAlert(ERROR_REQUEST);
        return voidGridConfig;
    }
};

export const getPackageTable = async ({page = 1, limit = 40, categoryId = 1}: ITableRequest): Promise<ITableData> => {
    try {
        const {data, success, message} = await $clipbins.post(`/${categoryId}/titles`, {page, limit});

        if (success) {
            return data;
        }

        setAlert({type: 'error', description: message, title: 'Ошибка при получении данных таблицы'});
        return voidGridState;
    } catch (err) {
        setAlert(ERROR_REQUEST);
        return voidGridState;
    }
};

export const addTitlesInPackage = async ({id, titleIds}: IPayloadTitlesInPackage) => {
    try {
        const {success, message} = await $clipbins.post('/titles/add', {id, titleIds});

        if (!success) {
            return setAlert({type: 'error', description: message, title: 'Ошибка при добавлении материалов в пакет'});
        }

        return setAlert({type: 'success', title: 'Материалы добавлены в пакет'});
    } catch (e) {
        setAlert(ERROR_REQUEST);
    }
};

export const changeUserAccess = async ({id, userId, type}: IChangeAccessPayload) => {
    try {
        const {success, message} = await $clipbins.post(`/access/${type}`, {id, userId});

        if (!success) {
            setAlert({type: 'error', description: message, title: 'Ошибка при смене доступа'});
        }
    } catch (e) {
        setAlert(ERROR_REQUEST);
    }
};

export const getAvailablePackages = async ({userId, query}: IGetAvailablePackages): Promise<IUserPackages[]> => {
    try {
        const {data, success, message} = await $clipbins.post(`/user/${userId}`, {query});

        if (success) {
            return data;
        }

        setAlert({type: 'error', description: message, title: 'Ошибка при получении доступных пакетов'});
        return [];
    } catch (e) {
        setAlert(ERROR_REQUEST);
        return [];
    }
};
