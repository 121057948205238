import React, {memo} from 'react';
import {ReactComponent as CloseIcon} from '@assets/icons/Player/Close.svg';
import Text from '@shared/ui/Text';
import {toggleOpenSettings} from '@models/Settings';

import styles from './SettingsHeader.module.scss';

const SettingsHeader = () => {
    return (
        <div className={styles.container}>
            <Text type={'subtitle2'}>{'Настройки'}</Text>
            <div className={styles.closeIcon} onClick={toggleOpenSettings}>
                <CloseIcon/>
            </div>
        </div>
    );
};

export default memo(SettingsHeader);
