import React, {memo, FC} from 'react';
import {useStore} from 'effector-react';
import {$currentPopupID} from '@models/PopupActions';
import {$form} from '@models/Form';
import {usePopup} from '@shared/ui/Popups/PopupActions/hooks/usePopup';
import {IPopupChild, IPopupParent} from '@shared/ui/Popups/PopupActions/interfaces';
import PopupActions from '@shared/ui/Popups/PopupActions';
import LogoPlug from '@shared/ui/LogoPlug';
import Text from '@shared/ui/Text';
import Logo from '@shared/ui/Logo';

import styles from './ContrAgentFile.module.scss';
import {IContrAgentFile} from './interfaces';
import {useUploadLogo} from './useUploadLogo';
import {contextMenuFile} from './utils';

const ContrAgentFile: FC<IContrAgentFile> = ({placeholder, value}) => {
    const currentPopupID = useStore($currentPopupID);
    const name = (useStore($form)?.['contr-agent-form']?.[1] as string | undefined);

    const {isActive, localUrl, onOver, onDrop, onLeave, onChange, onClick, onCLear} = useUploadLogo(value);

    const {
        containerRef, contextMenuCoords, popupRef, isLeftMode, popupID,
        onStopPropagation, onContextMenu, onClose
    } = usePopup();

    const onPopupAction = (target: IPopupParent | IPopupChild) => {
        switch (target.name) {
            case 'clear':
                onCLear();
                break;
            default: break;
        }
        onClose();
    };

    return (
        <React.Fragment>
            <div className={styles.container}>
                <Text type={'caption'} className={styles.label}>{'Логотип'}</Text>

                <div className={styles.wrapper}>
                    {(!localUrl && name && name.length) ? (
                        <div className={styles.plug}>
                            <LogoPlug name={name} isCircle={false} scale={'edit'}/>
                        </div>
                    ) : (
                        localUrl && (
                            <div onContextMenu={onContextMenu} className={styles.image}>
                                <Logo src={localUrl} name={name ?? ''} isCircle={false} scale={'edit'}/>
                            </div>
                        )
                    )}

                    <label
                        htmlFor={'upload-image'}
                        className={`${styles.zone} ${isActive ? styles.active : ''}`}
                        onClick={onClick}
                        onDrop={onDrop}
                        onDragOver={onOver}
                        onDragLeave={onLeave}
                    >
                        <Text type={'body1'} className={styles.text}>{placeholder}</Text>
                    </label>
                </div>
            </div>
            <input
                id={'upload-image'}
                type={'file'}
                className={styles.hidden}
                onChange={onChange}
            />

            <div
                ref={containerRef}
                onClick={onStopPropagation}
                onContextMenu={onStopPropagation}
                className={styles.contextMenu}
                style={(currentPopupID === popupID && contextMenuCoords) ? contextMenuCoords : {visibility: 'hidden'}}
            >
                <PopupActions
                    refObj={popupRef}
                    list={contextMenuFile}
                    onClick={onPopupAction}
                    isLeftMode={isLeftMode}
                />
            </div>
        </React.Fragment>
    );
};

export default memo(ContrAgentFile);
