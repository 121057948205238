import {sample} from 'effector';
import {reset} from 'patronum';
import {$alertData} from '@models/Alert';
import {$isOpenSettings} from '@models/Settings';
import {changePasswordFx} from '@models/User/changePassword';
import {changeLoginFx} from '@models/User/changeLogin';
import {$isHideSidebar, $sectionId, $sideBarWidth} from '@models/Sidebar';

import './changePassword/init';
import './changeLogin/init';
import {
    logoutFx,
    onLogout,
    loginFx,
    onLogin,
    $loginForm,
    AuthGate,
    onGetToken,
    getTokenFx,
    tickAuthRefresh,
    DashboardGate,
    onStartAuthCounter,
    $userData,
    onActivateAccount,
    activateAccountFx,
    registrationFx,
    onRegistration,
    $activationKey,
    setIsAdmin,
    $isAdmin,
    RegistrationGate, $isAllowedUser
} from '.';

sample({
    clock: [AuthGate.open, onGetToken],
    target: getTokenFx
});

sample({
    clock: DashboardGate.open,
    target: onStartAuthCounter
});

sample({
    clock: onLogin,
    source: $loginForm,
    target: loginFx
});

sample({
    clock: onRegistration,
    source: {form: $loginForm, key: $activationKey},
    fn: (data) => ({...data.form, activationKey: data.key}),
    target: registrationFx
});

sample({
    clock: registrationFx.doneData,
    fn: () => true,
    target: $isAllowedUser
});

sample({
    clock: tickAuthRefresh,
    target: getTokenFx,
});

sample({
    clock: onLogout,
    target: logoutFx,
});

sample({
    clock: onActivateAccount,
    target: activateAccountFx
});

sample({
    clock: $userData,
    fn: (data) => data.roleIds.includes(1),
    filter: (data) => data?.roleIds,
    target: setIsAdmin
});

sample({
    clock: RegistrationGate.open,
    fn: () => window.location.search.replace('?key=', ''),
    target: onActivateAccount
});

reset({
    clock: onLogout,
    target: [$alertData, $isOpenSettings, $isAdmin, $userData, $sectionId, $sideBarWidth, $isHideSidebar],
});
