import React, {FC, memo} from 'react';
import {ReactComponent as CloseIcon} from '@assets/icons/shared/Close.svg';
import {ReactComponent as SelectIcon} from '@assets/icons/shared/Select.svg';
import {ReactComponent as DeleteIcon} from '@assets/icons/MetaEditor/Delete.svg';
import {useCheckIfClickedOutside} from '@shared/hooks/useCheckIfClickedOutside';
import {IPopupTable} from '@shared/ui/Popups/PopupTable/interfaces';
import Button from '@shared/ui/Button';
import Table from '@shared/components/Table';
import Text from '@shared/ui/Text';

import styles from '../PopupWindow.module.scss';

const PopupTable: FC<IPopupTable> = ({
  tableContent, title = '',
  isHidePanelControl = false, isHideButtons = false, panelControlTitle = '',
  onClose, onSuccess = () => {}, onDelete = () => {}
}) => {
    const {popupRef, onMouseDown, contentRef} = useCheckIfClickedOutside({callback: onClose});

    return (
        <div
            ref={popupRef}
            className={styles.container}
            onMouseDown={onMouseDown}
        >
            <div ref={contentRef} className={styles.tableContent}>
                <div className={styles.header}>
                    <Text className={styles.title} type='subtitle2'>{title}</Text>
                    <div className={`${styles.close} ${styles.icon}`} onClick={onClose}>
                        <CloseIcon className={styles.closeIcon}/>
                    </div>
                </div>
                <div className={styles.body}>
                    {!isHidePanelControl && (
                        <div className={styles.panelControl}>
                            <Text type={'body2'}>{panelControlTitle}</Text>
                            <div className={styles.iconWrapper}>
                                <div className={styles.selectIcon}><SelectIcon/></div>
                                <div className={styles.icon}><CloseIcon/></div>
                                <div className={styles.icon}><DeleteIcon/></div>
                            </div>
                        </div>
                    )}
                    <div className={styles.table}>
                        <Table
                            tableName={'some-table'}
                            head={tableContent.head}
                            body={tableContent.body}
                            nextPage={() => {}}
                            totalAmount={0}
                            setSorting={(data: any) => {}}
                            isUseContext={false}
                        />
                    </div>
                    {!isHideButtons && (
                        <div className={styles.buttonsWrapper}>
                            <div>
                                <Button onClick={onSuccess} size={'md'} className={styles.button} text='Готово'/>
                                <Button onClick={onDelete} size={'md'} className={styles.button} text='Удалить' theme='cancel'/>
                            </div>
                            <Button onClick={onClose} size={'md'} className={styles.button} text='Отмена' theme='cancel'/>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default memo(PopupTable);
