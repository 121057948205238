import {useCallback, useEffect} from 'react';
import {$form, onChangeForm} from '@models/Form';
import {useStore} from 'effector-react';

import {IUseField} from '../interfaces';

export const useFieldDate = ({id, value, formKey, onSave}: IUseField) => {
    const localValue = (useStore($form)?.[formKey]?.[id]  as number | null);

    const onChange = useCallback((ms: number | null) => {
        if (onSave) {
            onSave({value: ms, fieldId: id});
        }
        onChangeForm({form: formKey, field: id, value: ms});
    }, [id]);

    useEffect(() => {
        onChangeForm({form: formKey, field: id, value: localValue ?? Number(value) ?? null});
    }, [value]);

    return {localValue, onChange};
};
