import React, {memo, FC} from 'react';
import {SelectorItem} from '@shared/components/Selector/interfaces';

import FieldInput from '@shared/components/Fields/FieldInput';
import FieldMultipleInput from '@shared/components/Fields/FieldMultipleInput';

import FieldSelect from '@shared/components/Fields/FieldSelect';
import FieldMultipleSelect from '@shared/components/Fields/FieldMultipleSelect';

import FieldDate from '@shared/components/Fields/FieldDate';
import FieldMultipleDate from '@shared/components/Fields/FieldMultipleDate';

import ContrAgentFile from '../ContrAgentFile';

import styles from './ContrAgentForm.module.scss';
import {IContrAgentForm} from './interfaces';

const ContrAgentForm: FC<IContrAgentForm> = ({list}) => {
    const formKey = 'contr-agent-form';

    return (
        <div className={styles.container}>
            {
                list.map(({isRequired, isReadOnly, type, options, value, id, label}) => {
                    if (!id) {
                        return <></>;
                    }

                    return (
                        <div key={`filed-${id}`} className={styles.field}>
                            {type === 'file' &&
                              <ContrAgentFile placeholder={label} value={value as string | null}/>
                            }

                            {(type === 'text' || type === 'duration') &&
                              <FieldInput
                                value={value as string | null}
                                id={id}
                                label={label}
                                isRequired={isRequired}
                                isReadOnly={isReadOnly}
                                formKey={formKey}
                                durationMode={type === 'duration'}
                              />
                            }

                            {type === 'multiple-text' &&
                              <FieldMultipleInput
                                value={value as string[] | null}
                                id={id}
                                label={label}
                                isReadOnly={isReadOnly}
                                isRequired={isRequired}
                                formKey={formKey}
                              />
                            }

                            {(type === 'select' && options) &&
                              <FieldSelect
                                value={value as SelectorItem | null}
                                id={id}
                                label={label}
                                options={options}
                                isReadOnly={isReadOnly}
                                isRequired={isRequired}
                                filter={false}
                                formKey={formKey}
                              />
                            }

                            {(type === 'multiple-select' && options) &&
                              <FieldMultipleSelect
                                value={value as SelectorItem[] | null}
                                id={id}
                                label={label}
                                options={options}
                                isReadOnly={isReadOnly}
                                isRequired={isRequired}
                                formKey={formKey}
                              />
                            }

                            {type === 'date' &&
                              <FieldDate
                                id={id}
                                label={label}
                                value={value as string | null}
                                isReadOnly={isReadOnly}
                                isRequired={isRequired}
                                formKey={formKey}
                              />
                            }

                            {type === 'multiple-date' &&
                              <FieldMultipleDate
                                id={id}
                                label={label}
                                value={value as string[] | null}
                                isReadOnly={isReadOnly}
                                isRequired={isRequired}
                                formKey={formKey}
                              />
                            }
                        </div>
                    );
                })
            }
        </div>
    );
};

export default memo(ContrAgentForm);
