import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import MainPage from './pages/Main';
import LoginPage from './pages/Login';
import Dashboard from './pages/Dashboard';
import RegistrationPage from './pages/Registration';
import MainLayout from './shared/layouts';
import AuthLayout from './shared/layouts/Auth';
import ErrorBoundary from './shared/components/ErrorBoundary';

const App = () => {
    return (
        <ErrorBoundary>
            <Routes>
                <Route element={<MainLayout/>}>
                    <Route path={'/'} element={<Navigate to={'/login'}/>}/>
                    <Route path={'/login'} element={<LoginPage/>}/>
                    <Route path={'/activate'} element={<RegistrationPage/>}/>
                    <Route element={<AuthLayout/>}>
                        <Route path={'/'} element={<Navigate to={'/dashboard'}/>}/>
                        <Route path={'/dashboard'} element={<Dashboard/>}/>
                    </Route>
                </Route>
            </Routes>
        </ErrorBoundary>
    );
};

export default App;
