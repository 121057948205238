import React, {FC, memo} from 'react';
import {createRange} from '@shared/utils/generators';

import {IUsersLoader} from './interfaces';
import styles from './UsersLoader.module.scss';

const UsersLoader: FC<IUsersLoader> = ({repeat = 1}) => {
    return (
        <>
            {createRange(repeat).map(block => (
                <div key={`block-${block}`} className={styles.container}>
                    <div className={styles.avatar}/>
                    <div className={styles.bio}>
                        <div className={styles.name}/>
                        <div className={styles.status}/>
                    </div>
                </div>
            ))}
        </>
    );
};

export default memo(UsersLoader);
