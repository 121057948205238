import {$settings} from '@shared/api/config';
import {setAlert} from '@models/Alert';
import {ERROR_REQUEST} from '@shared/constants/common';
import {ISettingsConfig} from '@models/Settings/interfaces';
import {voidSettingsConfig} from '@shared/api/utils';

export const setSettingsConfig = async (payload: ISettingsConfig): Promise<void> => {
    if (!payload) {
        return undefined;
    }

    try {
        const {message, success} = await $settings.post('/save', payload);

        if (success) {
            setAlert({type: 'success', title: 'Конфигурация обновлена'});
        } else {
            setAlert({type: 'error', description: message, title: 'Ошибка при обновлении конфигурации'});
        }
    } catch (e) {
        setAlert(ERROR_REQUEST);
    }
};

export const getSettingsConfig = async (typeId: number): Promise<ISettingsConfig> => {
    try {
        const {data, message, success} = await $settings.post('/get', {typeId});

        if (success) {
            return data;
        }

        setAlert({type: 'error', description: message, title: 'Ошибка при получении конфигурации'});

        return voidSettingsConfig;
    } catch (e) {
        setAlert(ERROR_REQUEST);

        return voidSettingsConfig;
    }
};
