import {ReactComponent as ErrorIcon} from '@assets/icons/shared/Alert/ErrorIcon.svg';
import {ReactComponent as InfoIcon} from '@assets/icons/shared/Alert/InfoIcon.svg';
import {ReactComponent as SuccessIcon} from '@assets/icons/shared/Alert/SuccessIcon.svg';
import {IAlertUtils} from "@shared/ui/Alert/interfaces";

export const icons: IAlertUtils = {
    error: <ErrorIcon/>,
    info: <InfoIcon/>,
    success: <SuccessIcon/>
}
