import React, {FC, TextareaHTMLAttributes, memo} from 'react';

import styles from './Textarea.module.scss';

const Textarea: FC<TextareaHTMLAttributes<HTMLTextAreaElement>> = (props) => {
    return (
        <div className={`${props.className} ${styles.container}`}>
            <textarea {...props} className={styles.textarea}/>
        </div>
    );
};

export default memo(Textarea);
