import {createEffect, createEvent, createStore} from 'effector';
import {createGate, useStore} from 'effector-react';
import {IContrAgent, IContrAgentStatus, TContrAgentModal} from '@models/Sidebar/ContrАgents/interfaces';
import {ISettingsConfig} from '@models/Settings/interfaces';
import {voidSettingsConfig} from '@shared/api/utils';
import {getSettingsConfig} from '@shared/api/endpoints/settings';
import {createContrAgentFx} from '@models/Form';
import {contrAgentsTabsItem} from '@shared/ui/Popups/PopupContAgent/interfaces';
import {
    changeUserStatus,
    deleteContrAgent,
    getActivationKey,
    getContrAgent,
    getContrAgents,
    getOldActivationKey
} from '@shared/api/endpoints/user';
import {contrAgentsTabsTitle} from '@shared/ui/Popups/PopupContAgent/utils';

export const getContrAgentsFx = createEffect<any, IContrAgent[], Error>(getContrAgents);
export const getContrAgentsConfigFx = createEffect<number, ISettingsConfig, Error>(getSettingsConfig);
export const getActivationKeyFx = createEffect<number, string, Error>(getActivationKey);
export const getContrAgentFx = createEffect<number, IContrAgent | null, Error>(getContrAgent);
export const deleteContrAgentFx = createEffect<number, any, Error>(deleteContrAgent);
export const getOldActivationKeyFx = createEffect<number, string, Error>(getOldActivationKey);
export const changeUserStatusFx = createEffect<IContrAgentStatus, any, Error>(changeUserStatus);


export const onInputQuery = createEvent<string>();
export const onForcedLoadContrAgents = createEvent<void>();
export const toggleCreateContrAgents = createEvent<TContrAgentModal>();
export const updateContrAgents = createEvent<IContrAgent>();
export const updateActivationKey = createEvent<any>();
export const setContrAgentId = createEvent<number>();
export const onDeleteContrAgent = createEvent<number>();
export const replaceContrAgents = createEvent<IContrAgent[]>();
export const onClearCurrentContrAgent = createEvent<void>();
export const onArchive = createEvent<number>();
export const onChangeEditContrAgentTab = createEvent<contrAgentsTabsItem>();

export const ContrAgentsGate = createGate();
export const ModalContrAgentsGate = createGate();

export const $contrAgentsQuery = createStore<string>('')
    .on(onInputQuery, (_, query) => query);
export const $contrAgents = createStore<IContrAgent[]>([])
    .on(replaceContrAgents, (_, list) => list)
    .on(updateContrAgents, (state, contrAgent) => [...state, contrAgent])
    .on(getContrAgentsFx.doneData, (_, data) => data);
export const $isOpenNewContrAgent = createStore<TContrAgentModal>('none')
    .on(toggleCreateContrAgents, (_, flag) => flag);
export const $contrAgentConfig = createStore<ISettingsConfig>(voidSettingsConfig)
    .on(getContrAgentsConfigFx.doneData, (_, config) => config);
export const $currentContrAgent = createStore<IContrAgent | null>(null)
    .on(onClearCurrentContrAgent, () => null)
    .on(getContrAgentFx.doneData, (_, user) => user)
    .on(createContrAgentFx.doneData, (_, user) => user);
export const $activationKey = createStore<string>('')
    .on(getOldActivationKeyFx.doneData, (_, key) => key)
    .on(getActivationKeyFx.doneData, (_, key) => key);

export const $editContrAgentTab = createStore<contrAgentsTabsItem>(contrAgentsTabsTitle[0].key)
    .on(onChangeEditContrAgentTab, (_, tab) => tab);
export const $currentContrAgentId = createStore<number>(-1)
    .on(setContrAgentId, (_, id) => id);
