import React, {FC, memo} from 'react';
import {useStore} from 'effector-react';
import {
    $isTileMode,
    $tableConfig,
    $tableData, $tableQuery,
    $tableTargets, getPackageConfigFx, getPackageTableFx, getTableFromQueryFx,
    onChangeTableTargets, setSortingTable
} from '@models/Table';
import Table from '@shared/components/Table';
import {$currentPackage} from '@models/Sidebar/Packages';
import {$isAdmin} from '@models/User';

import GridToolbar from './components/GridToolbar';
import GridPlug from './components/GridPlug';
import {tableContextMenu, tableAdminContextMenu} from './utils';
import {useGridToolbar} from './useGridToolbar';
import {IGrid} from './interfaces';
import styles from './Grid.module.scss';

const Grid: FC<IGrid> = ({contextMenuActions}) => {
    const isLoadingGrid = useStore(getPackageConfigFx.pending);
    const isLoadingConfig = useStore(getPackageTableFx.pending);
    const isLoadingQuery = useStore(getTableFromQueryFx.pending);
    const {head, sorting, totalAmount} = useStore($tableConfig);
    const {body, currentPage} = useStore($tableData);
    const currentPackage = useStore($currentPackage);
    const isTileMode = useStore($isTileMode);
    const targets = useStore($tableTargets);
    const isAdmin = useStore($isAdmin);
    const tableQuery = useStore($tableQuery);

    const {
        list, isOpen, availableCols,
        onChange, onOpen, onClose, onLoad
    } = useGridToolbar({head});

    return (
        <div className={styles.container}>

            {(currentPackage !== -1 || tableQuery) ? (
                <React.Fragment>
                    <GridToolbar
                        list={list}
                        isOpen={isOpen}
                        isTileMode={isTileMode}
                        onChange={onChange}
                        onClose={onClose}
                        onOpen={onOpen}
                    />

                    <Table
                        targets={targets}
                        onChange={onChangeTableTargets}
                        head={head}
                        body={body}
                        tableName={'main-grid'}
                        sorting={sorting}
                        availableCols={availableCols}
                        totalAmount={totalAmount}
                        nextPage={onLoad}
                        currentPage={currentPage}
                        isNextPageLoading={isLoadingGrid || isLoadingConfig || isLoadingQuery}
                        contextMenuActions={contextMenuActions}
                        contextMenuList={isAdmin ? tableAdminContextMenu : tableContextMenu}
                        setSorting={setSortingTable}
                        isTileMode={isTileMode}
                        isDraggable={false}
                        isUseContext
                    />
                </React.Fragment>
            ) : (
                <GridPlug/>
            )}
        </div>
    );
};

export default memo(Grid);
