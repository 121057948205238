import {ILoginPayload, IRegistrationPayload} from '@models/User/interfaces';
import {$auth} from '@shared/api/config';
import {setAlert} from '@models/Alert';
import {ERROR_REQUEST} from '@shared/constants/common';

export const login = async ({login, password}: ILoginPayload) => {
    try {
        const {data, message, success} = await $auth.post('/login', {login, password});

        if (success) {
            const {accessToken} = data;
            localStorage.setItem('accessToken', accessToken);
            return data.user;
        }

        setAlert({type: 'error', description: message, title: 'Ошибка при авторизации'});
        return null;
    } catch (err) {
        setAlert(ERROR_REQUEST);
        return null;
    }
};

export const registration = async ({login, password, activationKey}: IRegistrationPayload) => {
    try {
        const {data, message, success} = await $auth.post('/register', {activationKey, login, password});

        if (success) {
            const {accessToken} = data;
            localStorage.setItem('accessToken', accessToken);
            return data.user;
        }

        setAlert({type: 'error', description: message, title: 'Ошибка при регистрации'})
        return null;
    } catch (err) {
        setAlert(ERROR_REQUEST);
        return null;
    }
};

export const getToken = async () => {
    try {
        const {data} = await $auth.get('/token');
        const {accessToken} = data;
        localStorage.setItem('accessToken', accessToken);

        return data.user;
    } catch (err) {
        return null;
    }
};

export const logout = async () => {
    try {
        const {data} = await $auth.get('/logout');
        return data;
    } catch (err) {
        setAlert(ERROR_REQUEST);
        return null;
    }
};
