import React, {memo, useState} from 'react';
import {Navigate} from 'react-router-dom';
import {useStore} from 'effector-react';
import {ReactComponent as HiddenPassIcon} from '@assets/icons/Login/HiddenPass.svg';
import {ReactComponent as ShownPassIcon} from '@assets/icons/Login/ShownPass.svg';
import {ReactComponent as LogoIcon} from '@assets/icons/Login/Logo.svg';
import {ReactComponent as LoaderIcon} from '@assets/icons/shared/Loader.svg';
import {$loginForm, $userData, getTokenFx, handleChange, loginFx, onLogin} from '@models/User';
import Button from '@shared/ui/Button';
import Input from '@shared/ui/Inputs/Input';

import styles from './LoginPage.module.scss';

const LoginPage = () => {
    const isLoginPending = useStore(loginFx.pending);
    const isLoadingToken = useStore(getTokenFx.pending);
    const userData = useStore($userData);
    const {login, password} = useStore($loginForm);
    const [isPassShown, setPassVisible] = useState(false);

    const handlePassVisibility = () => setPassVisible(prevValue => !prevValue);
    const onLoginFn = () => onLogin();

    if (isLoadingToken) {
        return (
            <div className={styles.loader}>
                <LoaderIcon/>
            </div>
        );
    }

    if (userData) {
        return <Navigate to='/dashboard'/>;
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.box}>
                    <LogoIcon className={styles.logo}/>
                    <Input autoComplete='username' autoCapitalize='off' autoCorrect='off' className={styles.input}
                           maxLength={32}
                           onChange={handleChange} name='login' placeholder='Логин'
                           value={login}/>
                    <Input
                        className={styles.input}
                        icon={isPassShown ? <ShownPassIcon className={styles.icon}/> :
                            <HiddenPassIcon className={styles.icon}/>}
                        isReversed
                        maxLength={32}
                        onChange={handleChange}
                        onClickIcon={handlePassVisibility}
                        name='password'
                        placeholder='Пароль'
                        type={isPassShown ? 'text' : 'password'}
                        value={password}
                        autoComplete='off'
                    />
                    <Button className={styles.button} isLoading={isLoginPending} disabled={password.length < 8 || password.length > 30}
                            onClick={onLoginFn} text='Войти'/>
                </div>
            </div>
        </>
    );
};

export default memo(LoginPage);
