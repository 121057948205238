import React, {FC, memo, useCallback} from 'react';
import {ReactComponent as SelectIcon} from '@assets/icons/Header/Select.svg';
import Text from '@shared/ui/Text';

import {IPopupChild, popupItemTypes} from '../../../../interfaces';
import styles from '../../../../PopupActions.module.scss';

import {ISubPopupRow} from './interfaces';

const SubPopupRow: FC<ISubPopupRow> = ({subRow, id, onTarget, selected, subStartCategoryTabs}) => {
    const getSubRowClasses = useCallback((subRow: IPopupChild, id: number): string => {
        let classes = `${styles.listRow}`;

        if (subRow.endCategory) {
            classes += ` ${styles.paddingBottom}`;
        }

        if (subStartCategoryTabs[`id_${id}`]) {
            classes += ` ${styles.paddingTop}`;
        }

        if (subRow.disabled) {
            classes += ` ${styles.disabled}`;
        }

        return classes;
    }, [subStartCategoryTabs]);

    return (
        <>
            <div
                className={getSubRowClasses(subRow, id)}
                onClick={() => subRow.disabled ? {} : onTarget(subRow)}
            >
                {subRow.type === popupItemTypes.SELECT && (
                    <div className={styles.listSelect}>
                        <Text
                            type={'body2'}
                            className={`${styles.listSelectText} ${selected?.title === subRow.title ? styles.activeText : ''}`}
                        >
                            {subRow.title}
                        </Text>
                        <div>{selected?.title === subRow.title && <SelectIcon/>}</div>
                    </div>
                )}

                {subRow.type !== popupItemTypes.SELECT && <Text type={'body2'}>{subRow.title}</Text>}
            </div>

            {subRow.endCategory && <div className={styles.listLine}/>}
        </>
    );
};

export default memo(SubPopupRow);
