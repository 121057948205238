import {sample} from 'effector';
import {deepCopy} from '@shared/utils/deepCopy';
import {reset} from 'patronum';
import {debounce} from 'patronum/debounce';
import {DEBOUNCE_TIMEOUT_IN_MS} from '@models/Sidebar/utils';

import {$isHideSidebar, $sectionId} from '../Sidebar';
import {navIds} from '../Sidebar/interfaces';
import {
    $currentPackage,
    $editPackageId,
    addTitlesInPackageFx, editPackageFx,
    onSetPackage,
} from '../Sidebar/Packages';

import {ETypesCurrentTable, ITableColState, ITableConfig} from './interfaces';
import {parseTableConfigQuery, parseTableDataQuery, sortFilter} from './utils';
import {
    $tableData,
    $tableConfig,
    searchGrid,
    deleteGrid,
    setSortingTable,
    setDefaultName,
    onChangeTitleName,
    saveNewTitleName,
    setPage,
    $sortingTable,
    $page,
    $newTitleName,
    renameTitleFx,
    $currentRowId,
    updateTable,
    toggleIsTileMode,
    getPackageConfigFx,
    getPackageTableFx,
    setCurrentTableType,
    $typeCurrentTable,
    $tableTargets,
    onChangeTableSearch,
    $tableQuery,
    getTableFromQueryFx,
    onForciblyUpdateConfig,
    onForciblyUpdateTable,
    onDeleteTitleInPackage, deleteTitlesInPackageFx
} from './';

const debounced = debounce({
    source: onChangeTableSearch,
    timeout: DEBOUNCE_TIMEOUT_IN_MS
});

sample({
    clock: [getPackageTableFx.doneData, onSetPackage],
    fn: () => ETypesCurrentTable.PACKAGE,
    target: setCurrentTableType
});

sample({
    clock: [setSortingTable, onSetPackage, toggleIsTileMode],
    source: {categoryId: $currentPackage, sorting: $sortingTable, prevState: $tableConfig, section: $sectionId, type: $typeCurrentTable},
    fn: sortFilter,
    filter: ({section, type}) =>
        (type === ETypesCurrentTable.NONE && section === navIds.Packages) || type === ETypesCurrentTable.PACKAGE,
    target: getPackageConfigFx
});

sample({
    clock: addTitlesInPackageFx.doneData,
    source: {categoryId: $currentPackage, sorting: $sortingTable, prevState: $tableConfig, section: $sectionId, type: $typeCurrentTable},
    fn: sortFilter,
    target: getPackageConfigFx
});

sample({
    clock: [getPackageConfigFx.doneData, $page],
    source: {page: $page, categoryId: $currentPackage},
    filter: ({categoryId}) => categoryId !== -1,
    target: getPackageTableFx
});

sample({
    clock: saveNewTitleName,
    source: {id: $currentRowId, name: $newTitleName},
    target: renameTitleFx
});

sample({
    clock: renameTitleFx.doneData,
    source: $tableData,
    fn: (table, newTitle: any) => {
        const newTable = deepCopy(table);
        for (let i = 0; i < newTable.body.length; i++) {
            if (newTable.body[i].id === newTitle.id) {
                newTable.body[i].name = newTitle.name;
            }
        }
        return newTable;
    },
    target: updateTable
});

sample({
    clock: [debounced, $page, setSortingTable],
    source: {page: $page, sorting: $sortingTable, query: $tableQuery, packageId: $currentPackage},
    fn: ({page, sorting, query}) => ({page, ...sorting, query}),
    filter: ({packageId, query}) => (packageId === -1 && !!query),
    target: getTableFromQueryFx
});

sample({
    clock: getTableFromQueryFx.doneData,
    fn: parseTableConfigQuery,
    target: onForciblyUpdateConfig
});

sample({
    clock: getTableFromQueryFx.doneData,
    fn: parseTableDataQuery,
    target: onForciblyUpdateTable
});

sample({
    clock: debounced,
    source: $isHideSidebar,
    fn: (_, data) => !!data.length,
    filter: (isHide) => isHide,
    target: $isHideSidebar
});

sample({
    clock: editPackageFx.doneData,
    source: {config: $tableConfig, currentPackage: $currentPackage},
    filter: ({currentPackage}, editedPackage) => editedPackage?.id === currentPackage,
    fn: ({config}, editedPackage) => {
        if (editedPackage) {
            const newConfig: ITableConfig = deepCopy(config);
            newConfig.name = editedPackage.name;
            return newConfig;
        }
        return config;
    },
    target: $tableConfig
});

sample({
    clock: onDeleteTitleInPackage,
    target: deleteTitlesInPackageFx
});

sample({
    clock: deleteTitlesInPackageFx.doneData,
    source: $tableData,
    fn: (table, deletedTitles: any) => {
        const newTable = deepCopy(table);
        newTable.body = table.body.filter((row: ITableColState) => !deletedTitles.includes(Number(row.id)));
        return newTable;
    },
    target: updateTable
});

reset({
    clock: deleteGrid,
    target: [$tableData, $page, $tableTargets, $tableConfig]
})

reset({
    clock: getPackageConfigFx.doneData,
    target: [$tableData, $page, $tableTargets]
});

reset({
    clock: toggleIsTileMode,
    target: [$tableData]
});

reset({
    clock: debounced,
    target: [$currentPackage, $tableData, $page]
});

reset({
    clock: onDeleteTitleInPackage,
    target: $tableTargets
});
