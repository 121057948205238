import React, {memo} from 'react';

import Grid from './components/Grid';
import PopupsContainer from './components/PopupsContainer';
import {usePresentationActions} from './hooks/usePresentationActions';
import styles from './Presentation.module.scss';

const Presentation = () => {
    const {
        contextMenuActions, currentModal, onCloseModal, onSuccess
    } = usePresentationActions();

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <Grid contextMenuActions={contextMenuActions}/>
            </div>
            <PopupsContainer
                currentModal={currentModal}
                onSuccess={onSuccess}
                onCloseModal={onCloseModal}
            />
        </div>
    );
};

export default memo(Presentation);
