import React, {FC, FocusEvent, FormEvent, memo, useState} from 'react';
import InputMask from 'react-input-mask';
import {ReactComponent as CalendarIcon} from '@assets/icons/shared/Calendar.svg';
import {ReactComponent as CloseIcon} from '@assets/icons/shared/Close.svg';
import Text from '@shared/ui/Text';

import {IDatePickerField} from './interfaces';
import styles from './Field.module.scss';

const Field: FC<IDatePickerField> = ({
  isError, isVisible, dateField, durationField, placeholder,
  onDurationInput, onDateInput, toggleVisibility, disabled, onClear
}) => {
    const [currentFocus, setCurrentFocus] = useState<string>('');

    const onRemoveFocus = () => setCurrentFocus('');

    const onFocusIn = ((e: FocusEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        switch (target.name) {
            case 'date':
                setCurrentFocus('duration');
                break;
            case 'duration':
                setCurrentFocus('date');
                toggleVisibility(false);
                break;
            default: break;
        }
    });

    const onOpenCalendar = () => toggleVisibility(true);

    const onChange = (e: FormEvent<HTMLInputElement>, onAction: (str: string) => void) => {
        const target = e.target as HTMLInputElement;
        onAction(target.value.trim());
    };

    return (
        <div
            className={`${styles.container} ${isVisible ? styles.focus : ''} ${isError ? styles.error : ''} ${disabled ? styles.disabled : ''}`}
        >
            <InputMask
                mask={'99.99.9999'}
                name={'date'}
                autoComplete={'off'}
                className={`${styles.input} ${currentFocus === 'date' ? styles.unFocus : ''} ${!(dateField || durationField) ? styles.defaultInput : ''}`}
                disabled={disabled}
                value={dateField}
                onFocus={onFocusIn}
                onBlur={onRemoveFocus}
                onChange={(e) => onChange(e, onDateInput)}
            />
            <InputMask
                mask={'99:99:99'}
                name={'duration'}
                autoComplete={'off'}
                className={`${styles.input} ${currentFocus === 'duration' ? styles.unFocus : ''} ${!(dateField || durationField) ? styles.defaultInput : ''}`}
                disabled={disabled || !dateField}
                value={durationField}
                onFocus={onFocusIn}
                onBlur={onRemoveFocus}
                onChange={(e) => onChange(e, onDurationInput)}
                alwaysShowMask
            />
            {!disabled && (
                <>
                    <div className={`${styles.icon} ${styles.calendarIcon}  ${styles.hoverIcon}`} onClick={onOpenCalendar}>
                        <CalendarIcon/>
                    </div>
                    <div className={`${styles.icon} ${styles.hoverIcon}`} onClick={onClear}>
                        <CloseIcon/>
                    </div>
                </>
            )}
            {(!dateField && !durationField) && (
                <div className={styles.placeholderWrapper}>
                    <Text type={'body1'} className={styles.placeholder}>{placeholder}</Text>
                </div>
            )}
        </div>
    );
};

export default memo(Field);
