import {createEffect, createEvent, createStore} from 'effector';
import {ESettingsPages} from '@pages/Dashboard/components/Settings/interfaces';
import {setSettingsConfig, getSettingsConfig} from '@shared/api/endpoints/settings';
import {ISettingsConfig} from '@models/Settings/interfaces';
import {FormEvent} from 'react';
import {createGate} from 'effector-react';

export const SettingsGate = createGate();

export const toggleOpenSettings = createEvent<any>();
export const onChangeSettingsTab = createEvent<ESettingsPages>();
export const onChangeConfig = createEvent<FormEvent<HTMLTextAreaElement>>();
export const setDefaultConfig = createEvent<string>();
export const saveConfig = createEvent<any>();

export const setSettingsConfigFx = createEffect<any, Error>(setSettingsConfig);
export const getSettingsConfigFx = createEffect<number, ISettingsConfig, Error>(getSettingsConfig);

export const $isOpenSettings = createStore<boolean>(false)
    .on(toggleOpenSettings, state => !state);
export const $currentSettingsTab = createStore<ESettingsPages>(ESettingsPages.SETT)
    .on(onChangeSettingsTab, (_, tab) => tab);
export const $settingsConfigString = createStore<string>('')
    .on(setDefaultConfig, (_, value) => value)
    .on(onChangeConfig, (_, e) => (e.target as HTMLTextAreaElement).value);
