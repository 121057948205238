import React, {FC, memo} from 'react';
import Text from '@shared/ui/Text';

import styles from './User.module.scss';
import {IContrAgent} from './interfaces';
import Logo from '@shared/ui/Logo';

const User: FC<IContrAgent> = ({logo, name}) => {
    return (
        <div className={styles.container}>
            <Logo src={logo} name={name} scale={'sm-md'}/>

            <div className={styles.information}>
                <Text type={'body1'} className={styles.name}>{'Приветствуем,'}</Text>
                <Text type={'body1'} className={styles.name}>{name}</Text>
            </div>
        </div>
    );
};

export default memo(User);
