import React, {FC, memo, MouseEvent} from 'react';
import {ReactComponent as FolderIcon} from '@assets/icons/Sidebar/Folder.svg';
import {ReactComponent as OpenFolderIcon} from '@assets/icons/Sidebar/OpenFolder.svg';
import Text from '@shared/ui/Text';
import {createTemplateWidth} from '@shared/utils/generators';

import {useDirectory} from '../../hooks/useDirectory';
import {ICategoryTree} from '../../interfaces';

import styles from './CategoryFolder.module.scss';
import CheckBox from '@shared/ui/CheckBox';

const CategoryFolder: FC<ICategoryTree> = ({
    onClick, nestingLvl, item, onContextMenu,
    currentDirectory, onSelect, normalWidth,
    multiple, onChecked, selected
}) => {
    const {
        folderRef, slickWidth, isOpen, toggleOpen, onLocalContextMenu
    } = useDirectory({
        onContextMenu,
        onSelect,
        normalWidth,
        item,
        nestingLvl,
    });

    const onOpenSection = item.type === 'section' ? toggleOpen : (e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        if (onClick) {
            onClick(item.id);
        }
    };

    return (
        <div onContextMenu={onLocalContextMenu} ref={folderRef}>
            <details open={isOpen} className={styles.container}>
                <summary
                    className={`${styles.summary} ${(currentDirectory === item.id && item.type !== 'section') ? styles.current : ''} ${multiple ? styles.disabled : ''}`}
                    style={createTemplateWidth(slickWidth)}
                    onClick={onOpenSection}
                >
                    <div className={styles.directoryIcon}>
                        {isOpen ? <OpenFolderIcon/> : <FolderIcon/>}
                    </div>
                    <Text type={'body2'} className={styles.title}>{item.name}</Text>
                    {!multiple && <div className={styles.hover}/>}
                    {(multiple && onChecked) && (
                        <CheckBox
                            checked={selected[item.id]}
                            onChange={() => onChecked(item.id)}
                            label={''}
                        />
                    )}
                </summary>
            </details>
        </div>
    );
};

export default memo(CategoryFolder);
