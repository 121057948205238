import {useEffect, useRef, useState, FormEvent} from 'react';
import {useStore} from 'effector-react';
import {$currentPopupID} from '@models/PopupActions';

import {IUseSelector, SelectorItem} from './interfaces';

const useSelector = ({data, filter, disabled}: IUseSelector) => {
    const [isOpened, setOpen] = useState(false);
    const [search, setSearch] = useState<string>('');
    const [filteredData, setFilteredData] = useState<SelectorItem[]>([]);
    const [isUpMode, setIsUpMode] = useState<boolean>(false);
    const currentPopupId = useStore($currentPopupID);

    const onChange = (e: FormEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        setSearch(target.value);
    };

    const filterRef = useRef<HTMLDivElement>(null);
    const listRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLDivElement>(null);

    const onClose = () => setOpen(false);

    useEffect(() => {
        if (disabled) {
            return undefined;
        }

        const checkIfClickedOutside = (e: MouseEvent) => {
            const target = e.target as HTMLDivElement | HTMLElement;

            if (!filterRef.current?.contains(target)) {
                setOpen(false);
                setSearch('');
            }

            if ((!filter || (inputRef.current && !inputRef.current.contains(target))) && data.length > 1 && filterRef.current && filterRef.current.contains(target)) {
                setOpen((prev) => !prev);
                setSearch('');
            }

            if ((!filter || (inputRef.current && !inputRef.current.contains(target))) && listRef.current && listRef.current.contains(target)) {
                setOpen(false);
                setSearch('');
            }
        };

        document.addEventListener('click', checkIfClickedOutside);
        document.addEventListener('contextmenu', onClose);

        return () => {
            document.removeEventListener('click', checkIfClickedOutside);
            document.removeEventListener('contextmenu', onClose);
        };
    }, []);

    useEffect(() => {
        if (!filter || !search) {
            return setFilteredData(data);
        }

        let result: SelectorItem[] = [];

        data.forEach((item: SelectorItem) => {
            if (item.title.includes(search)) {
                result.push(item);
            }
        });
        setFilteredData(result);
    }, [search]);

    useEffect(() => {
        if (!listRef.current || disabled) {
            return undefined;
        }
        const {height} = document.body.getBoundingClientRect();
        const bottom = listRef.current.getBoundingClientRect().bottom;

        if (height < bottom + 4) {
            setIsUpMode(true);
        } else {
            setIsUpMode(false);
        }
    }, [isOpened, listRef]);

    useEffect(() => {
        if (currentPopupId) {
            setOpen(false);
        }
    }, [currentPopupId]);

    return {
        filterRef, listRef, inputRef, filteredData,
        isOpened, isUpMode, search, onChange
    };
};

export default useSelector;
