import {contrAgentsTabsItem} from './interfaces';

export const contrAgentsTabsTitle = [
    {
        key: contrAgentsTabsItem.MAIN,
        label: 'Основная информация'
    },
    {
        key: contrAgentsTabsItem.ACCESS,
        label: 'Доступы к пакетам'
    }
];
