import React, {memo} from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import {useStore} from 'effector-react';
import {$userData} from '@models/User';

const AuthLayout = () => {
    const userData = useStore($userData);
    const location = useLocation();

    if (userData === null) {
        return <Navigate to='/login' replace state={{from: location}}/>;
    }

    if (userData) {
        return <Outlet/>;
    }

    return <></>
};

export default memo(AuthLayout);
