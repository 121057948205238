export const uuid = (): string => {
    const mask = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
    let dt = new Date().getTime();

    return mask.replace(/[xy]/g, c => {
        const r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
};

export const createTemplateHeight = (size: number) => {
    if (size <= 0) {
        return {height: '100%'};
    }

    return {height: `${size}px`};
};

export const createTemplateWidth = (size: number, isWithoutMax = false) => {
    if (!size || size <= 0) {
        return {width: 'auto'};
    }

    if (isWithoutMax) {
        return {minWidth: size};
    }

    return {minWidth: size, maxWidth: size};
};

export const createRange = (size: number) => {
    if (size <= 0) {
        return [];
    }

    return Array.from({length: size}, (_, i) => i + 1);
};

export const toBase64 = (file: Blob): Promise<string> => new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = () => '';
});

export const randomKey = () => (Math.random() + 1).toString(36).substring(7);

export const getNoun = (number: number, one: string, two: string, five: string) => {
    let n = Math.abs(number);
    n %= 100;

    if (n >= 5 && n <= 20) {
        return five;
    }

    n %= 10;
    if (n === 1) {
        return one;
    }

    if (n >= 2 && n <= 4) {
        return two;
    }

    return five;
};
