const _formatValue = (value: number): string => String(value).padStart(2, '0');

export const getCompressedDate = (date: Date): string => {
    const day = _formatValue(date.getDate());
    const month = _formatValue(date.getMonth() + 1);
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
};

export const getDurationFromDate = (date: Date, withoutS: boolean = true): string => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    if (!withoutS) {
        return `${_formatValue(hours)}:${_formatValue(minutes)}:${_formatValue(seconds)}`;
    }

    return `${_formatValue(hours)}:${_formatValue(minutes)}`;
};

export const getDurationFromMs = (ms: number): string => {
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / 1000 / 60) % 60);
    const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);

    return `${_formatValue(hours)}:${_formatValue(minutes)}:${_formatValue(seconds)}`;
};

export const getFullDate = (ms: number): string => {
    const date = new Date(ms);
    return `${getCompressedDate(date)} ${getDurationFromMs(ms)}`;
};

export const getDurationWithoutMs = (ms: number) => getDurationFromMs(ms).slice(0, 5);

export const getMsFromDuration = (duration: string): number => {
    const durationSplit = duration.split(':');
    const seconds = Number(durationSplit[2]) * 1000;
    const minutes = Number(durationSplit[1]) * 1000 * 60;
    const hours = Number(durationSplit[0]) * 1000 * 60 * 60;

    if (Number(durationSplit[0]) > 24 || Number(durationSplit[1]) > 59 || Number(durationSplit[2]) > 59) {
        return -1;
    }

    if (isNaN(seconds) || isNaN(minutes) || isNaN(hours)) {
        return -1;
    }

    return seconds + minutes + hours;
};

export const getWeekly = (currentDate: Date = new Date()) => {
    const oneJan: Date = new Date(currentDate.getFullYear(), 0, 1);
    const MS_IN_DAY = 86400000;
    return Math.ceil((((+currentDate - (+oneJan)) / MS_IN_DAY) + oneJan.getDay() + 1) / 7) - 1;
};

export const getFormattedDate = (date: Date = new Date()) => {
    const formatDate = date.toLocaleDateString('ru', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    }).split(',');

    return `${formatDate[1].substring(0, formatDate[1].length - 3)}`.trim();
};

export const getTitleDate = (date: Date): string => {
    const currentYear = new Date().getFullYear();
    const currentDay = getFormattedDate();
    const formatDate = getFormattedDate(date);
    const yesterday = getFormattedDate(new Date(Date.now() - 86400000));

    if (currentDay === formatDate) {
        return 'Сегодня';
    }

    if (formatDate === yesterday) {
        return 'Вчера';
    }

    if (date.getFullYear() === currentYear) {
        return formatDate.substring(0, formatDate.length - 4);
    }

    return formatDate;
};
