import React, {FormEvent, memo, useCallback, useMemo} from 'react';
import {ReactComponent as SearchIcon} from '@assets/icons/shared/Search.svg';
import {useStore} from 'effector-react';
import Category from '@shared/components/Category';
import {singleSort} from '@shared/utils/sort';
import Input from '@shared/ui/Inputs/Input';
import {
    $allPackages, $allPackagesQuery,
    $selectedPackages,
    getAvailablePackagesFx,
    onAllPackagesQuery,
    onSelectPackage
} from '@models/Sidebar/ContrАgents/Accesses';
import FormLoader from '@shared/ui/Loaders/FormLoader';

import styles from './ContrAgentAccesses.module.scss';

const ContrAgentAccesses = () => {
    const packages = useStore($allPackages);
    const selected = useStore($selectedPackages);
    const packageQuery = useStore($allPackagesQuery);
    const isLoading = useStore(getAvailablePackagesFx.pending);

    const sortedData = useMemo(() => singleSort(packages, 'name'), [packages]);

    const onChecked = useCallback((id: number) => {
        onSelectPackage({id, value: !selected[id]});
    }, [selected]);

    const onChange = (e: FormEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        onAllPackagesQuery(target.value);
    };

    return (
        <div className={styles.container}>
            <div className={styles.input}>
                <Input
                    name={'filter'}
                    placeholder={'Поиск по пакетам'}
                    icon={<SearchIcon/>}
                    value={packageQuery}
                    onChange={onChange}
                    isReversed
                />
            </div>
            {isLoading ? (
                <div className={styles.loader}>
                    <FormLoader repeat={8} rows={1} isHideHeader/>
                </div>
            ) : (
                <Category list={sortedData} onChecked={onChecked} selected={selected} multiple/>
            )}
        </div>
    );
};

export default memo(ContrAgentAccesses);
