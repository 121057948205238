import {useCallback, useEffect, useState} from 'react';
import {IGenericBoolean} from '@shared/components/Table/interfaces';
import {ICheckbox} from '@shared/ui/Popups/PopupSettings/interfaces';
import {deepCopy} from '@shared/utils/deepCopy';
import {nextPage} from '@models/Table';

import {IUseGridToolbar} from './interfaces';

export const useGridToolbar = ({head}: IUseGridToolbar) => {
    const [list, setList] = useState<ICheckbox[]>([]);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [availableCols, setAvailableCols] = useState<IGenericBoolean>({});

    const onOpen = () => setTimeout(() => setIsOpen(true), 0);
    const onClose = () => setIsOpen(false);
    const onChange = useCallback((key: string) => {
        const newLists = deepCopy(list);

        for (let i = 0; i < newLists.length; i++) {
            if (newLists[i].key === key) {
                newLists[i].isChecked = !newLists[i].isChecked;
                break;
            }
        }

        setList(newLists);
    }, [list]);

    const onLoad = () => nextPage();

    useEffect(() => {
        const defaultList: ICheckbox[] = [];
        const storage = JSON.parse(localStorage.getItem('availableCols') || '{}');

        head.forEach(col => {
            const {id, title} = col;

            if (id) {
                const isChecked = storage[id] !== undefined ? storage[id] : true;
                const newCol: ICheckbox = {key: id, value: title, isChecked};
                defaultList.push(newCol);
            }
        });

        setList(defaultList);
    }, [head]);

    useEffect(() => {
        const columns: IGenericBoolean = {};

        list.forEach(({key, isChecked}) => {
            columns[key] = isChecked;
        });

        localStorage.setItem('availableCols', JSON.stringify(columns));
        setAvailableCols(columns);
    }, [list]);

    return {
        list, isOpen, availableCols,
        onChange, onOpen, onClose, onLoad
    };
};
