import React, {FC, memo, useRef} from 'react';
import {ReactComponent as UpIcon} from '@assets/icons/Sidebar/InlineDropDownUp.svg';
import {ReactComponent as DownIcon} from '@assets/icons/Sidebar/InlineDropDownBottom.svg';
import Text from '@shared/ui/Text';

import styles from './List.module.scss';
import {IList} from './interfaces';

const List: FC<IList> = ({
  title,
  icon,
  children,
  isOpen,
  name,
  toggleOpen,
}) => {
    const listRef = useRef<HTMLDivElement>(null);

    return (
        <div className={`${styles.container} ${isOpen ? styles.opened : ''}`}>
            <div className={styles.tab} onClick={() => toggleOpen(name)}>
                <div className={styles.titleWrapper}>
                    <div className={`${styles.icon} ${isOpen ? styles.activeIcon : ''}`}>{icon}</div>
                    <Text type={'body2'} className={`${styles.title} ${isOpen ? styles.activeTitle : ''}`}>{title}</Text>
                </div>

                <div className={`${styles.icon} ${isOpen ? styles.activeIcon : ''}`}>
                    {isOpen ? <UpIcon/> : <DownIcon/>}
                </div>
            </div>

            <div ref={listRef} className={`${styles.defaultContent}`}>{children}</div>
        </div>
    );
};

export default memo(List);
