import React, {FC, memo} from 'react';
import {ReactComponent as CloseIcon} from '@assets/icons/shared/Close.svg';
import CheckBox from '@shared/ui/CheckBox';
import Text from '@shared/ui/Text';
import {useCheckIfClickedOutsideGlobal} from '@shared/hooks/useCheckIfClickedOutsideGlobal';

import styles from './PopupSettings.module.scss';
import {ISettingsPopup} from './interfaces';

const PopupSettings: FC<ISettingsPopup> = ({popupName, list, onClose, onChange}) => {
    const filteredList = list.filter(option => option.value);
    const {contentRef} = useCheckIfClickedOutsideGlobal({callback: onClose});

    return (
        <div className={styles.container} ref={contentRef}>
            <div className={styles.titleWrapper}>
                <Text type={'body2'}>{popupName}</Text>
                <div className={`${styles.close} ${styles.icon}`} onClick={onClose}>
                    <CloseIcon className={styles.closeIcon}/>
                </div>
            </div>

            {filteredList.map(({key, value, isChecked}) => (
                <div key={`filter-${key}`} className={styles.field}>
                    <CheckBox
                        checked={isChecked}
                        label={value}
                        onChange={() => onChange(key)}
                    />
                </div>
            ))}
        </div>
    );
};

export default memo(PopupSettings);
