import {useStore} from 'effector-react';
import {$editContrAgentTab, onChangeEditContrAgentTab} from '@models/Sidebar/ContrАgents';

import {contrAgentsTabsItem} from '../interfaces';

export const useContrAgentTabs = () => {
    const tab = useStore($editContrAgentTab);
    const toggleTab = (key: contrAgentsTabsItem) => onChangeEditContrAgentTab(key);

    return {tab, toggleTab};
};
