import {sample} from 'effector';
import {debounce} from 'patronum/debounce';
import {DEBOUNCE_TIMEOUT_IN_MS} from '@models/Sidebar/utils';
import {reset} from 'patronum';
import {$currentContrAgent, $currentContrAgentId, $editContrAgentTab} from '@models/Sidebar/ContrАgents';
import {IChangeAccessPayload, ISelectedPackage} from '@models/Sidebar/ContrАgents/Accesses/interfaces';

import {
    $allPackages,
    $allPackagesQuery,
    $selectedPackages,
    AccessesGate, changeUserAccessFx,
    getAvailablePackagesFx,
    onAllPackagesQuery,
    onResetAccesses, onSelectPackage
} from '.';

const debounced = debounce({
    source: onAllPackagesQuery,
    timeout: DEBOUNCE_TIMEOUT_IN_MS
});

sample({
    clock: [AccessesGate.open, debounced, $currentContrAgentId],
    source: {query: $allPackagesQuery, userId: $currentContrAgentId},
    filter: ({userId}) => userId !== -1,
    target: getAvailablePackagesFx
});

sample({
    clock: onSelectPackage,
    source: $currentContrAgent,
    fn: (user, payload): IChangeAccessPayload => ({
        id: payload.id,
        userId: user ? user.id : -1,
        type: payload.value ? 'grant' : 'revoke'
    }),
    filter: (user) => !!user,
    target: changeUserAccessFx
});

sample({
    clock: getAvailablePackagesFx.doneData,
    fn: (data) => {
        const selected: ISelectedPackage = {};
        data.forEach(clipbin => selected[clipbin.id] = clipbin.isAvailable);
        return selected;
    },
    target: $selectedPackages
});

reset({
    clock: [onResetAccesses, AccessesGate.close],
    target: [$selectedPackages, $allPackagesQuery, $allPackages, $editContrAgentTab]
});
