import React, {memo} from 'react';
import {useStore} from 'effector-react';
import Textarea from '@shared/ui/Textarea';
import Text from '@shared/ui/Text';
import Button from '@shared/ui/Button';
import {$settingsConfigString, onChangeConfig, saveConfig} from '@models/Settings';

import styles from './SettingsContrAgents.module.scss';

const SettingsContrAgents = () => {
    const settingsConfig = useStore($settingsConfigString);

    return (
        <div className={styles.container}>
            <Text type={'body1'}>{'Конфигурация полей контрагентов'}</Text>
            <Textarea className={styles.textarea} value={settingsConfig} onInput={onChangeConfig}/>

            <div className={styles.button}>
                <Button onClick={saveConfig} text={'Отправить'}/>
            </div>
        </div>
    );
};

export default memo(SettingsContrAgents);
