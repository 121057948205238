import Fetch from './fetch';

const domain = import.meta.env.VITE_APP_API_URL;
const basePath = `${domain}/api`;

export const $data = Fetch(`${basePath}`);
export const $auth = Fetch(`${basePath}/auth`);
export const $user = Fetch(`${basePath}/user`);
export const $titles = Fetch(`${basePath}/titles`);
export const $category = Fetch(`${basePath}/categories`);
export const $search = Fetch(`${basePath}/search`);
export const $clipbins = Fetch(`${basePath}/clipbins`);
export const $settings = Fetch(`${basePath}/configs`);
export const $media = Fetch(`${basePath}/media`);
