import React, {FC, memo} from 'react';
import Text from '@shared/ui/Text';
import DatePicker from '@shared/ui/DatePicker';

import {IFiledSingle} from '../interfaces';
import styles from '../Fields.module.scss';

import {useFieldDate} from './useFieldDate';

const FieldDate: FC<IFiledSingle> = ({label, value, id, onSave, formKey, isReadOnly, isRequired}) => {
    const {localValue, onChange} = useFieldDate({id, value, onSave, formKey});

    return (
        <div className={styles.container}>
            {label && <Text type={'caption'} className={styles.label}>{label}</Text>}
            <DatePicker
                className={styles.datePicker}
                defaultValue={localValue}
                disabled={isReadOnly}
                onChangeDate={onChange}
            />
        </div>
    );
};

export default memo(FieldDate);
