import React, {FC} from 'react';

import styles from './Text.module.scss';

const Text: FC<any> = (props) => {
    const {type, className, children, ...textProps} = props;

    return <p {...textProps} className={`${styles[type]} ${className}`}>{children}</p>;
};

export default Text;
