import React, {memo, useState} from 'react';
import {useStore} from 'effector-react';
import {ReactComponent as NotificationIcon} from '@assets/icons/Header/Notification.svg';
import {$userData} from '@models/User';
import {toggleOpenSettings} from '@models/Settings';

import User from './components/User';
import styles from './Navigation.module.scss';

const Navigation = () => {
    const [openNotification, setOpenNotification] = useState<boolean>(false);
    const userData = useStore($userData);

    const toggleActive = () => setOpenNotification(prevState => !prevState);

    return (
        <div className={styles.container}>
            {/*<div*/}
            {/*    onClick={toggleActive}*/}
            {/*    className={`${styles.button} ${styles.fill} ${openNotification ? styles.activeIcon : ''}`}*/}
            {/*>*/}
            {/*    <NotificationIcon/>*/}
            {/*</div>*/}
            <div onClick={toggleOpenSettings}>
                <User
                    name={userData.name}
                    job={userData.login}
                    avatar={userData.logo}
                />
            </div>
        </div>
    );
};

export default memo(Navigation);
