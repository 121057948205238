import {sample} from 'effector';
import {debounce} from 'patronum/debounce';
import {deepCopy} from '@shared/utils/deepCopy';

import {DEBOUNCE_TIMEOUT_IN_MS} from '../utils';

import {
    getPackagesFx,
    setPackagesFx,
    deletePackageFx,
    onInputFilter,
    PackagesGate,
    $packageQuery,
    onSendPackage,
    $newPackage,
    onDeletePackage,
    $packages,
    updatePackages,
    onEditPackage,
    editPackageFx,
    $editPackageId,
    onForcedLoadPackages,
    onAddTitlesInPackage,
    addTitlesInPackageFx,
} from './';

const debounced = debounce({
    source: onInputFilter,
    timeout: DEBOUNCE_TIMEOUT_IN_MS
});

sample({
    clock: [PackagesGate.open, debounced, onForcedLoadPackages],
    source: $packageQuery,
    target: getPackagesFx
});

sample({
    clock: onSendPackage,
    source: $newPackage,
    target: setPackagesFx
});

sample({
    clock: onDeletePackage,
    target: deletePackageFx
});

sample({
    clock: onEditPackage,
    source: {name: $newPackage, id: $editPackageId},
    target: editPackageFx
})

sample({
    clock: deletePackageFx.doneData,
    source: $packages,
    fn: (packages, deletedPackage: any) => {
        if (deletedPackage) {
            return packages.filter(item => item.id !== deletedPackage.id);
        }
        return packages;
    },
    target: updatePackages
});

sample({
    clock: editPackageFx.doneData,
    source: $packages,
    fn: (packages, editPackage: any) => {
        if (editPackage) {
            const copyPackages = deepCopy(packages);
            for (let i = 0; i < copyPackages.length; i++) {
                if (copyPackages[i].id === editPackage.id) {
                    copyPackages[i].name = editPackage.name;
                }
            }
            return copyPackages;
        }
        return packages;
    },
    target: updatePackages
});


sample({
   clock: onAddTitlesInPackage,
   target: addTitlesInPackageFx
});
