import React, {FC, memo} from 'react';
import {ReactComponent as AddIcon} from '@assets/icons/MetaEditor/Add.svg';
import {ReactComponent as DeleteIcon} from '@assets/icons/MetaEditor/Delete.svg';
import Text from '@shared/ui/Text';
import Selector from '@shared/components/Selector';
import {SelectorItem} from '@shared/components/Selector/interfaces';

import {IFieldMultipleSelect} from '../interfaces';
import styles from '../Fields.module.scss';

import {useFieldMultipleSelect} from './useFieldMultipleSelect';

const FieldMultipleSelect: FC<IFieldMultipleSelect> = ({value, id, label, options, onSave, formKey, isReadOnly, isRequired}) => {
    const {
        localValue, onAdd, onDelete, onSelect
    } = useFieldMultipleSelect({id, value, formKey, onSave})

    return (
        <div className={styles.container}>
            <Text type={'caption'} className={styles.label}>{label}</Text>
            <div className={styles.fieldList}>
                {localValue && localValue.map((value, index) => (
                    <div
                        key={`multiple-select-${id}-${index}`}
                        className={styles.fieldListItem}
                    >
                        <Selector
                            onSelect={(newValue: SelectorItem) => onSelect(newValue, index)}
                            className={styles.input}
                            options={options}
                            value={value}
                            disabled={isReadOnly}
                            isScrollMode
                            filter
                        />
                        {!isReadOnly && (
                            <div className={styles.iconContainer}>
                                {index !== 0 && (
                                    <div className={styles.icon} onClick={() => onDelete(index)}>
                                        <DeleteIcon/>
                                    </div>
                                )}
                                {index === localValue.length - 1 && (
                                    <div className={styles.icon} onClick={onAdd}>
                                        <AddIcon/>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default memo(FieldMultipleSelect);
