import {useEffect} from 'react';
import {useStore} from 'effector-react';
import {useNavigate} from 'react-router-dom';

import {setSectionId, $isHideSidebar, setIsHideSidebar} from '@models/Sidebar';
import {keyCodes} from '@shared/constants/KeyCodes';
import {navIds} from '@models/Sidebar/interfaces';
import {onLogout} from '@models/User';
import {$isWorkHotkeys} from '@models/Hotkeys';

export const useHotkeys = () => {
    const isHideSidebar = useStore($isHideSidebar);
    const isWorkHotkeys = useStore($isWorkHotkeys);
    const navigate = useNavigate();

    const onKeyDown = (e: KeyboardEvent) => {
        const {altKey, shiftKey, ctrlKey, metaKey, code} = e;
        const target = e.target as HTMLInputElement;

        if (!isWorkHotkeys || (target.tagName === 'INPUT' && target.type !== 'range')) {
            return undefined;
        }

        if (altKey && (ctrlKey || metaKey) && shiftKey && code === keyCodes.L) {
            onLogout();
            return navigate('/dashboard', {replace: true, state: {from: location}});
        }

        if (altKey && shiftKey) {
            switch (code) {
                default: return undefined;
            }
        }

        if (ctrlKey || metaKey) {
            switch (code) {
                default: return undefined;
            }
        }

        if (altKey) {
            switch (code) {
                case keyCodes.O:
                    e.preventDefault();
                    return setIsHideSidebar();
                case keyCodes.K:
                    e.preventDefault();
                    if (isHideSidebar) {
                        setIsHideSidebar();
                    }
                    return setSectionId(navIds.Packages);
                default: break;
            }
        }
    };

    const onDragStart = () => {
        document.body.removeEventListener('keydown', onKeyDown);
    };

    const onDragEnd = () => {
        document.body.addEventListener('keydown', onKeyDown);
    };

    useEffect(() => {
        document.body.addEventListener('keydown', onKeyDown);
        document.body.addEventListener('dragstart', onDragStart);
        document.body.addEventListener('dragend', onDragEnd);

        return () => {
            document.body.removeEventListener('keydown', onKeyDown);
            document.body.removeEventListener('dragstart', onDragStart);
            document.body.removeEventListener('dragend', onDragEnd);
        };
    }, [
        isHideSidebar,
        isWorkHotkeys
    ]);
};
