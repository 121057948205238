import React, {FormEvent, memo, useCallback} from 'react';
import {useGate, useStore} from 'effector-react';
import {ReactComponent as SearchIcon} from '@assets/icons/shared/Search.svg';
import {$currentPopupID} from '@models/PopupActions';
import {$sideBarWidth} from '@models/Sidebar';
import PopupActions from '@shared/ui/Popups/PopupActions';
import Category from '@shared/components/Category';
import {
    $currentPackage, $editPackageId, $newPackage,
    $packageQuery,
    $packages, getPackagesFx, onChangeNewPackage, onForcedLoadPackages,
    onInputFilter,
    onSetPackage,
    PackagesGate
} from '@models/Sidebar/Packages';
import Input from '@shared/ui/Inputs/Input';
import PopupForm from '@shared/ui/Popups/PopupForm';
import {$isAdmin} from '@models/User';

import {usePackageTree} from './usePackageTree';
import styles from './PackageTree.module.scss'
import {contextMenuCategory} from './utils';
import TableLoader from '@shared/ui/Loaders/TableLoader';

const PackageTree = () => {
    useGate(PackagesGate);

    const currentPopupID = useStore($currentPopupID);
    const sideBarWidth = useStore($sideBarWidth);
    const packages = useStore($packages);
    const currentPackage = useStore($currentPackage);
    const packageQuery = useStore($packageQuery);
    const newPackage = useStore($newPackage);
    const isAdmin = useStore($isAdmin);
    const packageEdit = useStore($editPackageId);
    const isLoadingPackages = useStore(getPackagesFx.pending);

    const {
        sortedData, modalType,
        containerRef, contextMenuCoords,
        popupRef, isLeftMode, popupID,
        onStopPropagation, onContextMenu, onSelect, onPopupAction,
        onOpenModal, onCloseModal, onSuccessModal
    } = usePackageTree({packages, newPackage});

    const onChange = (e: FormEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        onInputFilter(target.value);
    };

    const onOpenPackage = useCallback((id: number) => {
        if (id !== currentPackage) {
            onSetPackage(id);
        }
    }, [currentPackage]);

    return (
        <div className={styles.container}>
            <div className={styles.field}>
                <Input
                    name={'filter'}
                    placeholder={'Поиск по пакетам'}
                    icon={<SearchIcon/>}
                    value={packageQuery}
                    onChange={onChange}
                    onClickIcon={onForcedLoadPackages}
                    isReversed
                />
            </div>

            {isLoadingPackages ? (
                <div className={styles.loader}>
                    <TableLoader rows={12} cols={1} isHideHeader/>
                </div>
                ) : (
                <Category
                    actionTitle={isAdmin ? 'Добавить пакет' : undefined}
                    normalWidth={sideBarWidth}
                    currentDirectory={currentPackage}
                    list={sortedData}
                    onContextMenu={isAdmin ? onContextMenu : undefined}
                    onSelect={onSelect}
                    onClick={onOpenPackage}
                    onOpenModal={onOpenModal}
                />
            )}

            <div
                ref={containerRef}
                onClick={onStopPropagation}
                onContextMenu={onStopPropagation}
                className={styles.contextMenu}
                style={(currentPopupID === popupID && contextMenuCoords) ? contextMenuCoords : {visibility: 'hidden'}}
            >
                <PopupActions
                    refObj={popupRef}
                    list={contextMenuCategory}
                    onClick={onPopupAction}
                    isLeftMode={isLeftMode}
                />
            </div>

            {(modalType === 'create' || modalType === 'edit') && (
                <PopupForm
                    name={'new-package'}
                    placeholder={'Название'}
                    title={modalType === 'create' ? 'Создание нового пакета' : 'Изменение пакета'}
                    label={(packageEdit === -1 || modalType === 'create') ? undefined : `ID Пакета: ${packageEdit}`}
                    maxLength={100}
                    value={newPackage}
                    onChange={onChangeNewPackage}
                    onClose={onCloseModal}
                    onSuccess={onSuccessModal}
                />
            )}
        </div>
    );
};

export default memo(PackageTree);
