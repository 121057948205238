import React, {FC} from 'react';
import styles from './Alert.module.scss';
import {icons} from "./utils";
import {IAlertProps} from "@shared/ui/Alert/interfaces";
import Text from '@shared/ui/Text';
import useAlert from "./useAlert";

const Alert: FC<IAlertProps> = ({type, title, description}) => {
    useAlert();
    return (
        <div className={`${styles.container} ${styles[type]}`}>
            <div className={styles.header}>
                <div className={styles.icon}>
                    {icons[type]}
                </div>
                <Text type='subtitle2' className={styles.title}>{title}</Text>
            </div>
            {!description || typeof description === 'string' ? (
                <Text type='body2' className={styles.description}>{description}</Text>
            ) : (
                description.map(text => <Text type='body2' className={styles.description}>{text}</Text>)
            )}
        </div>
    );
};

export default Alert;
