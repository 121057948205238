import {FormEvent, useCallback, useEffect} from 'react';
import {deepCopy} from '@shared/utils/deepCopy';
import {useStore} from 'effector-react';
import {$form, onChangeForm} from '@models/Form';

import {IUseField} from '../interfaces';

export const useFieldMultipleInput = ({id, value, formKey, onSave}: IUseField) => {
    const localValue = (useStore($form)?.[formKey]?.[id] as string[] | undefined);

    const onInput = useCallback((e: FormEvent<HTMLInputElement>, inputId: number) => {
        if (!localValue) {
            return undefined;
        }
        const target = e.target as HTMLInputElement;
        const state = deepCopy(localValue);

        state[inputId] = target.value;
        onChangeForm({form: formKey, field: id, value: state});
    }, [localValue]);

    const onSaveInput = useCallback(() => {
        if (onSave && localValue) {
            onSave({value: localValue, fieldId: id});
        }
    }, [localValue, id]);

    const onAdd = useCallback(() => {
        if (!localValue) {
            return undefined;
        }
        const state = deepCopy(localValue);
        onChangeForm({form: formKey, field: id, value: [...state, '']});
    }, [localValue]);

    const onDelete = useCallback((inputId: number) => {
        if (!localValue) {
            return undefined;
        }
        const state: string[] = deepCopy(localValue);
        const newState: string[] = state.filter((_, index) => index !== inputId);

        if (onSave) {
            onSave({value: newState, fieldId: id});
        }

        onChangeForm({form: formKey, field: id, value: newState});
    }, [id, localValue]);

    useEffect(() => {
        onChangeForm({form: formKey, field: id, value: localValue ?? value ?? ['']});
    }, [value, localValue]);
    return {
        localValue, onAdd, onInput, onDelete, onSaveInput
    };
};
