import {sample} from 'effector';
import {reset} from 'patronum';

import {
    $loginForm,
    changeLoginFx,
    onChangeLogin,
    onCloseLoginPopup,
    loginChange,
    setNewLogin
} from '.';

sample({
    clock: onChangeLogin,
    source: $loginForm,
    target: changeLoginFx
});

reset({
    clock: onCloseLoginPopup,
    target: $loginForm
});
