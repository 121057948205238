import React, {memo} from 'react';
import {useStore} from 'effector-react';
import Headline from '@shared/ui/Headline';
import Text from '@shared/ui/Text';
import {$currentPackage} from '@models/Sidebar/Packages';
import {ReactComponent as SearchIcon} from '@assets/icons/Sidebar/Search.svg';

import styles from './GridPlug.module.scss';
import {$tableQuery, setTargetInput} from '@models/Table';

const GridPlug = () => {
    const currentPackage = useStore($currentPackage);
    const query = useStore($tableQuery);

    return (
        <div className={styles.container}>
            <div className={styles.titleWrapper}>
                {(currentPackage === -1 && !query) ? (
                     <>
                         <Headline level={3} className={styles.title}>{'Тут пока пусто'}</Headline>
                         <Headline level={3} className={styles.subtitle}>{'но вы можете'}</Headline>
                     </>
                ) : (
                    <>
                        <Headline level={3} className={styles.title}>{'Пустая таблица'}</Headline>
                    </>
                )}
            </div>
            {(currentPackage === -1 && !query) && (
                <div className={styles.button} onClick={setTargetInput}>
                    <div className={styles.icon}>
                        <SearchIcon/>
                    </div>
                    <Text type={'caption'} className={styles.buttonTitle}>{'Найти медиа'}</Text>
                </div>
            )}
        </div>
    );
};

export default memo(GridPlug);
