import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useElementRect} from '@shared/hooks/useElementRect';
import {IUseInfiniteLoader} from '@shared/components/Table/interfaces';
import TableLoader from '@shared/ui/Loaders/TableLoader';
import TileLoader from '@shared/ui/Loaders/TileLoader';

import Row from '../components/Row';
import Card from '../components/Card';
import {defaultContainerState, defaultScrollState} from '../utils';
import styles from '../Table.module.scss';

import {useDragRow} from './useDragRow';
import {useTile} from './useTile';

export const useInfiniteLoader = ({
    body, head, isNextPageLoading,
    totalAmount, currentPage,
    tableWidth, isOpenSidebar,
    isTileMode,
    targets, nextPage,
    toggleTarget, availableCols,
    doubleClick, isDraggable,
    colsWidth, onContextMenu,
    onSelect, isUseContext
}: IUseInfiniteLoader) => {
    const [scrollState, setScrollState] = useState(defaultScrollState);
    const [containerSettings, setContainerSettings] = useState(defaultContainerState);

    const rowsRef = useRef<HTMLDivElement>(null);
    const rowsWidth = useElementRect(rowsRef, 'width', false);
    const rowsHeight = useElementRect(rowsRef, 'height', false);

    const hasNextPage = useMemo(() => totalAmount > currentPage * 40, [totalAmount, currentPage]);
    const loadMoreRows = useMemo(() => isNextPageLoading ? () => {} : nextPage, [isNextPageLoading, nextPage]);
    const cellsSize = useMemo(() => {
        if (head) {
            return head.map(({id}) => colsWidth[id]);
        }
        return [];
    }, [colsWidth, head]);

    const {
        links,
        onDragStart, onDragEnd, onDrag
    } = useDragRow({targets, tableWidth, body});

    const {labels} = useTile({head, availableCols});

    const setScrollRowAndColum = (rowIndex: number, columnIndex: number) => setScrollState({rowIndex, columnIndex});

    const rowCount = useMemo(() => {
        if (isTileMode) {
            return hasNextPage ? Math.ceil((body.length + 1) / 4) : Math.ceil(body.length / 4);
        }
        return hasNextPage ? body.length + 1 : body.length;
    }, [body, hasNextPage, isTileMode]);

    const isRowLoaded = useCallback((index: number) => {
        if (isTileMode) {
            return !hasNextPage || index < body.length / 4;
        }
        return !hasNextPage || index < body.length;
    }, [body, hasNextPage, isTileMode]);

    const RowRenderer = useCallback(({index, style}: any) => {
        const rowData = body[index];

        if (!isRowLoaded(index)) {
            return (
                <div style={style} className={styles.plug}>
                    <TableLoader rows={!body.length ? 12 : 1} cols={head.length} cellsSize={cellsSize} isHideHeader/>
                </div>
            );
        }

        return (
            <div style={style}>
                <Row
                    head={head}
                    data={rowData}
                    index={index}
                    colsWidth={colsWidth}
                    tableWidth={tableWidth}
                    availableCols={availableCols}
                    targets={targets}
                    isUseContext={isUseContext}
                    isDraggable={isDraggable}
                    onDragEnd={onDragEnd}
                    onDragStart={onDragStart}
                    onDrag={onDrag}
                    onContextMenu={onContextMenu}
                    doubleClick={doubleClick || ((id: string | number) => {})}
                    onSelect={onSelect}
                    toggleTarget={toggleTarget}
                    links={links}
                />
            </div>
        );
    }, [body, head, colsWidth, isDraggable, links, isUseContext, availableCols, tableWidth, targets]);


    const CardRenderer = useCallback(({columnIndex, rowIndex, style}: any) => {
        const itemIndex = rowIndex * 4 + columnIndex;
        const cardData = body[itemIndex];

        if (!isRowLoaded(rowIndex)) {
            return <TileLoader count={4}/>;
        }

        if (!cardData) {
            return <React.Fragment/>;
        }

        return (
            <div style={style}>
                <Card
                    preview={'./images/GridPreview.jpg'}
                    head={head}
                    labels={labels}
                    data={cardData}
                    targets={targets}
                    toggleTarget={toggleTarget}
                    onContextMenu={onContextMenu}
                    onSelect={onSelect}
                    doubleClick={doubleClick || ((id: string | number) => {})}
                />
            </div>
        );
    }, [body, head, targets, labels]);

    useEffect(() => {
        if (!rowsRef.current) {
            return undefined;
        }

        const minWidth = Number(tableWidth?.minWidth.replace('px', '')) || 0;
        const {width, height} = rowsRef.current.getBoundingClientRect();
        const normalizeWidth = isOpenSidebar ? width : rowsWidth;

        setContainerSettings({
            width: isTileMode ? normalizeWidth : minWidth,
            height: rowsHeight > height ? height : rowsHeight
        });
    }, [rowsRef, rowsWidth, rowsHeight, tableWidth, isOpenSidebar, isTileMode]);

    return {
        RowRenderer, CardRenderer,
        containerSettings, rowCount, rowsRef, loadMoreRows, isRowLoaded,
        scrollState, setScrollRowAndColum
    };
};
