import React, {memo} from 'react';
import {Outlet} from 'react-router-dom';
import {useGate, useStore} from 'effector-react';
import Alert from '@shared/ui/Alert';
import {$alertData} from '@models/Alert';
import {AuthGate} from '@models/User';

const MainLayout = () => {
    const alertData = useStore($alertData);
    useGate(AuthGate);

    return (
        <>
            <Outlet/>
            {alertData && <Alert {...alertData} />}
        </>
    );
};

export default memo(MainLayout);
