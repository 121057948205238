import React, {FC, memo} from 'react';
import {useStore} from 'effector-react';
import PopupTable from '@shared/ui/Popups/PopupTable';
import PopupComment from '@shared/ui/Popups/PopupComment';
import PopupMigrate from '@shared/ui/Popups/PopupMigrate';
import PopupForm from '@shared/ui/Popups/PopupForm';
import {$newTitleName, onChangeTitleName} from '@models/Table';
import PopupContAgent from '@shared/ui/Popups/PopupContAgent';
import {$isOpenNewContrAgent, onClearCurrentContrAgent, toggleCreateContrAgents} from '@models/Sidebar/ContrАgents';
import {onResetForm} from '@models/Form';

import {usePopups} from '../../hooks/usePopups';
import {
    directoriesMigration,
    informationHeaderRow,
    relatedSubtitlesHeaderRow
} from '../../utils';

import {IPopupsContainer} from './interfaces';

const PopupsContainer: FC<IPopupsContainer> = ({currentModal, onSuccess, onCloseModal}) => {
    const isOpenNewContrAgent = useStore($isOpenNewContrAgent);

    const {
        comment, onChangeComment, resetComment,
        commentChecked, onCommentChecked
    } = usePopups();

    const rename = useStore($newTitleName);
    const onCloseContrAgentPopup = () => {
        onResetForm('contr-agent-form');
        toggleCreateContrAgents('none');
        onClearCurrentContrAgent();
    };

    return (
        <React.Fragment>
            {isOpenNewContrAgent !== 'none' && <PopupContAgent onClose={onCloseContrAgentPopup}/>}
            {(currentModal === 'migrate') && (
                <PopupMigrate
                    list={directoriesMigration}
                    title={'Политика миграции'}
                    onSuccess={onSuccess}
                    onClose={onCloseModal}
                />
            )}
            {(currentModal === 'relatedSubtitles') && (
                <PopupTable
                    tableContent={{
                        head: relatedSubtitlesHeaderRow,
                        body: []
                    }}
                    title={'Субтитры для видео Title GATR_TEST1'}
                    onSuccess={onSuccess}
                    onClose={onCloseModal}
                    onDelete={onCloseModal}
                    isHidePanelControl
                />
            )}
            {(currentModal === 'information') && (
                <PopupTable
                    tableContent={{
                        head: informationHeaderRow,
                        body: []
                    }}
                    title={'Информация о медиа'}
                    panelControlTitle={'Name_of_the_file ассоциирован с 6 файлами в хранилище'}
                    onClose={onCloseModal}
                    isHideButtons
                />
            )}
            {(currentModal === 'comment') && (
                <PopupComment
                    title={'Добавить комментарий'}
                    placeholder={'Введите комментарий'}
                    name={'comment'}
                    value={comment}
                    maxLength={400}
                    checked={commentChecked}
                    onChecked={onCommentChecked}
                    onSuccess={() => {
                        onCloseModal();
                        resetComment();
                    }}
                    onClose={() => {
                        onCloseModal();
                        resetComment();
                    }}
                    onChange={onChangeComment}
                />
            )}
            {(currentModal === 'rename') && (
                <PopupForm
                    title={'Переименование'}
                    name={'rename'}
                    value={rename}
                    maxLength={100}
                    onChange={onChangeTitleName}
                    onSuccess={onSuccess}
                    onClose={onCloseModal}
                />
            )}
        </React.Fragment>
    );
};

export default memo(PopupsContainer);
