import {TCell} from '@models/Table/interfaces';
import {ReactComponent as VideoIcon} from '@assets/icons/Table/Types/Video.svg';
import {
    getCompressedDate,
    getDurationFromDate,
    getDurationFromMs,
} from '@shared/utils/dateFormat';

import styles from './Row.module.scss';

export const getCellClassName = (type: TCell) => {
    switch (type) {
        case 'checkbox': return styles.checkbox;
        case 'status': return styles.status;
        case 'type': return styles.type;
        default: return styles.text;
    }
};

export const getCellValue = (type: TCell, value: string | number) => {
    switch (type) {
        case 'time': return getDurationFromMs(Number(value) as number);
        case 'datetime':
            if (!value) {
                return '';
            }

            const date = new Date(Number(value));

            if (typeof value === 'number') {
                return `${getCompressedDate(new Date(value))} ${getDurationFromDate(date, true)}`;
            }

            return `${getCompressedDate(date)} ${getDurationFromDate(date, true)}`;
        case 'date': return getCompressedDate(new Date(Number(value)));
        case 'type': return <VideoIcon/>;
        default: return value;
    }
};
