import {$linkForDownloadVideo} from '@models/Media';
import {useStore} from 'effector-react';
import {useEffect} from 'react';

export const useDownloadMedia = () => {
    const linkForDownloadVideo = useStore($linkForDownloadVideo);

    const download = (link: string) => {
        const a = document.createElement('a');

        a.href = link;
        a.download = link;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    useEffect(() => {
        if (!linkForDownloadVideo) {
            return undefined;
        }

        if (typeof linkForDownloadVideo === 'string') {
            return download(linkForDownloadVideo);
        }

        linkForDownloadVideo.forEach((link, i) => setTimeout(() => download(link), 1000 * i));
    }, [linkForDownloadVideo]);
};
