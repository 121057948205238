import React, {FC, memo} from 'react';
import {ReactComponent as AddIcon} from '@assets/icons/MetaEditor/Add.svg';
import {ReactComponent as DeleteIcon} from '@assets/icons/MetaEditor/Delete.svg';
import Input from '@shared/ui/Inputs/Input';
import Text from '@shared/ui/Text';

import {IFieldMultiple} from '../interfaces';
import styles from '../Fields.module.scss';

import {useFieldMultipleInput} from './useFieldMultipleInput';

const FieldMultipleInput: FC<IFieldMultiple> = ({durationMode, value, id, label, onSave, formKey, isRequired, isReadOnly}) => {
    const {
        localValue, onAdd, onDelete, onInput, onSaveInput
    } = useFieldMultipleInput({id, value, formKey, onSave});

    return (
        <div className={styles.container}>
            <Text type={'caption'} className={styles.label}>{label}</Text>
            <div className={styles.fieldList}>
                {localValue?.map((value, index) => (
                    <div
                        key={`multiple-input-${id}-${index}`}
                        className={styles.fieldListItem}
                    >
                        <Input
                            name={`input-${id}-${index}`}
                            className={styles.input}
                            value={value}
                            onInput={(e) => onInput(e, index)}
                            onBlur={onSaveInput}
                            disabled={isReadOnly}
                            durationMode={durationMode}
                        />
                        {!isReadOnly && (
                            <div className={styles.iconContainer}>
                                {index !== 0 && (
                                    <div className={styles.icon} onClick={() => onDelete(index)}>
                                        <DeleteIcon/>
                                    </div>
                                )}
                                {index === localValue.length - 1 && (
                                    <div className={styles.icon} onClick={onAdd}>
                                        <AddIcon/>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default memo(FieldMultipleInput);
