import {IPopupParent, popupItemTypes} from '@shared/ui/Popups/PopupActions/interfaces';

export const tableAdminContextMenu: IPopupParent[] = [
    {
        type: popupItemTypes.ACTION,
        title: 'Переименовать',
        name: 'rename',
        endCategory: false,
        disabled: false,
        list: []
    },
    {
        type: popupItemTypes.ACTION,
        title: 'Скачать',
        name: 'download',
        endCategory: false,
        disabled: false,
        list: []
    },
    {
        type: popupItemTypes.ACTION,
        title: 'Удалить',
        name: 'deleteTitle',
        endCategory: false,
        disabled: false,
        list: []
    }
];

export const tableContextMenu: IPopupParent[] = [
    {
        type: popupItemTypes.ACTION,
        title: 'Скачать',
        name: 'download',
        endCategory: false,
        disabled: false,
        list: []
    }
];
