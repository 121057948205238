import React, {FC, memo} from 'react';
import {useStore} from 'effector-react';
import {FixedSizeList, FixedSizeGrid} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import {usePopup} from '@shared/ui/Popups/PopupActions/hooks/usePopup';
import PopupActions from '@shared/ui/Popups/PopupActions';
import {$currentPopupID} from '@models/PopupActions';
import {$isHideSidebar} from '@models/Sidebar';
import TableLoader from '@shared/ui/Loaders/TableLoader';

import {usePopupAction} from './hooks/usePopupAction';
import {useSlickTable} from './hooks/useSlickTable';
import {useTargetRows} from './hooks/useTargetRows';
import {useInfiniteLoader} from './hooks/useInfiniteLoader';
import TitleCol from './components/TitleCol';
import styles from './Table.module.scss';
import {ITable} from './interfaces';
import {CARD_HEIGHT, CARD_WIDTH, ROW_HEIGHT} from './utils';

const Table: FC<ITable> = ({
  head, body, sorting,
  nextPage, tableName,
  isNextPageLoading = false,
  totalAmount = 40,
  currentPage = 1,
  isUseContext = true,
  isTileMode = false,
  isDraggable = false,
  targets = [], setSorting,
  plugComponent, onChange,
  contextMenuList, contextMenuActions,
  availableCols, doubleClick
}) => {
    const currentPopupID = useStore($currentPopupID);
    const isOpenSidebar = useStore($isHideSidebar);

    const {
        containerRef, contextMenuCoords, data, popupID, popupRef, isLeftMode,
        onStopPropagation, onSelect, onClose, onContextMenu
    } = usePopup();

    const {
        tableRef, targetWidget, tableWidth,
        colsWidth, sortBy, indexes,
        onMouseMove, onMouseDown, onSort
    } = useSlickTable({head, availableCols, sorting, tableName, isOpenSidebar, setSorting});

    const {
        mainCheckbox, onChangeMain, toggleTarget
    } = useTargetRows({body, onChange, targets});

    const {onClick} = usePopupAction({popupData: data, onClose, contextMenuActions});

    const {
        RowRenderer, CardRenderer,
        rowCount, rowsRef, isRowLoaded,
        containerSettings, loadMoreRows,
        scrollState, setScrollRowAndColum
    } = useInfiniteLoader({
        body, tableWidth, isNextPageLoading, currentPage, nextPage, totalAmount,
        isOpenSidebar, isTileMode, head,
        availableCols, targets, toggleTarget, isDraggable, isUseContext, onContextMenu,
        colsWidth, onSelect, doubleClick
    });

    return (
        <div onMouseMove={onMouseMove} className={`${styles.container}`}>
            <div ref={tableRef} className={styles.table}>
                <div className={styles.header} style={tableWidth?.minWidth ? tableWidth : {}}>
                    {head.map(({id, title}) => (
                        (!availableCols || (!id || availableCols[id])) && (
                            <TitleCol
                                key={`table-title-row-${id}-${indexes[id]}`}
                                keyCol={id}
                                value={title}
                                checked={mainCheckbox}
                                onChangeChecked={onChangeMain}
                                index={indexes[id]}
                                targetWidget={targetWidget}
                                colsWidth={colsWidth}
                                sortBy={sortBy}
                                onMouseDown={!isTileMode ? onMouseDown : undefined}
                                onSort={onSort}
                            />
                        )
                    ))}
                </div>
                <div
                    ref={rowsRef}
                    className={isTileMode ? styles.cards : styles.rows}
                    style={{minWidth: containerSettings.width}}
                >
                    {!body.length && !head.length && isNextPageLoading && (
                        <div className={styles.plug}>
                            <TableLoader cols={5} rows={12} isHideHeader/>
                        </div>
                    )}
                    <InfiniteLoader
                        isItemLoaded={isRowLoaded}
                        loadMoreItems={loadMoreRows}
                        itemCount={rowCount}
                    >
                        {({onItemsRendered, ref}) => (
                            !isTileMode ? (
                                <FixedSizeList
                                    ref={ref}
                                    itemCount={rowCount}
                                    onItemsRendered={onItemsRendered}
                                    {...containerSettings}
                                    itemSize={ROW_HEIGHT}
                                    style={{overflowX: 'hidden'}}
                                >
                                    {RowRenderer}
                                </FixedSizeList>
                            ) : (
                                <FixedSizeGrid
                                    ref={ref}
                                    columnCount={4}
                                    rowCount={rowCount}
                                    columnWidth={CARD_WIDTH}
                                    rowHeight={CARD_HEIGHT}
                                    {...containerSettings}
                                    style={{overflowX: 'hidden', minWidth: containerSettings.width}}
                                    initialScrollTop={scrollState.rowIndex}
                                    onItemsRendered={({
                                       visibleRowStartIndex,
                                       visibleColumnStartIndex,
                                       visibleRowStopIndex,
                                       overscanRowStopIndex,
                                       overscanRowStartIndex,
                                    }) => {
                                        setScrollRowAndColum(visibleRowStartIndex, visibleColumnStartIndex);
                                        onItemsRendered({
                                            overscanStartIndex: overscanRowStartIndex,
                                            overscanStopIndex: overscanRowStopIndex,
                                            visibleStartIndex: visibleRowStartIndex,
                                            visibleStopIndex: visibleRowStopIndex
                                        });
                                    }}
                                >
                                    {CardRenderer}
                                </FixedSizeGrid>
                            )
                        )}
                    </InfiniteLoader>
                </div>
            </div>

            {contextMenuList && (
                <div
                    ref={containerRef}
                    onClick={onStopPropagation}
                    onContextMenu={onStopPropagation}
                    className={styles.contextMenu}
                    style={(currentPopupID === popupID && contextMenuCoords) ? contextMenuCoords : {visibility: 'hidden'}}
                >
                    <PopupActions
                        refObj={popupRef}
                        list={contextMenuList}
                        onClick={onClick}
                        isLeftMode={isLeftMode}
                    />
                </div>
            )}
        </div>
    );
};

export default memo(Table);
