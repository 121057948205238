import {FormEvent, useCallback, useEffect, useState} from 'react';
import {useStore} from 'effector-react';
import {$form, onChangeForm, onDisabledForm} from '@models/Form';

import {IUseField} from '../interfaces';

export const useFieldInput = ({id, formKey, value, onSave, isRequired}: IUseField) => {
    const localValue = (useStore($form)?.[formKey]?.[id] as string | undefined);
    const [isError, setIsError] = useState<boolean>(false);

    const onInput = (e: FormEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        onChangeForm({form: formKey, field: id, value: target.value.trimStart()});
        if (isRequired) {
            setIsError(!target.value);
        }
    };

    const onSaveInput = useCallback(() => {
        if (onSave && localValue) {
            onSave({value: localValue.trim(), fieldId: id});
        }
    }, [localValue, id]);

    useEffect(() => {
        onChangeForm({form: formKey, field: id, value: localValue ?? value ?? ''});
        if (isRequired) {
            onDisabledForm({key: formKey, value: !localValue});
        }
    }, [value, localValue]);

    return {localValue, isError, onInput, onSaveInput};
};
