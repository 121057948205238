import React, {FC, memo} from 'react';
import Text from '@shared/ui/Text';
import Selector from '@shared/components/Selector';

import {IFieldSelect} from '../interfaces';
import styles from '../Fields.module.scss';

import {useFieldSelect} from './useFieldSelect';

const FieldSelect: FC<IFieldSelect> = ({label, value, options, id, onSave, formKey, filter, isReadOnly, isRequired}) => {
    const {
        localValue, onSelect
    } = useFieldSelect({id, value, formKey, onSave});

    return (
        <div className={styles.container}>
            <Text type={'caption'} className={styles.label}>{label}</Text>
            <Selector
                onSelect={onSelect}
                className={styles.input}
                options={options}
                value={localValue}
                isScrollMode
                filter={filter}
                disabled={isReadOnly}
            />
        </div>
    );
};

export default memo(FieldSelect);
