import {sample} from 'effector';
import {reset} from 'patronum';

import {
    $passwordForm,
    changePasswordFx,
    onChangePassword,
    onClosePopup,
    passwordChange,
    setNewPassword
} from '.';

sample({
    clock: onChangePassword,
    source: $passwordForm,
    target: changePasswordFx
});

reset({
    clock: onClosePopup,
    target: $passwordForm
});
