import React, {memo} from 'react';
import {ReactComponent as CheckedIcon} from '@assets/icons/shared/Select.svg';
import Text from '@shared/ui/Text';
import {ICheckBox} from '@shared/ui/CheckBox/interfaces';

import styles from './CheckBox.module.scss'

const CheckBox = ({checked, disabled = false, onChange, label}: ICheckBox) => {
    return (
        <div className={styles.container}>
            <span
                onClick={(e) => {
                    e.stopPropagation();
                    onChange();
                }}
                onDoubleClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                className={`${styles.checkbox} ${checked ? styles.active : ''} ${disabled ? styles.disabled : ''}`}
            >
                {checked && <CheckedIcon/>}
            </span>
            <Text type={'body2'}>{label}</Text>
        </div>
    );
};

export default memo(CheckBox);

