import {useCallback, useRef, useEffect} from 'react';
import {useStore} from 'effector-react';
import {$currentPopupID} from '@models/PopupActions';

export const useCheckIfClickedOutsideGlobal = ({callback}: {callback: () => void}) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const currentPopupId = useStore($currentPopupID);

    const onClick = useCallback((e: MouseEvent) => {
        const target = e.target as HTMLElement;
        if (!contentRef.current?.contains(target)) {
            callback();
        }
    }, [contentRef]);

    useEffect(() => {
        document.body.addEventListener('click', onClick);
        document.body.addEventListener('contextmenu', onClick);

        return () => {
            document.body.removeEventListener('click', onClick);
            document.body.addEventListener('contextmenu', onClick);
        };
    }, [contentRef]);

    useEffect(() => {
        if (currentPopupId) {
            callback();
        }
    }, [currentPopupId]);

    return {contentRef};
};
