import {MouseEvent, useEffect, useMemo, useRef, useState} from 'react';
import {singleSort} from '@shared/utils/sort';

import {IUseDirectoryProps} from '../interfaces';
import {categoryBorder, categoryOffset} from '../utils';

export const useDirectory = ({nestingLvl, normalWidth, onContextMenu, onSelect, item}: IUseDirectoryProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [slickWidth, setSlickWidth] = useState<number>(0);
    const folderRef = useRef<HTMLDivElement>(null);

    const toggleOpen = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setIsOpen(prevState => !prevState)
    };
    const onLocalContextMenu = (e: MouseEvent<HTMLDivElement>) => {
        if (onContextMenu && onSelect) {
            onSelect({...item});
            onContextMenu(e);
        }
    };

    const sortedChildren = useMemo(() => {
        return item.children ? singleSort(singleSort(item.children, 'name').reverse(), 'type').reverse() : [];
    }, [item]);

    useEffect(() => {
        if (folderRef.current) {
            setSlickWidth(normalWidth - (nestingLvl * categoryOffset) - (nestingLvl ? categoryBorder * nestingLvl : 0));
        }
    }, [folderRef, normalWidth, nestingLvl]);

    return {folderRef, isOpen, toggleOpen, onLocalContextMenu, slickWidth, sortedChildren};
};
