import React, {FC, memo} from 'react';

import styles from './Row.module.scss';
import {IRow} from './interfaces';
import Cell from './componets/Cell';
import {useRow} from '@shared/components/Table/components/Row/useRow';

const Row: FC<IRow> = ({
  head,
  data,
  tableWidth,
  colsWidth,
  availableCols,
  toggleTarget,
  links,
  index,
  targets,
  isUseContext,
  isDraggable,
  onDrag,
  onDragEnd,
  onDragStart,
  onContextMenu,
  onSelect,
  doubleClick
}) => {
    const {
        rowRef, rowId, isDrag, onLocalDragEnd, onLocalDragStart, onLocalContextMenu, getType
    } = useRow({
       head, data, isUseContext, onContextMenu, onSelect, onDragStart, onDragEnd, links
    });

    return (
        <div
            className={`${styles.container} ${isDraggable ? styles.cursorGrab : ''}`}
            onContextMenu={onLocalContextMenu}
        >
            <div
                ref={rowRef}
                style={tableWidth ?? {}}
                className={`${styles.row} ${targets.includes(rowId) ? styles.target : ''}`}
                onDrag={onDrag}
                onDragStart={onLocalDragStart}
                onDragEnd={onLocalDragEnd}
                onDragLeave={e => e.preventDefault()}
                onDragOver={e => e.preventDefault()}
                draggable={isDraggable}
                onDoubleClick={rowId ? () => doubleClick(rowId) : undefined}
            >
                {Object.keys(data).map((cell: string) => (
                    (!availableCols || availableCols[cell]) && (
                        <React.Fragment key={`table-col-${cell}-${index}`}>
                            {((!isUseContext && cell) || (isUseContext)) && (
                                <Cell
                                    id={cell}
                                    value={String(data[cell])}
                                    type={getType(cell)}
                                    colsWidth={colsWidth}
                                    rowId={rowId}
                                    checked={targets.includes(rowId)}
                                    toggleTarget={toggleTarget}
                                />
                            )}
                        </React.Fragment>
                    )
                ))}
            </div>
            {isDrag && <div className={styles.plug} style={tableWidth ?? {}}/>}
        </div>
    );
};

export default memo(Row);
