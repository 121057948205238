import {ITableConfig, ITableConfigPayload, ITableData, ITableQuery, ITableSorting} from '@models/Table/interfaces';
import {deepCopy} from '@shared/utils/deepCopy';
import {getNoun} from '@shared/utils/generators';

export const defaultSortState: ITableSorting = {
    sortingField: 'id', sortingType: 0
};

export const sortFilter = (data: {categoryId: number, sorting: ITableSorting, prevState: ITableConfig}) => {
    const {categoryId, sorting, prevState} = data;
    const newData: ITableConfigPayload = deepCopy({categoryId, sorting});
    const {sortingType, sortingField} = prevState.sorting;

    if (newData.sorting.sortingField === sortingField && newData.sorting.sortingType === sortingType) {
        newData.sorting.sortingField = null;
        newData.sorting.sortingType = null;
    }

    return newData;
};

export const parseTableConfigQuery = (response: ITableQuery) => {
    const {sorting, head, totalAmount} = response;
    const name = `${getNoun(totalAmount, 'Найден', 'Найдено', 'Найдено')} ${totalAmount} ${getNoun(totalAmount, 'файл', 'файла', 'файлов')}`;
    return {sorting, head, totalAmount, name};
};

export const parseTableDataQuery = (response: ITableQuery) => {
    const {body, currentPage} = response;
    return {body, currentPage};
};

export const mergeTableData = ({body}: ITableData, newState: ITableData) =>
    ({...newState, body: [...body, ...newState.body]});
