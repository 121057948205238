export enum keyCodes {
    O = 'KeyO',
    T = 'KeyT',
    F = 'KeyF',
    H = 'KeyH',
    K = 'KeyK',
    L = 'KeyL',
    I = 'KeyI',
    M = 'KeyM',
    P = 'KeyP',
    Space = 'Space',
    ArrowUp = 'ArrowUp',
    ArrowDown = 'ArrowDown',
    ArrowLeft = 'ArrowLeft',
    ArrowRight = 'ArrowRight',
    BracketR = 'BracketRight',
    BracketL = 'BracketLeft'
}
