import React, {FC, memo, useMemo} from 'react';
import {useStore} from 'effector-react';
import {ReactComponent as CopyIcon} from '@assets/icons/shared/Copy.svg';
import Text from '@shared/ui/Text';
import Button from '@shared/ui/Button';
import {setAlert} from '@models/Alert';
import {copyTextToClipboard} from '@shared/utils/copyText';
import {$activationKey, updateActivationKey} from '@models/Sidebar/ContrАgents';

import styles from './ContrAgentLink.module.scss';
import {IContrAgentLink} from './inerfaces';

const ContrAgentLink: FC<IContrAgentLink> = ({statusId}) => {
    const key = useStore($activationKey);
    const clipboard = useMemo(() => `${window.location.origin}/activate?key=${key}`, [key]);

    const saveInClipboard = () => {
        const res = copyTextToClipboard(clipboard);
        res.then(() => {
            setAlert({type: 'success', title: 'Текст скопирован'});
        }).catch(() => {
            setAlert({type: 'error', title: 'Произошла ошибка при копирование'});
        });
    };

    if (!key) {
        return <></>;
    }

    return (
        <div className={styles.container}>
            <Text type={'caption'} className={styles.label}>{statusId === 1 ?'Ссылка приглашение' : 'Ссылка для сброса пароля'}</Text>

            <div className={styles.wrapper}>
                <div className={styles.link}>
                    <Text type={'body2'}>{clipboard}</Text>
                    <div className={styles.icon} onClick={saveInClipboard}><CopyIcon/></div>
                </div>
                <Button className={styles.button} text={'Новая ссылка'} onClick={updateActivationKey}/>
            </div>
        </div>
    );
};

export default memo(ContrAgentLink);
