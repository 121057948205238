import {sample} from 'effector';
import {reset, debounce} from 'patronum';
import {createContrAgentFx, editUserFx} from '@models/Form';
import {IContrAgent, TContrAgentModal} from '@models/Sidebar/ContrАgents/interfaces';

import {DEBOUNCE_TIMEOUT_IN_MS} from '../utils';

import './Accesses/init';
import {
    ContrAgentsGate,
    getContrAgentsFx,
    onInputQuery,
    $contrAgentsQuery,
    onForcedLoadContrAgents,
    ModalContrAgentsGate,
    getContrAgentsConfigFx,
    updateContrAgents,
    getActivationKeyFx,
    updateActivationKey,
    $currentContrAgent,
    setContrAgentId,
    getContrAgentFx,
    onDeleteContrAgent,
    deleteContrAgentFx,
    toggleCreateContrAgents,
    $activationKey,
    $contrAgents,
    replaceContrAgents,
    getOldActivationKeyFx,
    changeUserStatusFx,
    onArchive, $currentContrAgentId
} from './';
import {deepCopy} from '@shared/utils/deepCopy';

const debounced = debounce({
    source: onInputQuery,
    timeout: DEBOUNCE_TIMEOUT_IN_MS
});

sample({
    clock: [debounced, ContrAgentsGate.open, onForcedLoadContrAgents],
    source: $contrAgentsQuery,
    target: getContrAgentsFx
});

sample({
    clock: editUserFx.doneData,
    source: $contrAgents,
    filter: (user) => !!user,
    fn: (list, user) => {
        if (user) {
            return list.map(contrAgent => {
               if (contrAgent.id === user.id) {
                   return user;
               }
               return contrAgent;
            });
        }
        return list;
    },
    target: replaceContrAgents
});

sample({
   clock: ModalContrAgentsGate.open,
   fn: () => 3,
   target: getContrAgentsConfigFx
});

sample({
    clock: updateActivationKey,
    source: $currentContrAgentId,
    target: getActivationKeyFx
});

sample({
    clock: setContrAgentId,
    target: [getOldActivationKeyFx, getContrAgentFx]
});

sample({
    clock: getOldActivationKeyFx.doneData,
    filter: (key) => !key,
    target: updateActivationKey
});

sample({
    clock: onDeleteContrAgent,
    target: deleteContrAgentFx
});

sample({
    clock: deleteContrAgentFx.doneData,
    source: $contrAgents,
    fn: (state, deletedUser) => state.filter(user => user.id !== deletedUser.id),
    target: replaceContrAgents
});

sample({
    clock: createContrAgentFx.doneData,
    filter: (user) => !!user,
    fn: (user) => user.id,
    target: [getContrAgentFx, getActivationKeyFx]
});

sample({
    clock: createContrAgentFx.doneData,
    filter: (user) => !!user,
    target: updateContrAgents
});

sample({
    clock: createContrAgentFx.doneData,
    filter: (user) => !!user,
    fn: (): TContrAgentModal => 'edit',
    target: toggleCreateContrAgents
});

sample({
    clock: onArchive,
    source: $currentContrAgent,
    filter: (user) => !!user,
    fn: (userId, statusId) => ({userId: userId?.id ?? -1, statusId}),
    target: changeUserStatusFx
});

sample({
    clock: changeUserStatusFx.doneData,
    source: $contrAgents,
    filter: (_, res) => !!res,
    fn: (state, res) => {
        const newState: IContrAgent[] = deepCopy(state);

        return newState.map(user => {
            if (user.id === res.id) {
                user.statusId = res.statusId
            }
            return user;
        });
    },
    target: $contrAgents
});

sample({
    clock: changeUserStatusFx.doneData,
    source: $currentContrAgent,
    fn: (user, res) => {
        if (user) {
            const newUser: IContrAgent = deepCopy(user);
            newUser.statusId = res.statusId;
            return newUser;
        }
        return user;
    },
    target: $currentContrAgent
});

reset({
   clock: toggleCreateContrAgents,
   target: $activationKey
});

reset({
    clock: deleteContrAgentFx.doneData,
    target: [$currentContrAgent, $currentContrAgentId]
});
