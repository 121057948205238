import {useCallback, useEffect, useState} from 'react';
import {getBrowser, getIsRetina, getOS, getScreenSize, getViewPort} from '@shared/utils/clientInfo';

import {ISystemData} from '../interfaces';
import {defaultTemplateClient, defaultTemplateSystem} from '../utils';
import {copyTextToClipboard} from '@shared/utils/copyText';
import {setAlert} from '@models/Alert';

export const useClientInfo = () => {
    const [client, setClient] = useState<ISystemData[]>([]);
    const [system, setSystem] = useState<ISystemData[]>([]);
    const [clipboard, setClipboard] = useState<string>('');

    const generateClientData = () => {
        const data = [...defaultTemplateClient];

        data.forEach(row => {
            switch (row.label) {
                case 'Браузер': row.value = getBrowser(); break;
                case 'ОС': row.value = getOS(); break;
                case 'Монитор': row.value = getScreenSize(); break;
                case 'ViewPort': row.value = getViewPort(); break;
                case 'PixelRatio': row.value = getIsRetina(); break;
                default: break;
            }
        });

        setClient(data);
    };

    const generateSystemData = () => {
        const data = [...defaultTemplateSystem];

        data.forEach(row => {
            switch (row.label) {
                case 'Backend-app': row.value = 'v1.01.1 (от 01.08.2023 19:00)'; break;
                case 'Frontend-app': row.value = 'v1.01.1 (от 01.08.2023 19:00)'; break;
                case 'Сервер': row.value = 'TV Archive  (WEB-1)'; break;
                case 'База данных': row.value = 'archive.net \\ DBArchive'; break;
                default: break;
            }
        });

        setSystem(data);
    };

    const saveInClipboard = async () => {
        try {
            await copyTextToClipboard(clipboard);
            setAlert({type: 'success', title: 'Текст скопирован'});
        } catch (e) {
            setAlert({type: 'error', title: 'Произошла ошибка при копировании'});
        }
    };

    useEffect(() => {
        generateClientData();
        // generateSystemData();
    }, []);

    useEffect(() => {
        let text = '';
        system.forEach(item => text += `${item.label}: ${item.value};\n`);
        client.forEach(item => text += `${item.label}: ${item.value};\n`);
        setClipboard(text);
    }, [client, system]);

    return {system, client, saveInClipboard};
};
