import {setAlert} from '@models/Alert';
import {ESettingsPages} from '@pages/Dashboard/components/Settings/interfaces';

export const saveConfigFn = (data: {tab: ESettingsPages, config: string}) => {
    try {
        return {
            config: JSON.parse(`${data.config || '{}'}`),
            typeId: 3
        };
    } catch (e) {
        setAlert({type: 'error', title: 'Конфигурация содержит ошибки'});
        return null;
    }
};
