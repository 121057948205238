import React, {memo} from 'react';
import {useGate, useStore} from 'effector-react';
import Header from '@shared/widgets/Header';
import {DashboardGate} from '@models/User';
import {$isOpenSettings} from '@models/Settings';
import {useDownloadMedia} from '@pages/Dashboard/hooks/useDownloadMedia';

import Sidebar from './components/Sidebar';
import Presentation from './components/Presentation';
import Settings from './components/Settings';
import {useSlickSidebar} from './hooks/useSlickSidebar';
import {useHotkeys} from './hooks/useHotkeys';
import styles from './Dashboard.module.scss';


const Dashboard = () => {
    useGate(DashboardGate)
    const isOpenSettings = useStore($isOpenSettings);

    const {
        sideBarWidth,
        onMouseDown, onMouseMove
    } = useSlickSidebar();

    useHotkeys();
    useDownloadMedia();

    return (
        <>
            <Header/>
            <div className={styles.container} onMouseMove={onMouseMove}>
                <Sidebar sideBarWidth={sideBarWidth} onMouseDown={onMouseDown}/>
                <Presentation/>
                {isOpenSettings && <Settings/>}
            </div>
        </>
    );
};

export default memo(Dashboard);
