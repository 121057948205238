import React, {FC, memo} from 'react';
import Text from '@shared/ui/Text';
import Input from '@shared/ui/Inputs/Input';

import {IFiledSingle} from '../interfaces';
import styles from '../Fields.module.scss';

import {useFieldInput} from './useFieldInput';

const FieldInput: FC<IFiledSingle> = ({durationMode, label, value, id, onSave, isReadOnly, isRequired, formKey}) => {
    const {
        localValue, isError, onInput, onSaveInput
    } = useFieldInput({id, value, formKey, onSave, isRequired});

    return (
        <div className={styles.container}>
            {label && <Text type={'caption'} className={styles.label}>{label}</Text>}
            <Input
                onBlur={onSaveInput}
                className={`${styles.input} ${isError ? styles.error : ''}`}
                name={`input-${id}`}
                value={localValue ?? ''}
                onInput={onInput}
                disabled={isReadOnly}
                durationMode={durationMode}
            />
        </div>
    );
};

export default memo(FieldInput);
