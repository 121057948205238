import {IFieldEvent, IForm} from '@models/Form/interfaces';

export const contrAgentFormatter = (form: IForm) => {
    const file: string | Blob = (form['contr-agent-form']['file'] as any) ?? '';
    const customData: IFieldEvent[] = [];
    const formData = new FormData();
    const contrAgentForm = form['contr-agent-form'];

    Object.keys(contrAgentForm).forEach(key => {
        if (key !== 'file') {
            customData.push({
                fieldId: Number(key),
                value: contrAgentForm[key]
            });
        }
    });

    formData.set('customData', JSON.stringify(customData));
    formData.set('file', file);

    return formData;
};
