import {ReactComponent as ClipBinIcon} from '@assets/icons/Sidebar/ClipBin.svg';
import {ReactComponent as UsersIcon} from '@assets/icons/Sidebar/Users.svg';
import {navIds} from '@models/Sidebar/interfaces';

export const headerNav = [
    {
        id: navIds.Packages,
        title: 'Пакеты',
        icon: (props?: any) => <ClipBinIcon {...props}/>
    },
    {
        id: navIds.ContrAgents,
        title: 'Контрагенты',
        icon: (props?: any) => <UsersIcon {...props}/>
    }
];
