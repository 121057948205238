import {ISystemData} from '@pages/Dashboard/components/Settings/components/SettingsProfile/interfaces';

export const defaultTemplateClient: ISystemData[] = [
    {
        label: 'Браузер',
        value: ''
    },
    {
        label: 'ОС',
        value: ''
    },
    {
        label: 'Монитор',
        value: ''
    },
    {
        label: 'ViewPort',
        value: ''
    },
    {
        label: 'PixelRatio',
        value: ''
    }
];

export const defaultTemplateSystem: ISystemData[] = [
    {
        label: 'Версии',
        value: ''
    },
    {
        label: 'Backend-app',
        value: ''
    },
    {
        label: 'Frontend-app',
        value: ''
    },
    {
        label: 'Сервер',
        value: ''
    },
    {
        label: 'База данных',
        value: ''
    }
];
