import {ChangeEvent} from 'react';
import {interval} from 'patronum';
import {createGate} from 'effector-react';
import {createEffect, createEvent, createStore} from 'effector';
import {getToken, login, logout, registration} from '@shared/api/endpoints/auth';
import {activateAccount} from '@shared/api/endpoints/user';

import {IContrAgentBase, ILoginPayload, IRegistrationPayload} from './interfaces';

export const getTokenFx = createEffect<void, any>(getToken);
export const loginFx = createEffect<ILoginPayload, any>(login);
export const logoutFx = createEffect<void, any>(logout);
export const activateAccountFx = createEffect<string, any, Error>(activateAccount);
export const registrationFx = createEffect<IRegistrationPayload, any, Error>(registration);

export const onGetToken = createEvent<void>();
export const onLogin = createEvent<any>();
export const onRegistration = createEvent<any>();
export const onLogout = createEvent<any>();
export const onStartAuthCounter = createEvent<any>();
export const setField = createEvent<any>();
export const onActivateAccount = createEvent<string>();
export const setIsAdmin = createEvent<boolean>();

export const AuthGate = createGate<void>();
export const DashboardGate = createGate<void>();
export const RegistrationGate = createGate<void>();

export const $loginForm = createStore({login: '', password: ''}).on(setField, (s, {key, value}) => ({
    ...s,
    [key]: value,
}));

export const $userData = createStore<any>(null)
    .on(getTokenFx.doneData, (_, data) => data)
    .on(registrationFx.doneData, (_, data) => data)
    .on(loginFx.doneData, (_, data) => data);

export const handleChange = setField.prepend((e: ChangeEvent<HTMLInputElement>) => ({
    key: e.target.name,
    value: e.target.value
}));

export const {tick: tickAuthRefresh} = interval({
    timeout: 900000,
    start: onStartAuthCounter
});

export const $contrAgentBase = createStore<IContrAgentBase | null>(null)
    .on(activateAccountFx.doneData, (_, data) => data);
export const $activationKey = createStore<string>('')
    .on(onActivateAccount, (_, key) => key);
export const $isAdmin = createStore<boolean>(false)
    .on(setIsAdmin, (_, flag) => flag);
export const $isAllowedUser = createStore<boolean>(false);

