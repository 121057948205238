import React, {FC, memo} from 'react';
import {ReactComponent as SearchIcon} from '@assets/icons/shared/Search.svg';

import styles from '../Input.module.scss';

import {IInputSearch} from './interfaces';

const InputSearch: FC<IInputSearch> = (props) => {
    const {
        inputRef,
        value,
        className,
        ...inputProps
    } = props;

    return (
        <div
            className={`${styles.containerReversed} ${className}`}
        >
            <div className={styles.searchIcon}><SearchIcon/></div>
            <input
                {...inputProps}
                ref={inputRef}
                value={value}
                className={`${styles.input} ${styles.searchInput} ${styles.default}`}
                onDrop={e => e.preventDefault()}
            />
        </div>
    );
};

export default memo(InputSearch);
