import React, {FC, memo} from 'react';
import {ReactComponent as PlusIcon} from '@assets/icons/Sidebar/Plus.svg';
import Text from '@shared/ui/Text';

import {ICategory} from './interfaces';
import CategoryFolder from './components/CategoryFolder';
import styles from './Category.module.scss';

const Category: FC<ICategory> = ({
    list, onContextMenu, currentDirectory,
    onSelect, onClick, normalWidth = -1,
    onOpenModal, actionTitle, multiple = false,
    onChecked, selected = {}
}) => {
    const onAddSection = onOpenModal ? () => onOpenModal('category', {id: 0}) : undefined;

    return (
        <>
            {actionTitle && (
                <div className={styles.plus} onClick={onAddSection}>
                    <PlusIcon/>
                    <Text type={'body2'} className={styles.title}>{actionTitle}</Text>
                </div>
            )}
            {list.map((item, index) => (
                <CategoryFolder
                    key={`tree-${item.id}-${index}`}
                    item={item}
                    nestingLvl={0}
                    normalWidth={normalWidth}
                    onContextMenu={onContextMenu}
                    multiple={multiple}
                    selected={selected}
                    currentDirectory={currentDirectory}
                    onSelect={onSelect}
                    onClick={onClick}
                    onOpenModal={onOpenModal}
                    onChecked={onChecked}
                />
            ))}
        </>
    );
};

export default memo(Category);
