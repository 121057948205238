import {useState, FormEvent} from 'react';

export const usePopups = () => {
    const [comment, setComment] = useState<string>('');
    const [commentChecked, setCommentChecked] = useState<boolean>(false);

    const onChangeComment = (e: FormEvent<HTMLTextAreaElement>) => {
        const target = e.target as HTMLTextAreaElement;
        setComment(target.value);
    };

    const onCommentChecked = () => setCommentChecked(prevState => !prevState);

    const resetComment = () => setComment('');

    return {
        comment, onChangeComment, resetComment,
        commentChecked, onCommentChecked
    };
};
