import {setAlert} from '@models/Alert';
import {ERROR_REQUEST} from '@shared/constants/common';

import {$clipbins, $media} from '../config';

export const downloadVideo = async (titleId: number): Promise<string> => {
    try {
        const {data, success, message} = await $media.get(`/download/${titleId}`);

        if (success) {
            return data;
        }

        setAlert({type: 'error', description: message, title: 'Ошибка при скачивании видео'});
        return '';
    } catch (e) {
        setAlert(ERROR_REQUEST);
        return '';
    }
};

export const downloadPackage = async (packageId: number): Promise<string> => {
    try {
        const {data, success, message} = await $clipbins.get(`/download/${packageId}`);

        if (success) {
            return data;
        }

        setAlert({type: 'error', description: message, title: 'Ошибка при скачивании пакета'});
        return '';
    } catch (e) {
        setAlert(ERROR_REQUEST);
        return '';
    }
};
