import React, {FC, memo, useState} from 'react';
import {ReactComponent as CloseIcon} from '@assets/icons/shared/Close.svg';
import {ReactComponent as SearchIcon} from '@assets/icons/shared/Search.svg';
import {useCheckIfClickedOutside} from '@shared/hooks/useCheckIfClickedOutside';
import Category from '@shared/components/Category';
import Button from '@shared/ui/Button';
import Input from '@shared/ui/Inputs/Input';
import Text from '@shared/ui/Text';

import styles from '../PopupWindow.module.scss';

import {IPopupMigrate} from './interface';

const PopupMigrate: FC<IPopupMigrate> = ({title = '', onClose, onSuccess, list}) => {
    const [searchValue, setSearchValue] = useState<string>('');

    const onChange = (e: React.FormEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        setSearchValue(target.value.trim());
    };

    const {popupRef, onMouseDown, contentRef} = useCheckIfClickedOutside({callback: onClose});

    return (
        <div
            ref={popupRef}
            className={styles.container}
            onMouseDown={onMouseDown}
        >
            <div ref={contentRef} className={styles.content}>
                <div className={styles.header}>
                    <Text className={styles.title} type='subtitle2'>{title}</Text>
                    <div className={`${styles.close} ${styles.icon}`} onClick={onClose}>
                        <CloseIcon className={styles.closeIcon}/>
                    </div>
                </div>
                <div className={styles.body}>
                    <Input
                        onChange={onChange}
                        icon={<SearchIcon/>}
                        placeholder='Введите запрос для поиска'
                        value={searchValue}
                        isReversed
                    />
                    <div className={styles.categoryWrapper}>
                        <Category
                            list={list}
                            onSelect={() => {}}
                            onClick={() => {}}
                            currentDirectory={-1}
                        />
                    </div>
                    <div className={styles.buttons}>
                        <Button onClick={onClose} size={'md'} className={styles.button} text='Отмена' theme='cancel'/>
                        <Button onClick={onSuccess} size={'md'} className={styles.button} text='Отправить'/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(PopupMigrate);
