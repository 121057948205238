import React, {FC, memo} from 'react';
import {createRange, createTemplateWidth} from '@shared/utils/generators';

import styles from './TableLoader.module.scss';
import {ITableLoader} from './interfaces';

const TableLoader: FC<ITableLoader> = ({rows = 3, cols = 5, isHideHeader = false, cellsSize}) => {
    return (
        <div className={styles.container}>
            {!isHideHeader &&
                <div className={styles.header}>
                    {createRange(cols).map(col => <div key={`th-${col}`} className={styles.th}/>)}
                </div>
            }
            {createRange(rows).map(row =>
                <span key={`tr-${row}`} className={styles.tr}>
                    {cellsSize ? (
                        cellsSize.map((size, index) =>
                            <span
                                key={`td-${row}-${index}-${size}`}
                                className={styles.td}
                                style={createTemplateWidth(size - 4, index === cellsSize.length - 1)}
                            />
                        )
                    ) : (
                        createRange(cols).map(col =>
                            <span key={`td-${row}-${col}`} className={styles.td}/>
                        )
                    )}
                </span>
            )}
        </div>
    );
};

export default memo(TableLoader);
