import {useStore} from 'effector-react';
import {$lists, setList} from '@models/Sidebar';
import {navIds} from '@models/Sidebar/interfaces';

export const useList = () => {
    const lists = useStore($lists);
    const toggleOpen = (section: navIds, key: string) => setList({section, key});

    return {
        lists, toggleOpen
    };
};
