import React, {FC, memo} from 'react';
import {ReactComponent as CloseIcon} from '@assets/icons/shared/Close.svg';
import {useCheckIfClickedOutside} from '@shared/hooks/useCheckIfClickedOutside';
import Button from '@shared/ui/Button';
import Input from '@shared/ui/Inputs/Input';
import Text from '@shared/ui/Text';

import styles from '../PopupWindow.module.scss';

import {IPopupForm} from './interfaces';

const PopupForm: FC<IPopupForm> = ({
  maxLength, name, value, placeholder = '', title = '',
  onChange, onClose, onSuccess, label
}) => {
    const {popupRef, onMouseDown, contentRef} = useCheckIfClickedOutside({callback: onClose});
    return (
        <div
            ref={popupRef}
            className={styles.container}
            onMouseDown={onMouseDown}
        >
            <div ref={contentRef} className={styles.content}>
                <div className={styles.header}>
                    <Text className={styles.title} type='subtitle2'>{title}</Text>
                    <div className={`${styles.close} ${styles.icon}`} onClick={onClose}>
                        <CloseIcon className={styles.closeIcon}/>
                    </div>
                </div>
                <div className={styles.body}>
                    {label && <Text type={'body2'} className={styles.label}>{label}</Text>}
                    <Input
                        maxLength={maxLength}
                        name={name}
                        value={value}
                        placeholder={placeholder}
                        onChange={onChange}
                        autoComplete={'off'}
                        autoFocus
                    />
                    <div className={styles.buttons}>
                        <Button onClick={onClose} size={'md'} className={styles.button} text='Отмена' theme='cancel'/>
                        <Button disabled={!value.length} onClick={onSuccess} size={'md'} className={styles.button} text='Готово'/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(PopupForm);
