import {onLogout} from "@models/User";

const isAuthError = (response: Response) => {
    const url = response.url.split('/').pop();
    if (response.status === 401 && url !== 'token') {
        onLogout()
    }
}

const Fetch = (baseUrl: string) => { // TODO: #1 Remove Authorization header (tmp option);
    const get = async (slug: string) => {
        const response = await fetch(baseUrl + slug, {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        });
        isAuthError(response);

        return response.json();
    };
    const post = async (slug: string, body: object = {}) => {
        const response = await fetch(baseUrl + slug, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            },
            body: JSON.stringify(body),
        });
        isAuthError(response);

        return response.json();
    };

    const postForm = async (slug: string, body: FormData = new FormData()) => {
        const response = await fetch(baseUrl + slug, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            },
            body
        });
        isAuthError(response);

        return response.json();
    };

    const del = async (slug: string, body: object = {}) => {
        const response = await fetch(baseUrl + slug, {
            credentials: 'include',
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            },
            body: JSON.stringify(body),
        });
        isAuthError(response);

        return response.json();
    };

    return {get, post, postForm, delete: del};
};

export default Fetch;
