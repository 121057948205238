import {useCallback, useEffect, useMemo, useState} from 'react';
import {deepCopy} from '@shared/utils/deepCopy';
import {IUseTargetRows} from '@shared/components/Table/interfaces';

export const useTargetRows = ({body, onChange, targets}: IUseTargetRows) => {
    const [mainCheckbox, setMainCheckbox] = useState<boolean>(false);

    const allIds = useMemo(() => {
        const ids: string[] = [];
        const flag = body.length <= targets.length;

        if (body.length) {
            body.forEach(row => ids.push(String(row.id)));

            if (targets.length) {
                setMainCheckbox(flag);
            } else {
                setMainCheckbox(false);
            }
        }

        return ids;
    }, [body]);

    const onChangeMain = () => setMainCheckbox(prevState => {
        if (onChange) {
            onChange(!prevState ? allIds : []);
        }
        return !prevState;
    });

    const toggleTarget = useCallback((id: string) => {
        const copyTargets: string[] = deepCopy(targets);
        const isIncludes = !(copyTargets.includes(id));
        const newState = isIncludes ? [...copyTargets, id] : copyTargets.filter(row => row !== id);

        if (onChange) {
            onChange(newState);
        }

        if (mainCheckbox && allIds.length !== newState.length) {
            setMainCheckbox(false);
        }

        if (!mainCheckbox && allIds.length === newState.length) {
            setMainCheckbox(true);
        }
    }, [targets, allIds, mainCheckbox]);

    useEffect(() => {
        if (mainCheckbox && onChange) {
            onChange(allIds);
        }
    }, [body, mainCheckbox, allIds]);

    return {
        mainCheckbox, onChangeMain, toggleTarget
    };
};
