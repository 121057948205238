import {createEffect, createEvent, createStore} from 'effector';
import {downloadPackage, downloadVideo} from '@shared/api/endpoints/media';

export const downloadVideoFx = createEffect<number, any, Error>(downloadVideo);
export const downloadPackageFx = createEffect<number, any, Error>(downloadPackage);

export const onDownLoadVideo = createEvent<number>();
export const onDownloadPackage = createEvent<any>();

export const $linkForDownloadVideo = createStore<string | string[]>('')
    .on(downloadPackageFx.doneData, (_, links) => links)
    .on(downloadVideoFx.doneData, (_, link) => link);
