import {ReactComponent as ContrAgentsIcon} from '@assets/icons/Settings/Conteragent.svg';
import {ReactComponent as SettingsIcon} from '@assets/icons/Settings/Settings.svg';

import {ESettingsPages} from '../../interfaces';

export const settingsTabs = [
    {
        name: ESettingsPages.SETT,
        label: 'Мой профиль',
        icon: <SettingsIcon/>
    },
    {
        name: ESettingsPages.CONTR,
        label: 'Поля контрагента',
        icon: <ContrAgentsIcon/>
    }
];
