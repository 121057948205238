import React, {FormEvent, memo, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useGate, useStore} from 'effector-react';
import {ReactComponent as HiddenPassIcon} from '@assets/icons/Login/HiddenPass.svg';
import {ReactComponent as ShownPassIcon} from '@assets/icons/Login/ShownPass.svg';
import {ReactComponent as LogoIcon} from '@assets/icons/Login/Logo.svg';
import {
    $contrAgentBase, $isAllowedUser,
    $loginForm, $userData,
    activateAccountFx,
    handleChange,
    onRegistration, registrationFx, RegistrationGate
} from '@models/User';
import Button from '@shared/ui/Button';
import Input from '@shared/ui/Inputs/Input';
import UsersLoader from '@shared/ui/Loaders/UsersLoader';
import Text from '@shared/ui/Text';
import {setAlert} from '@models/Alert';

import styles from './Registration.module.scss';
import User from './components/User';

const RegistrationPage = () => {
    useGate(RegistrationGate);
    const isAllowed = useStore($isAllowedUser);
    const [repeatPassword, setRepeatPassword] = useState<string>('');
    const [isPassShown, setPassVisible] = useState(false);
    const [isRPassShown, setRPassVisible] = useState(false);

    const userData = useStore($userData);
    const isLoginPending = useStore(registrationFx.pending);
    const {login, password} = useStore($loginForm);
    const contrAgent = useStore($contrAgentBase);
    const contrAgentPending = useStore(activateAccountFx.pending);
    const navigate = useNavigate();

    const handlePassVisibility = () => setPassVisible(prevValue => !prevValue);
    const handleRPassVisibility = () => setRPassVisible(prevValue => !prevValue);

    const onLoginFn = () => {
        if (repeatPassword === password) {
            onRegistration();
        } else {
            setAlert({type: 'error', title: 'Пароли не совпадают'});
        }
    };

    const onInput = (e: FormEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        setRepeatPassword(target.value);
    };

    useEffect(() => {
        if (userData && isAllowed) {
            navigate('/dashboard');
        }
    }, [userData, isAllowed]);

    return (
        <>
            <div className={styles.container}>
                <div className={styles.box}>
                    {contrAgentPending && <UsersLoader repeat={1}/>}
                    {(!contrAgentPending && !contrAgent) && <Text type={'body1'}>{'Ссылка-приглашение недействительна'}</Text>}
                    {contrAgent && (
                        <User name={contrAgent.name} logo={contrAgent.logo}/>
                    )}
                </div>
                <div className={`${styles.box} ${styles.center}`}>
                    <LogoIcon className={styles.logo}/>
                    <Text type={'body1'} className={styles.text}>{'Регистрация по ссылке-приглашению'}</Text>
                    <Input autoComplete='username' autoCapitalize='off' autoCorrect='off' className={styles.input}
                           maxLength={30}
                           onChange={handleChange} name='login' placeholder='Логин'
                           value={login}/>
                    <Input
                        className={styles.input}
                        icon={isPassShown ? <ShownPassIcon className={styles.icon}/> :
                            <HiddenPassIcon className={styles.icon}/>}
                        isReversed
                        maxLength={30}
                        onChange={handleChange}
                        onClickIcon={handlePassVisibility}
                        name='password'
                        placeholder='Пароль'
                        type={isPassShown ? 'text' : 'password'}
                        value={password}
                        autoComplete='off'
                    />
                    <Input
                        className={styles.input}
                        icon={isRPassShown ? <ShownPassIcon className={styles.icon}/> :
                            <HiddenPassIcon className={styles.icon}/>}
                        isReversed
                        maxLength={30}
                        onChange={onInput}
                        onClickIcon={handleRPassVisibility}
                        name='repeat-password'
                        placeholder='Повторите пароль'
                        type={isRPassShown ? 'text' : 'password'}
                        value={repeatPassword}
                        autoComplete='off'
                    />
                    <Button
                        className={styles.button}
                        isLoading={isLoginPending}
                        disabled={(password.length < 8 || password.length > 30) && !!contrAgent}
                        onClick={onLoginFn}
                        text='Войти'
                    />
                </div>
            </div>
        </>
    );
};

export default memo(RegistrationPage);
