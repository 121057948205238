import {IChangePasswordPayload} from '@models/User/changePassword/interfaces';
import {$auth, $user} from '@shared/api/config';
import {setAlert} from '@models/Alert';
import {ERROR_REQUEST} from '@shared/constants/common';
import {IContrAgent, IContrAgentEdit, IContrAgentStatus} from '@models/Sidebar/ContrАgents/interfaces';
import {IContrAgentBase} from '@models/User/interfaces';
import {IChangeLoginPayload} from '@models/User/changeLogin/interfaces';
import {onLogout} from '@models/User';

export const getContrAgents = async (query = '') => {
    try {
        const {data, success, message} = await $user.post('/list', {query});

        if (success) {
            return data;
        }

        setAlert({type: 'error', description: message, title: 'Ошибка при получении списка контрагентов'});
        return [];
    } catch (err) {
        setAlert(ERROR_REQUEST);
        return [];
    }
};

export const changePassword = async ({password}: IChangePasswordPayload) => {
    try {
        const {success, message} = await $auth.post('/changePassword', {password});

        if (success) {
            setAlert({type: 'success', title: 'Пароль установлен', description: 'Ваш пароль был успешно изменён'});
            onLogout();
        } else {
            setAlert({type: 'error', description: message, title: 'Ошибка при изменении пароля'});
        }
    } catch (err) {
        setAlert(ERROR_REQUEST);
    }
};

export const changeLogin = async ({login}: IChangeLoginPayload) => {
    try {
        const {success, message} = await $auth.post('/changeLogin', {login});

        if (success) {
            setAlert({type: 'success', title: 'Новый логин', description: 'Ваш логин был успешно изменён'});
            onLogout();
        } else {
            setAlert({type: 'error', description: message, title: 'Ошибка при изменении логина'});
        }
    } catch (err) {
        setAlert(ERROR_REQUEST);
    }
};

export const createUser = async (payload: FormData): Promise<null | IContrAgent> => {
    try {
        const {data, success, message} = await $user.postForm('/create', payload);

        if (success) {
            setAlert({type: 'success', title: 'Пользователь создан'});
            return data;
        }

        setAlert({type: 'error', description: message, title: 'Ошибка при создании контрагента'});
        return null;
    } catch (err) {
        setAlert(ERROR_REQUEST);
        return null;
    }
};

export const editUser = async ({formData, userId}: IContrAgentEdit): Promise<IContrAgent | null> => {
    try {
        const {success, message, data} = await $user.postForm(`/metaData/${userId}`, formData);

        if (success) {
            setAlert({type: 'success', title: 'Изменения внесены'});
            return data;
        }

        setAlert({type: 'error', description: message, title: 'Ошибка при изменении контрагента'});
        return null;
    } catch (err) {
        setAlert(ERROR_REQUEST);
        return null;
    }
};

export const getActivationKey = async (id: number) => {
    try {
        const {data, success, message} = await $user.post(`/activationKey/${id}`);

        if (success) {
            return data.activationKey;
        }

        setAlert({type: 'error', description: message, title: 'Ошибка при создании ссылки-приглашения'});
        return '';
    } catch (err) {
        setAlert(ERROR_REQUEST);
        return '';
    }
};

export const getOldActivationKey = async (id: number) => {
    try {
        const {data, success, message} = await $user.get(`/activationKey/${id}`);

        if (success) {
            return data.activationKey;
        }

        setAlert({type: 'error', description: message, title: 'Ошибка при получении ссылки-приглашения'});
        return '';
    } catch (err) {
        setAlert(ERROR_REQUEST);
        return '';
    }
};

export const activateAccount = async (key: string): Promise<IContrAgentBase | null> => {
    try {
        const {data, success, message} = await $user.post(`/profile/${key}`);

        if (success) {
            return data;
        }

        setAlert({type: 'error', description: message, title: 'Ошибка при активации аккаунта'});
        return null;
    } catch (err) {
        setAlert(ERROR_REQUEST);
        return null;
    }
};

export const getContrAgent = async (userId: number) => {
    try {
        const {data, success, message} = await $user.get(`/metaData/${userId}`);

        if (success) {
            return data;
        }

        setAlert({type: 'error', description: message, title: 'Ошибка при получении данных по контрагенту'});
        return null;
    } catch (err) {
        setAlert(ERROR_REQUEST);
        return null;
    }
};

export const deleteContrAgent = async (userId: number) => {
    try {
        const {success, message, data} = await $user.delete(`/${userId}`);

        if (success) {
            setAlert({type: 'success', title: 'Пользователь удален'});
            return data;
        }

        setAlert({type: 'error', description: message, title: 'Ошибка при удалении контрагента'});
        return null;
    } catch (err) {
        setAlert(ERROR_REQUEST);
        return null;
    }
};

export const changeUserStatus = async ({userId, statusId}: IContrAgentStatus) => {
    try {
        const {data, message, success} = await $user.post(`/status/${userId}`, {statusId});

        if (success) {
            return data;
        }

        setAlert({type: 'error', description: message, title: 'Ошибка при изменении статуса контрагента'});
        return null;
    } catch (err) {
        setAlert(ERROR_REQUEST);
        return null;
    }
};
