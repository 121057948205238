import {IDocument} from '@shared/components/Category/interfaces';
import {ITableColHeadState} from '@models/Table/interfaces';

export const maxTranslate = .8;
export const minTranslate = .40;

export const directoriesMigration: IDocument[] = [
    {
        id: 1,
        parentId: 0,
        name: 'main',
        type: 'section',
        children: [
            {
                id: 2,
                parentId: 1,
                name: 'ToDaletHD',
                type: 'section',
                children: []
            },
            {
                id: 3,
                parentId: 1,
                name: 'MakeProxy_HD',
                type: 'section',
                children: []
            },
            {
                id: 4,
                parentId: 1,
                name: 'Подкатегория',
                type: 'section',
                children: []
            }
        ]
    }
];

export const relatedSubtitlesHeaderRow: ITableColHeadState[] = [
    {
        id: 'track',
        title: 'Трек',
        type: 'string'
    },
    {
        id: 'description',
        title: 'Описание',
        type: 'string'
    },
    {
        id: 'subtitle',
        title: 'Подзаголовок',
        type: 'string'
    },
    {
        id: 'lang',
        title: 'Язык',
        type: 'string'
    }
];

export const informationHeaderRow: ITableColHeadState[] = [
    {
        id: 'store',
        title: 'Хранилище',
        type: 'string'
    },
    {
        id: 'mediaStatus',
        title: 'Медиа статус',
        type: 'string'
    },
    {
        id: 'file',
        title: 'Файл',
        type: 'string'
    },
    {
        id: 'duration',
        title: 'Длительность',
        type: 'string'
    },
    {
        id: 'format',
        title: 'Формат',
        type: 'string'
    },
    {
        id: 'probability',
        title: 'Вероятность',
        type: 'string'
    },
    {
        id: 'frames',
        title: 'Частота кадров',
        type: 'string'
    },
    {
        id: 'base',
        title: 'Базовый уровень',
        type: 'string'
    },
    {
        id: 'fileStatus',
        title: 'Статус',
        type: 'string'
    }
];

