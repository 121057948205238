export type TCell = 'string' | 'number' | 'time' | 'datetime' | 'date' | 'checkbox' | 'type' | 'status';

export interface ITableColState {
    [cell: string]: string | number;
}

export interface ITableSorting {
    sortingField: string;
    sortingType: number;
}

export interface ITableConfigPayload {
    sorting: {
        sortingField: string | null;
        sortingType: number | null;
    }
    categoryId: number;
}

export interface ITableColHeadState {
    id: string;
    title: string;
    type: TCell;
}

export interface ITableConfig {
    head: ITableColHeadState[];
    name: string;
    totalAmount: number;
    sorting: ITableSorting;
}

export interface ITableData {
    body: ITableColState[];
    currentPage: number;
}

export interface ITableRequest {
    page: number;
    limit: number;
    categoryId: number;
    isShortened: boolean;
}

export interface ITableConfigRequest {
    categoryId: number;
    sorting: ITableSorting;
}

export enum ETypesCurrentTable {
    NONE = 'none',
    PACKAGE = 'packages',
}

export interface IPayloadTitlesRemove {
    titleIds: number[]
}

export interface ITableQuery extends ITableData {
    head: ITableColHeadState[];
    totalAmount: number;
    sorting: ITableSorting;
}

export interface ITableQueryPayload {
    limit?: number;
    page: number;
    sortingField: string;
    sortingType: number;
    query: string;
}

export interface IPayloadTitlesInPackage {
    id: number;
    titleIds: number[]
}
