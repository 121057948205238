import React, {FC, memo, useCallback} from 'react';
import {ReactComponent as ArrowLeftIcon} from '@assets/icons/shared/DatePickerArrowLeft.svg';
import {ReactComponent as ArrowRightIcon} from '@assets/icons/shared/DatePickerArrowRight.svg';
import {ReactComponent as ArrowTopIcon} from '@assets/icons/shared/DatePickerArrowTop.svg';
import {ReactComponent as ArrowBottomIcon} from '@assets/icons/shared/DatePickerArrowUp.svg';
import {format} from 'date-fns';
import ru from 'date-fns/locale/ru';
import {useDayPicker, useNavigation, CaptionProps} from 'react-day-picker';

import styles from './Caption.module.scss';

const Caption: FC<CaptionProps> = ({displayMonth}) => {
    const {onMonthChange} = useDayPicker();
    const {previousMonth, nextMonth, goToMonth, goToDate} = useNavigation();

    const previousMonthClick = useCallback(() => {
        if (!previousMonth || !onMonthChange) {
            return undefined;
        }

        goToMonth(previousMonth);
        onMonthChange(previousMonth);
    }, [previousMonth, onMonthChange]);

    const nextMonthClick = useCallback(() => {
        if (!nextMonth || !onMonthChange) {
            return undefined;
        }

        goToMonth(nextMonth);
        onMonthChange(nextMonth);
    }, [nextMonth, onMonthChange]);

    const previousYearClick = useCallback(() => {
        if (!previousMonth || !onMonthChange) {
            return undefined;
        }

        const date = new Date(previousMonth);
        date.setFullYear(date.getFullYear() - 1);
        date.setMonth(date.getMonth() + 1);

        goToDate(date);
        onMonthChange(date);
    }, [previousMonth, onMonthChange]);

    const nextYearClick = useCallback(() => {
        if (!nextMonth || !onMonthChange) {
            return undefined;
        }

        const date = new Date(nextMonth);
        date.setFullYear(date.getFullYear() + 1);
        date.setMonth(date.getMonth() - 1);

        goToDate(date);
        onMonthChange(date);
    }, [nextMonth, onMonthChange]);

    return (
        <div className={styles.container}>
            <div className={styles.arrow} onClick={previousMonthClick}>
                <ArrowLeftIcon/>
            </div>

            <div className={styles.yearContainer}>
                <span>
                    {format(displayMonth, 'LLLL', {locale: ru})}
                </span>
                <span className={styles.year}>
                    {format(displayMonth, 'yyy', {locale: ru})}
                </span>
                <div className={styles.yearControl}>
                    <div className={`${styles.arrow} ${styles.customHeight}`} onClick={nextYearClick}>
                        <ArrowTopIcon/>
                    </div>
                    <div className={`${styles.arrow} ${styles.customHeight}`} onClick={previousYearClick}>
                        <ArrowBottomIcon/>
                    </div>
                </div>
            </div>

            <div className={styles.arrow} onClick={nextMonthClick}>
                <ArrowRightIcon/>
            </div>
        </div>
    );
};

export default memo(Caption);
