import React, {memo, FormEvent, useCallback} from 'react';
import {useGate, useStore} from 'effector-react';
import {usePopup} from '@shared/ui/Popups/PopupActions/hooks/usePopup';
import {ReactComponent as SearchIcon} from '@assets/icons/shared/Search.svg';
import {ReactComponent as PlusIcon} from '@assets/icons/Sidebar/Plus.svg';
import {IPopupChild, IPopupParent} from '@shared/ui/Popups/PopupActions/interfaces';
import PopupActions from '@shared/ui/Popups/PopupActions';
import {$currentPopupID} from '@models/PopupActions';
import Input from '@shared/ui/Inputs/Input';
import Text from '@shared/ui/Text';
import UsersLoader from '@shared/ui/Loaders/UsersLoader';
import {
    $contrAgents,
    $contrAgentsQuery,
    ContrAgentsGate, getContrAgentsFx, onChangeEditContrAgentTab,
    onForcedLoadContrAgents,
    onInputQuery, setContrAgentId, toggleCreateContrAgents
} from '@models/Sidebar/ContrАgents';
import {contrAgentsTabsTitle} from '@shared/ui/Popups/PopupContAgent/utils';

import {EUserStatusesReadable} from './components/User/interfaces';
import {contextMenuContrAgents} from './utils';
import User from './components/User';
import styles from './ContrAgents.module.scss';

const ContrAgents = () => {
    useGate(ContrAgentsGate);
    const currentPopupID = useStore($currentPopupID);
    const query = useStore($contrAgentsQuery);
    const contrAgents = useStore($contrAgents);
    const isLoading = useStore(getContrAgentsFx.pending);

    const onChange = (e: FormEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        onInputQuery(target.value);
    };

    const {
        containerRef, contextMenuCoords, data, popupRef, isLeftMode, popupID,
        onStopPropagation, onContextMenu, onSelect, onClose
    } = usePopup();

    const onPopupAction = useCallback((target: IPopupParent | IPopupChild) => {
        switch (target.name) {
            case 'edit':
                toggleCreateContrAgents('edit');
                setContrAgentId(data.id);
                break;
            case 'access':
                onChangeEditContrAgentTab(contrAgentsTabsTitle[1].key);
                toggleCreateContrAgents('edit');
                setContrAgentId(data.id);
                break;
            default: break;
        }
        onClose();
    }, [data]);

    return (
        <div className={styles.container}>
            <div className={styles.field}>
                <Input
                    name={'filter'}
                    placeholder={'Поиск'}
                    icon={<SearchIcon/>}
                    value={query}
                    onChange={onChange}
                    onClickIcon={onForcedLoadContrAgents}
                    isReversed
                />
            </div>
            <div className={styles.plus} onClick={() => toggleCreateContrAgents('create')}>
                <PlusIcon/>
                <Text type={'body2'} className={styles.title}>{'Добавить контрагента'}</Text>
            </div>
            <div className={styles.list}>
                {isLoading ? (
                    <UsersLoader repeat={10}/>
                ) : (
                    contrAgents.map(({id, name, login, logo, statusId}, index) => (
                        <User
                            key={`user-${name}-${index}`}
                            login={login}
                            name={name}
                            id={id}
                            status={EUserStatusesReadable[statusId - 1]}
                            logo={logo}
                            onSelect={onSelect}
                            onContextMenu={onContextMenu}
                        />
                    ))
                )}
                {(!contrAgents.length && !isLoading) && <Text type={'body2'} className={styles.plug}>{'Нет данных'}</Text>}
            </div>

            <div
                ref={containerRef}
                onClick={onStopPropagation}
                onContextMenu={onStopPropagation}
                className={styles.contextMenu}
                style={(currentPopupID === popupID && contextMenuCoords) ? contextMenuCoords : {visibility: 'hidden'}}
            >
                <PopupActions
                    refObj={popupRef}
                    list={contextMenuContrAgents}
                    onClick={onPopupAction}
                    isLeftMode={isLeftMode}
                />
            </div>
        </div>
    );
};

export default memo(ContrAgents);
