import {MouseEvent} from 'react';
import {IGenericPopupData} from '@shared/ui/Popups/PopupActions/interfaces';

export interface IUser {
    id: number;
    login: string;
    logo: string;
    name: string;
    status: string;
    onSelect: (data: IGenericPopupData | string) => void;
    onContextMenu?: (e: MouseEvent<HTMLDivElement>) => void;
}

export enum EUserStatusesReadable {'Новый', 'Активный', 'Архивный'}
