import {DragEvent, useRef, useCallback, useState} from 'react';
import {onActivateDropZone} from '@models/Form';
import {ITableColState} from '@models/Table/interfaces';

import {IRowLinks, IUseDragRow} from '../interfaces';

export const useDragRow = ({targets, tableWidth, body}: IUseDragRow) => {
    const [currentId, setCurrentId] = useState<string>('');
    const links = useRef<IRowLinks>({});
    const rowHeight = 40;

    const onUpdateRowStyles = (node: HTMLDivElement, x: number, y: number) => {
        node.style.position = 'fixed';
        node.style.zIndex = '11';
        node.style.pointerEvents = 'none';
        node.style.top = `${y}px`;
        node.style.left = `${x}px`;
        node.style.transform = `rotate(0deg) translate(0, -100%)`;
    };

    const onClearRowStyles = useCallback((node: HTMLDivElement) => {
        if (tableWidth) {
            node.style.cssText = `min-width: ${tableWidth.minWidth};`;
        }
    }, [tableWidth]);

    const onDragStart = useCallback((event: DragEvent<HTMLDivElement>, id: string) => {
        const dataTransfer: ITableColState[] = [];

        if (!(targets.includes(id))) {
            body.forEach(row => {
                if (id === String(row.id)) {
                    dataTransfer.push(row)
                }
            });
        } else {
            body.forEach(row => {
                if (targets.includes(String(row.id))) {
                    dataTransfer.push(row);
                }
            });
        }

        event.dataTransfer.setData('text/plain', JSON.stringify(dataTransfer));
        event.dataTransfer.setDragImage(new Image(0, 0), -10, -10);

        setCurrentId(id);
        onActivateDropZone(true);
    }, [targets, body]);

    const onDrag = useCallback((event: DragEvent<HTMLDivElement>) => {
        if (!(targets.includes(currentId))) {
            const selectedRow = links.current[currentId]?.current;
            if (selectedRow) {
                onUpdateRowStyles(selectedRow, event.pageX, event.pageY + rowHeight);
            }
            return undefined;
        }

        targets.forEach((id, index) => {
            const selectedRow = links.current[id]?.current;

            if (selectedRow) {
                onUpdateRowStyles(selectedRow, event.pageX, event.pageY + rowHeight * index);
            }
        });
    }, [targets, links, currentId]);

    const onDragEnd = useCallback(() => {
        onActivateDropZone(false);

        if (!(targets.includes(currentId))) {
            const selectedRow = links.current[currentId]?.current;
            if (selectedRow) {
                onClearRowStyles(selectedRow);
            }
            return undefined;
        }

        targets.forEach(id => {
            const selectedRow = links.current[id]?.current;

            if (selectedRow) {
                onClearRowStyles(selectedRow);
            }
        });
    }, [targets, currentId]);

    return {
        links,
        onDragStart, onDragEnd, onDrag
    };
};
