export const doubleSort = (array: any[], field1: string, field2: string) =>
    array.sort((a, b) => a[field1].localeCompare(b[field1]) || a[field2].localeCompare(b[field2]));

export const singleSort = (array: any[], field: string) =>
    array.sort((a, b) => a[field].localeCompare(b[field]));

export const binarySearch = (array: any[], desired: number, filed?: string) => {
    let min = 0;
    let max = array.length - 1;

    if (desired < 0) {
        return '';
    }

    while (min <= max) {
        let middle = Math.floor((min + max) / 2);
        let guess = filed ? array[middle][filed] : array[middle];

        if (guess === desired) {
            return array[middle];
        }

        if (guess > desired) {
            max = middle - 1;
        } else {
            min = middle + 1;
        }
    }

    return '';
};
