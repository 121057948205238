import {ITableColHeadState} from '@models/Table/interfaces';

export const ERROR_REQUEST = {type: 'error', title: 'Что-то пошло не так. Мы уже работаем над этим'};

export const CHUNK_SIZE = 100 * 1024 * 1024;

export const shortenedTableHead: ITableColHeadState[] = [
    {
        id: 'checkbox',
        title: '',
        type: 'checkbox'
    },
    {
        id: 'type',
        title: 'Тип',
        type: 'type'
    },
    {
        id: 'status',
        title: 'Статус',
        type: 'status'
    },
    {
        id: 'name',
        title: 'Имя',
        type: 'string'
    }
];
