import {
    IPayloadTitlesRemove,
    ITableColState, ITableQuery, ITableQueryPayload,
} from '@models/Table/interfaces';
import {setAlert} from '@models/Alert';
import {ERROR_REQUEST} from '@shared/constants/common';

import {$titles} from '../config';
import {voidTableQuery} from '../utils';

export const renameTitle = async ({id, name}: {id: string, name: string}): Promise<ITableColState> => {
    try {
        const {message, success, data} = await $titles.post('/edit', {
            id: Number(id),
            name
        });

        if (success) {
            return data;
        }

        setAlert({type: 'error', description: message, title: 'Ошибка при изменении названия тайтла'});
        return {id: -1, name: ''};
    } catch (err) {
        setAlert(ERROR_REQUEST);
        return {id: -1, name: ''};
    }
};

export const getTableFromQuery = async ({page, query, sortingType, sortingField, limit = 40}: ITableQueryPayload): Promise<ITableQuery> => {
    try {
        const {data, message, success} = await $titles.post('/search', {page, query, limit, sortingType, sortingField});

        if (success) {
            return data;
        }

        setAlert({type: 'error', description: message, title: 'Ошибка при выполнении поиска'});
        return voidTableQuery;
    } catch (err) {
        setAlert(ERROR_REQUEST);
        return voidTableQuery;
    }
};

export const deleteTitles = async ({titleIds}: IPayloadTitlesRemove) => {
    try {
        const {message, success} = await $titles.post('/delete', {titleIds});

        if (success) {
            return titleIds;
        }

        setAlert({type: 'error', description: message, title: 'Ошибка при удалении материалов'});
        return null;
    } catch (e) {
        setAlert(ERROR_REQUEST);
        return null;
    }
};

