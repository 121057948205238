import {useMemo} from 'react';

export const useLogoPlug = (name: string) => {
    const text = useMemo(() => {
        const formatName = name.trim();

        if (formatName.length === 1) {
            return formatName.toUpperCase();
        }

        const splitName = name.split(' ');

        if (splitName.length >= 2 && splitName[1]) {
            return `${splitName[0][0]}${splitName[1][0]}`.toUpperCase();
        }

        return `${splitName[0][0]}${splitName[0][1]}`.toUpperCase();
    }, [name]);

    return {text};
};
