import React, {FC, memo} from 'react';
import Text from '@shared/ui/Text';
import Logo from '@shared/ui/Logo';

import styles from './User.module.scss';
import {IUser} from './interfaces';

const User: FC<IUser> = ({avatar, job, name}) => {
    return (
        <div className={styles.container}>
            <div className={styles.bio}>
                <Logo src={avatar} name={name} isCircle={false}/>

                <div className={styles.information}>
                    <Text type={'body2'} className={styles.name}>{name}</Text>
                    <Text type={'caption'} className={styles.job}>{job}</Text>
                </div>
            </div>
        </div>
    );
};

export default memo(User);
