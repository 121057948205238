import React, {memo} from 'react';
import {useStore} from 'effector-react';
import {useNavigate} from 'react-router-dom';
import {ReactComponent as LogoutIcon} from '@assets/icons/Settings/Logout.svg';
import {$isAdmin, onLogout} from '@models/User';
import {$currentSettingsTab, onChangeSettingsTab} from '@models/Settings';
import Text from '@shared/ui/Text';

import styles from './SettingsSidebar.module.scss';
import {settingsTabs} from './utils';

const SettingsSidebar = () => {
    const currentTab = useStore($currentSettingsTab);
    const navigate = useNavigate();
    const isAdmin = useStore($isAdmin);
    const onLogoutFn = () => {
        onLogout();
        navigate('/login');
    };

    return (
        <div className={styles.container}>
            <div className={styles.tabs}>
                {isAdmin ? (
                    settingsTabs.map(({name, label, icon}) => (
                        <div
                            key={`${name}-${label}`}
                            className={`${styles.tab} ${currentTab === name ? styles.activeTab : ''}`}
                            onClick={() => onChangeSettingsTab(name)}
                        >
                            <div className={styles.tabIcon}>
                                {icon}
                            </div>
                            <Text className={styles.tabText} type={'body1'}>{label}</Text>
                        </div>
                    ))
                ) : (
                    <div className={`${styles.tab} ${styles.activeTab}`}>
                        <div className={styles.tabIcon}>
                            {settingsTabs[0].icon}
                        </div>
                        <Text className={styles.tabText} type={'body1'}>{settingsTabs[0].label}</Text>
                    </div>
                )}
            </div>
            <div className={`${styles.button} ${styles.fill}`} onClick={onLogoutFn}>
                <Text className={styles.text} type={'body2'}>{'Выход'}</Text>
                <LogoutIcon/>
            </div>
        </div>
    );
};

export default memo(SettingsSidebar);
