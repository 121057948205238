export const browserVersion = (userAgent: string, regex: RegExp) => {
    const match = userAgent.match(regex);
    return match ? match[2] : null;
};

export const getBrowser = () => {
    const userAgent = navigator.userAgent;
    let browser = 'Неизвестно';

    browser = /ucbrowser/i.test(userAgent) ? 'UCBrowser' : browser;
    browser = /edg/i.test(userAgent) ? 'Edge' : browser;
    browser = /googlebot/i.test(userAgent) ? 'GoogleBot' : browser;
    browser = /chromium/i.test(userAgent) ? 'Chromium' : browser;
    browser =
        /firefox|fxios/i.test(userAgent) && !/seamonkey/i.test(userAgent)
            ? 'Firefox'
            : browser;
    browser =
        /; msie|trident/i.test(userAgent) && !/ucbrowser/i.test(userAgent)
            ? 'IE'
            : browser;
    browser =
        /chrome|crios/i.test(userAgent) &&
        !/opr|opera|chromium|edg|ucbrowser|googlebot/i.test(userAgent)
            ? 'Chrome'
            : browser;
    browser =
        /safari/i.test(userAgent) &&
        !/chromium|edg|ucbrowser|chrome|crios|opr|opera|fxios|firefox/i.test(
            userAgent
        )
            ? 'Safari'
            : browser;
    browser = /opr|opera/i.test(userAgent) ? 'Opera' : browser;

    switch (browser) {
        case 'UCBrowser':
            return `${browser} (${browserVersion(
                userAgent,
                /(ucbrowser)\/([\d\.]+)/i
            )})`;
        case 'Edge':
            return `${browser} (${browserVersion(
                userAgent,
                /(edge|edga|edgios|edg)\/([\d\.]+)/i
            )})`;
        case 'GoogleBot':
            return `${browser} (${browserVersion(
                userAgent,
                /(googlebot)\/([\d\.]+)/i
            )})`;
        case 'Chromium':
            return `${browser} (${browserVersion(
                userAgent,
                /(chromium)\/([\d\.]+)/i
            )})`;
        case 'Firefox':
            return `${browser} (${browserVersion(
                userAgent,
                /(firefox|fxios)\/([\d\.]+)/i
            )})`;
        case 'Chrome':
            return `${browser} (${browserVersion(
                userAgent,
                /(chrome|crios)\/([\d\.]+)/i
            )})`;
        case 'Safari':
            return `${browser} (${browserVersion(
                userAgent,
                /(safari)\/([\d\.]+)/i
            )})`;
        case 'Opera':
            return `${browser} (${browserVersion(
                userAgent,
                /(opera|opr)\/([\d\.]+)/i
            )})`;
        case 'IE':
            const version = browserVersion(userAgent, /(trident)\/([\d\.]+)/i);
            return version
                ? `${browser} (${parseFloat(version) + 4.0})`
                : `${browser} (7.0)`;
        default:
            return 'Неизвестно (0.0.0.0)';
    }
};

export const getOS = () => {
    const userAgent = window.navigator.userAgent;
    const startIndex = userAgent.indexOf('(') + 1;
    const endIndex = userAgent.indexOf(')');
    return userAgent.substring(startIndex, endIndex).replace(/[_;]/g, '.');
};

export const getIsRetina = () => window.devicePixelRatio > 1 ? 'Retina display' : 'Normal display';

export const getViewPort = () => {
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
    return `${viewportWidth}x${viewportHeight}`;
};

export const getScreenSize = () => {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    return `${screenWidth}x${screenHeight}`;
};

