import {FC, memo} from 'react';
import InputMask from 'react-input-mask';

import styles from '../Input.module.scss';

import {InputProps} from './interfaces';

const Input: FC<InputProps> = (props) => {
    const {
        className,
        icon,
        isReversed = false,
        onClickIcon = null,
        scale = 'lg',
        theme = 'default',
        durationMode = false,
        ...inputProps
    } = props;

    return (
        <div className={`${isReversed ? styles.containerReversed : styles.container} ${className}`}>
            {icon && <div className={`${styles.icon} ${props.value ? styles.activeIcon : ''}`} onClick={onClickIcon}>{icon}</div>}
            {durationMode ? (
                <InputMask
                    mask={'99:99:99.999'}
                    {...inputProps}
                    className={`${styles.input} ${theme ? ` ${styles[theme]}` : ''} ${styles[scale]}`}
                    style={{[`padding${isReversed ? 'Right' : 'Left'}`]: icon ? 56 : 12}}
                    onDrop={e => e.preventDefault()}
                />
            ) : (
                <input
                    {...inputProps}
                    className={`${styles.input} ${theme ? ` ${styles[theme]}` : ''} ${styles[scale]}`}
                    style={{[`padding${isReversed ? 'Right' : 'Left'}`]: icon ? 56 : 12}}
                    onDrop={e => e.preventDefault()}
                />
            )}
        </div>
    );
};

export default memo(Input);
