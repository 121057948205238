import {useEffect, useState, RefObject, useCallback} from 'react';

type rects = 'height' | 'width' | 'left' | 'top';

export const useElementRect = (element: RefObject<HTMLElement | HTMLTableElement> | null, rect: rects = 'width', useOffset: boolean = true) => {
    const [size, setSize] = useState(0);

    const updateSize = useCallback(() => {
        if (element && element.current) {
            const offset = element.current.getBoundingClientRect()[rect];
            if (useOffset) {
                const innerSize = rect === 'width' || rect === 'left' ? window.innerWidth : window.innerHeight;
                setSize(innerSize - offset);
            } else {
                setSize(offset);
            }
        }
    }, [element, element?.current, rect, useOffset]);

    useEffect(() => updateSize(), [element, element?.current]);
    useEffect(() => {
        window.addEventListener('resize', updateSize);

        return function clear () {
            window.removeEventListener('resize', updateSize);
        };
    }, [element, element?.current, rect]);

    return size;
};
