import React, {FC, memo} from 'react';
import {ReactComponent as ArrowTopIcon} from '@assets/icons/Sidebar/InlineDropDownUp.svg';
import {ReactComponent as ArrowBottomIcon} from '@assets/icons/Sidebar/InlineDropDownBottom.svg';
import {createTemplateWidth} from '@shared/utils/generators';
import Text from '@shared/ui/Text';
import CheckBox from '@shared/ui/CheckBox';

import styles from './TitleCol.module.scss';
import {ITitleCol} from './interfaces';

const TitleCol: FC<ITitleCol> = ({
  keyCol, index, colsWidth, value,
  onMouseDown, targetWidget, onSort, sortBy,
  checked, onChangeChecked
}) => {
    return (
        <div
            className={`${styles.container} ${keyCol ? styles.cursor : ''}`}
            style={createTemplateWidth(colsWidth[keyCol])}
        >
            <div
                onMouseDown={onMouseDown ? () => onMouseDown(index) : undefined}
                className={`${styles.line} ${!onMouseDown ? styles.disabledLine : ''} ${targetWidget === index ? styles.lineCurrent : ''}`}
            />

            {value ? (
                <span
                    className={`${styles.headerTitle} ${(sortBy.sortingField === keyCol && sortBy.sortingType) ? styles.sort : ''}`}
                    onClick={keyCol ? () => onSort(keyCol) : undefined}
                >
                <Text className={styles.title} type='subtitle2'>{value}</Text>
                    <span className={styles.icon}>
                        {sortBy.sortingField === keyCol ? (
                            <React.Fragment>
                                {(sortBy.sortingType === 1 || !sortBy.sortingType) && <ArrowTopIcon/>}
                                {sortBy.sortingType === -1 && <ArrowBottomIcon/>}
                            </React.Fragment>
                        ) : (
                            <ArrowTopIcon/>
                        )}
                    </span>
            </span>
            ) : (
                <div className={styles.box}>
                    <CheckBox
                        checked={checked}
                        onChange={onChangeChecked}
                        label={''}
                    />
                </div>
            )}
        </div>
    );
};

export default memo(TitleCol);
