import React, {FC, memo, useState} from 'react';
import {useCheckIfClickedOutside} from '@shared/hooks/useCheckIfClickedOutside';
import {DayPicker} from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import ru from 'date-fns/locale/ru';

import Caption from './components/Caption';
import Field from './components/Field';
import {useDatePicker} from './hooks/useDatePicker';
import styles from './DatePicker.module.scss';
import {IDatePicker} from './interfaces';

const DatePicker: FC<IDatePicker> = ({disabled, defaultValue, onChangeDate, className = ''}) => {
    const [isVisible, setVisibility] = useState<boolean>(false);
    const toggleVisibility = (flag: boolean) => setVisibility(flag);

    const {
        currentDate, month, isError,
        dateField, durationField,
        onSelect, onMonthChange, onDurationInput, onDateInput, onClear
    } = useDatePicker({toggleVisibility, defaultValue, onChangeDate});

    const {
        popupRef, onMouseDown, contentRef
    } = useCheckIfClickedOutside({callback: () => toggleVisibility(false)});

    const classNames = {
        day_selected: styles.selected,
        day_today: styles.toDay,
        head: styles.head,
        button: styles.button,
        tbody: styles.body
    };

    return (
        <div className={`${styles.container} ${className}`}>
            <Field
                disabled={disabled}
                isError={isError}
                isVisible={isVisible}
                dateField={dateField}
                durationField={durationField}
                placeholder={'Выберите дату и время'}
                toggleVisibility={toggleVisibility}
                onDateInput={onDateInput}
                onDurationInput={onDurationInput}
                onClear={onClear}
            />
            {isVisible && (
                <div
                    className={styles.modal}
                    ref={popupRef}
                    onMouseDown={onMouseDown}
                >
                    <div className={styles.datePicker} ref={contentRef}>
                        <DayPicker
                            locale={ru}
                            mode={'single'}
                            components={{Caption}}
                            selected={currentDate}
                            month={month}
                            classNames={classNames}
                            onSelect={onSelect}
                            onMonthChange={onMonthChange}
                            showOutsideDays
                            fixedWeeks
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default memo(DatePicker);
