import {IPopupParent, popupItemTypes} from '@shared/ui/Popups/PopupActions/interfaces';

export const contextMenuCategory: IPopupParent[] = [
    {
        type: popupItemTypes.ACTION,
        title: 'Изменить',
        name: 'edit',
        endCategory: false,
        disabled: false,
        list: []
    },
    {
        type: popupItemTypes.ACTION,
        title: 'Удалить',
        name: 'delete',
        endCategory: false,
        disabled: false,
        list: []
    }
];
