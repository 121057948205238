import {createEffect, createEvent, createStore} from 'effector';
import {deleteTitles, getTableFromQuery, renameTitle} from '@shared/api/endpoints/table';
import {getPackageConfig, getPackageTable} from '@shared/api/endpoints/sidebar';
import {
    ETypesCurrentTable, IPayloadTitlesRemove,
    ITableConfig,
    ITableData,
    ITableQueryPayload,
    ITableSorting
} from '@models/Table/interfaces';
import {defaultSortState, mergeTableData} from '@models/Table/utils';
import {voidGridConfig, voidGridState} from '@shared/api/utils';
import {ChangeEvent} from 'react';

export const renameTitleFx = createEffect<any>(renameTitle);
export const getPackageConfigFx = createEffect<any, ITableConfig, Error>(getPackageConfig);
export const getPackageTableFx = createEffect<any, ITableData, Error>(getPackageTable);
export const getTableFromQueryFx = createEffect<ITableQueryPayload, any, Error>(getTableFromQuery);
export const deleteTitlesInPackageFx = createEffect<IPayloadTitlesRemove, any, Error>(deleteTitles);


export const deleteGrid = createEvent<any>();
export const searchGrid = createEvent<any>();
export const prevPage = createEvent<any>();
export const nextPage = createEvent<any>();
export const setPage = createEvent<number>();
export const setSortingTable = createEvent<ITableSorting>();
export const onChangeTitleName = createEvent<ChangeEvent<HTMLInputElement>>();
export const setDefaultName = createEvent<string>('');
export const saveNewTitleName = createEvent<void>();
export const setCurrentRowId = createEvent<string>();
export const updateTable = createEvent<ITableData>();
export const toggleIsTileMode = createEvent<any>();
export const setCurrentTableType = createEvent<any>();
export const onChangeTableTargets = createEvent<string[]>();
export const onChangeTableSearch = createEvent<string>();
export const setTargetInput = createEvent<any>();
export const onForciblyUpdateConfig = createEvent<ITableConfig>();
export const onForciblyUpdateTable = createEvent<ITableData>();
export const onDeleteTitleInPackage = createEvent<IPayloadTitlesRemove>();

export const $page = createStore<number>(1)
    .on(setPage, (_, data) => data)
    .on(prevPage, (currentPage) => currentPage > 1 ? currentPage - 1 : currentPage)
    .on(nextPage, (currentPage) => currentPage + 1);

export const $tableConfig = createStore<ITableConfig>(voidGridConfig)
    .on(onForciblyUpdateConfig, (_, data) => data)
    .on(getPackageConfigFx.doneData, (_, data) => data);
export const $tableData = createStore<ITableData>(voidGridState)
    .on(updateTable, (_, data) => data)
    .on(onForciblyUpdateTable, mergeTableData)
    .on(getPackageTableFx.doneData, mergeTableData);
export const $tableTargets = createStore<string[]>([])
    .on(onChangeTableTargets, (_, rowsId) => rowsId);

export const $sortingTable = createStore<ITableSorting>(defaultSortState)
    .on(setSortingTable, (_, sorting) => sorting);

export const $newTitleName = createStore<string>('')
    .on(setDefaultName, (_, value) => value)
    .on(onChangeTitleName, (_, e) => e.target.value);

export const $currentRowId = createStore<string>('')
    .on(setCurrentRowId, (_, value) => value);

export const $isTileMode = createStore<boolean>(false)
    .on(toggleIsTileMode, state => !state);
export const $typeCurrentTable = createStore<ETypesCurrentTable>(ETypesCurrentTable.NONE)
    .on(setCurrentTableType, (_, data) => data);

export const $activeSearchInput = createStore<boolean>(false)
    .on(setTargetInput, (state) => !state);
export const $tableQuery = createStore('')
    .on(onChangeTableSearch, (_, search) => search);
