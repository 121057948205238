import React, {FC, memo} from 'react';
import {createRange} from '@shared/utils/generators';

import {IFormLoader} from './interfaces';
import styles from './FormLoader.module.scss';

const FormLoader: FC<IFormLoader> = ({repeat = 1, rows = 1, isHideHeader= false}) => {
    return (
        <>
            {createRange(repeat).map(block => (
                <div key={`block-${block}`} className={styles.container}>
                    {!isHideHeader && <div className={styles.header}/>}
                    {createRange(rows).map(row => <div key={`block-row-${row}`} className={styles.row}/>)}
                </div>
            ))}
        </>
    );
};

export default memo(FormLoader);
