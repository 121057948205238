import React, {FC, memo, MouseEvent, useCallback, useMemo} from 'react';
import Text from '@shared/ui/Text';
import CheckBox from '@shared/ui/CheckBox';
import {getCellValue} from '@shared/components/Table/components/Row/utils';
import {TCell} from '@models/Table/interfaces';

import {ITileCard} from './interfaces';
import styles from './Card.module.scss';

const Card: FC<ITileCard> = ({head, targets, labels, data, preview, onContextMenu, onSelect, doubleClick, toggleTarget}) => {
    const idCard = useMemo(() => String(data.id), [data]);
    const checked = useMemo(() => targets.includes(idCard), [targets, idCard]);

    const onLocalContextMenu = useCallback((e: MouseEvent<HTMLDivElement>) => {
        onContextMenu(e);
        onSelect(idCard);
    }, [idCard]);

    const getType = useCallback((cell: string) => {
        let result: TCell = 'string';
        head.forEach(({id, type}) => {
            if (id === cell) {
                result = type;
            }
        });
        return result;
    }, [head]);

    return (
        <div
            className={`${styles.container} ${checked ? styles.active : ''}`}
            onContextMenu={onLocalContextMenu}
            onDoubleClick={() => doubleClick(idCard)}
        >
            <div className={styles.header}>
                <div className={styles.box}>
                    <CheckBox
                        label={''}
                        checked={checked}
                        onChange={() => toggleTarget(idCard)}
                    />
                </div>

                <div className={styles.type}>{getCellValue('type', getType(String(data.type)))}</div>
                <Text type={'caption'} className={styles.status}>{data.status}</Text>
            </div>

            <div className={styles.preview}>
                <img src={preview} alt={'preview'}/>
            </div>

            <div className={styles.info}>
                {Object.keys(data).map((key) => (
                    labels[key] && (
                        <div className={styles.row} key={`tile-row-${idCard}-${key}`}>
                            <Text type={'body2'} className={styles.label}>{labels[key]}</Text>
                            <Text type={'body2'} className={styles.text}>{getCellValue(getType(key), data[key])}</Text>
                        </div>
                    )
                ))}
            </div>
        </div>
    );
};

export default memo(Card);
