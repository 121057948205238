import React, {memo} from 'react';
import {useGate, useStore} from 'effector-react';
import {$currentSettingsTab, SettingsGate} from '@models/Settings';

import SettingsHeader from './components/SettingsHeader';
import SettingsSidebar from './components/SettingsSidebar';
import {settingsContent} from './utils';
import styles from './Settings.module.scss';

const Settings = () => {
    const currentTab = useStore($currentSettingsTab);
    useGate(SettingsGate);

    return (
        <div className={styles.container}>
            <SettingsHeader/>
            <div className={styles.content}>
                <SettingsSidebar/>
                {settingsContent[currentTab]}
            </div>
        </div>
    );
};

export default memo(Settings);
