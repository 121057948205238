import {useCallback, useRef, MouseEvent, useEffect} from 'react';
import {useStore} from 'effector-react';
import {$currentPopupID} from '@models/PopupActions';

export const useCheckIfClickedOutside = ({callback, ignoreContextMenu = false}: {callback: () => void, ignoreContextMenu?: boolean}) => {
    const popupRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const currentPopupId = useStore($currentPopupID);

    const onMouseDown = useCallback((e: MouseEvent<HTMLDivElement>) => {
        if (popupRef.current?.contains(e.target as Node) && !contentRef.current?.contains(e.target as Node)) {
            callback();
        }
    }, [popupRef, contentRef]);

    useEffect(() => {
        if (currentPopupId && !ignoreContextMenu) {
            callback();
        }
    }, [currentPopupId, ignoreContextMenu]);

    return {popupRef, contentRef, onMouseDown};
};
