import React, {FC, memo, useMemo} from 'react';
import {createTemplateWidth} from '@shared/utils/generators';
import CheckBox from '@shared/ui/CheckBox';
import Text from '@shared/ui/Text';

import {getCellClassName, getCellValue} from '../../utils';
import styles from '../../Row.module.scss';

import {ICell} from './interfaces';

const Cell: FC<ICell> = ({type, id, rowId, value, checked, colsWidth, toggleTarget}) => {
    const classes = useMemo(() => getCellClassName(type), [type]);
    const formatValue = useMemo(() => getCellValue(type, value), [type, value]);

    return (
        <div style={createTemplateWidth(colsWidth[id])} className={styles.cell}>
            {type === 'checkbox' ? (
                <div className={classes}>
                    <CheckBox checked={checked} label={''} onChange={() => toggleTarget(rowId)}/>
                </div>
            ) : (
                <Text className={classes} type='body2'>{formatValue}</Text>
            )}
        </div>
    );
};

export default memo(Cell);
