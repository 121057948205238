import React, {memo, useState} from 'react';
import {useStore} from 'effector-react';
import {$userData} from '@models/User';
import Headline from '@shared/ui/Headline';
import Text from '@shared/ui/Text';
import Input from '@shared/ui/Inputs/Input';
import Button from '@shared/ui/Button';
import PopupForm from '@shared/ui/Popups/PopupForm';
import {$loginForm, loginChange, onChangeLogin, onCloseLoginPopup} from '@models/User/changeLogin';
import {
    $passwordForm,
    onChangePassword,
    onClosePopup,
    passwordChange
} from '@models/User/changePassword';
import Logo from '@shared/ui/Logo';

import {useClientInfo} from './hooks/useClientInfo';
import styles from './SettingsProfile.module.scss';

const SettingsProfile = () => {
    const [isOpenPassword, setIsOpenPassword] = useState<boolean>(false);
    const [isOpenLogin, setIsOpenLogin] = useState<boolean>(false);
    const {name, logo, login} = useStore($userData);
    const {client, system, saveInClipboard} = useClientInfo();

    const password = useStore($passwordForm).password;
    const newLogin = useStore($loginForm).login;

    const onClose = () => {
        setIsOpenPassword(false);
        onClosePopup();
    };

    const onCloseLogin = () => {
        setIsOpenLogin(false);
        onCloseLoginPopup();
    };

    const onSuccessPassword = () => {
        const isAccess = confirm('Вы действительно хотите изменить пароль?');

        if (isAccess) {
            onChangePassword();
        }
    };

    const onSuccessLogin = () => {
        const isAccess = confirm('Вы действительно хотите изменить логин?');

        if (isAccess) {
            onChangeLogin();
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.block}>
                <Headline level={5}>{'Мой профиль'}</Headline>
                <div className={styles.line}/>

                <div className={styles.profile}>
                    <Logo src={logo} name={name} isCircle={false} scale={'lg'}/>

                    <div className={styles.bio}>
                        <Text type={'body2'} className={styles.label}>{'Имя:'}</Text>
                        <Input name={'user-name'} value={name} onInput={() => {}} disabled/>
                    </div>
                </div>
            </div>

            <div className={styles.block}>
                <Headline level={5} className={''}>{'Безопасность аккаунта'}</Headline>
                <div className={styles.line}/>

                <div className={styles.dataWrapper}>
                    <div className={styles.dataItem}>
                        <div className={styles.dataText}>
                            <Text type={'body1'}>{'Логин'}</Text>
                            <Text type={'body2'} className={styles.text}>
                                {login}
                            </Text>
                        </div>
                        <Button className={styles.button} text={'Изменить'} onClick={() => setIsOpenLogin(true)}/>
                    </div>

                    <div className={styles.dataItem}>
                        <div className={styles.dataText}>
                            <Text type={'body1'}>{'Пароль'}</Text>
                            <Text type={'body2'} className={styles.text}>
                                {'*************'}
                            </Text>
                        </div>
                        <Button className={styles.button} text={'Изменить'} onClick={() => setIsOpenPassword(true)}/>
                    </div>
                </div>
            </div>

            <div className={styles.block}>
                <Headline level={5} className={''}>{'Техническая информация о системе'}</Headline>
                <div className={styles.line}/>

                <div className={styles.profile}>
                    {/*<div className={styles.column}>*/}
                    {/*    <Text type={'body1'}>{'О системе:'}</Text>*/}
                    {/*    {system.map(({label, value}) => (*/}
                    {/*        <Text*/}
                    {/*            key={label}*/}
                    {/*            type={'body2'}*/}
                    {/*            className={styles.text}*/}
                    {/*        >*/}
                    {/*            {`${label}: ${value}`}*/}
                    {/*        </Text>*/}
                    {/*    ))}*/}
                    {/*</div>*/}
                    <div className={styles.column}>
                        <Text type={'body1'}>{'О клиенте:'}</Text>
                        {client.map(({label, value}) => (
                            <Text
                                key={label}
                                type={'body2'}
                                className={styles.text}
                            >
                                {`${label}: ${value}`}
                            </Text>
                        ))}
                    </div>
                </div>
                <div className={styles.buttonWrapper}>
                    <Button className={styles.button} onClick={saveInClipboard} text={'Копировать'}/>
                </div>
            </div>

            {isOpenPassword && (
                <PopupForm
                    placeholder={'Новый пароль'}
                    title={'Смена пароля'}
                    value={password}
                    maxLength={30}
                    name={'password'}
                    onClose={onClose}
                    onChange={passwordChange}
                    onSuccess={onSuccessPassword}
                />
            )}

            {isOpenLogin && (
                <PopupForm
                    placeholder={'Новый логин'}
                    title={'Смена логина'}
                    value={newLogin}
                    maxLength={30}
                    name={'login'}
                    onClose={onCloseLogin}
                    onChange={loginChange}
                    onSuccess={onSuccessLogin}
                />
            )}
        </div>
    );
};

export default memo(SettingsProfile);
