import React, {FC, memo} from 'react';
import {ReactComponent as CloseIcon} from '@assets/icons/shared/Close.svg';
import {useCheckIfClickedOutside} from '@shared/hooks/useCheckIfClickedOutside';
import CheckBox from '@shared/ui/CheckBox';
import Button from '@shared/ui/Button';
import Text from '@shared/ui/Text';

import styles from '../PopupWindow.module.scss';

import {IPopupComment} from './interfaces';

const PopupComment: FC<IPopupComment> = ({
  maxLength, name, value, placeholder = '', title = '',
  onChange, onClose, onSuccess, onChecked, checked
}) => {
    const {popupRef, onMouseDown, contentRef} = useCheckIfClickedOutside({callback: onClose});

    return (
        <div
            ref={popupRef}
            className={styles.container}
            onMouseDown={onMouseDown}
        >
            <div ref={contentRef} className={styles.content}>
                <div className={styles.header}>
                    <Text className={styles.title} type='subtitle2'>{title}</Text>
                    <div className={`${styles.close} ${styles.icon}`} onClick={onClose}>
                        <CloseIcon className={styles.closeIcon}/>
                    </div>
                </div>
                <div className={styles.body}>
                    <textarea
                        className={styles.textarea}
                        name={name}
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                        maxLength={maxLength}
                    />
                    <div className={styles.buttonsWrapper}>
                        <CheckBox
                            label={'Не показывать больше'}
                            onChange={onChecked}
                            checked={checked}
                            disabled={false}
                        />
                        <Button onClick={onSuccess} size={'md'} className={styles.button} text='Готово'/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(PopupComment);
