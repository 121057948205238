import React, {memo, FC} from 'react';
import Headline from '@shared/ui/Headline';

import styles from './LogoPlug.module.scss';
import {ILogoPlug} from './interfaces';
import {useLogoPlug} from './useLogoPlug';
import {textLvl, logoPlugStyles} from './utils';

const LogoPlug: FC<ILogoPlug> = ({name, scale = 'sm', isCircle = true}) => {
    const {text} = useLogoPlug(name);
    return (
        <div
            style={{background: `linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), ${logoPlugStyles[text[0]]}`}}
            className={`${styles.container} ${styles[scale]} ${!isCircle ? styles.box : ''}`}
        >
            <Headline level={textLvl[scale]}>{text}</Headline>
        </div>
    );
};

export default memo(LogoPlug);
