import {useCallback, useEffect, useState} from 'react';
import {useStore} from 'effector-react';
import {IGenericPopupData} from '@shared/ui/Popups/PopupActions/interfaces';
import {IGenericContextMenuData} from '@shared/components/Table/interfaces';
import {
    $currentRowId,
    $tableData, $tableTargets, onDeleteTitleInPackage,
    saveNewTitleName,
    setCurrentRowId,
    setDefaultName
} from '@models/Table';
import {onDownLoadVideo} from '@models/Media';
import {ITableColState} from '@models/Table/interfaces';

import {ModalsWindow} from '../interfaces';

export const usePresentationActions = () => {
    const [currentModal, setCurrentModal] = useState<ModalsWindow>('none');

    const tableData = useStore($tableData);
    const currentRowId = useStore($currentRowId);
    const targets = useStore($tableTargets);

    const onOpenModal = (modal: ModalsWindow) => setCurrentModal(modal);
    const onCloseModal = () => setCurrentModal('none');
    const onSuccess = () => {
        switch (currentModal) {
            case 'rename':
                saveNewTitleName();
                break;
            default:
                break;
        }

        onCloseModal();
    };
    const startDownloadVideo = useCallback((popupData: string | null | IGenericPopupData | number) => {
        if (targets.length) {
            const titlesIds: number[] = [...new Set(targets.map(id => Number(id)))];
            return titlesIds.forEach((id, index) => setTimeout(() => onDownLoadVideo(id), 1000 * index));
        }

        onDownLoadVideo(Number(popupData));
    }, [targets]);

    useEffect(() => {
        let targetRow: ITableColState = {};

        if (tableData.body.length) {
            tableData.body.forEach(row => {
                if (row.id === Number(currentRowId)) {
                    targetRow = row;
                }
            })
        }

        setDefaultName(String(targetRow.name));
    }, [tableData, currentRowId]);

    const contextMenuActions: IGenericContextMenuData = {
        download: (popupData) => startDownloadVideo(popupData),
        rename: (popupData) => {
            setCurrentRowId(popupData as string);
            onOpenModal('rename');
        },
        deleteTitle: (popupData) => {
            const isAccess = confirm(`Вы действительно хотите удалить материал${targets.length > 1 ? 'ы' : ''}?`);

            if (!isAccess) {
                return undefined;
            }

            let array: number[];

            if (targets.length) {
                array = targets.map(id => Number(id));
            } else {
                array = [Number(popupData)];
            }

            onDeleteTitleInPackage({titleIds: array});
        }
    };

    return {
        contextMenuActions, currentModal,
        onOpenModal, onCloseModal, onSuccess
    };
};
