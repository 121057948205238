import React, {memo} from 'react';
import {useStore} from 'effector-react';
import {$sectionId, setSectionId} from '@models/Sidebar';
import Text from '@shared/ui/Text';

import {headerNav} from './utils';
import styles from './Header.module.scss';

const Header = () => {
    const sectionId = useStore($sectionId);
    return (
        <div className={styles.container}>
            {headerNav.map(({id, icon, title}) => (
                <div
                    key={`sidebar-nav-${id}`}
                    className={`${styles.iconContainer} ${sectionId === id ? styles.activeIconContainer : ''}`}
                    onClick={() => setSectionId(id)}
                >
                    {icon({
                        className: `${styles.icon} ${sectionId === id ? styles.activeIcon : ''}`,
                    })}
                    <Text type={'caption'} className={`${styles.title} ${sectionId === id ? styles.activeTitle : ''}`}>{title}</Text>
                </div>
            ))}
        </div>
    );
};

export default memo(Header);
