import React, {FC, memo} from 'react';
import {useStore} from 'effector-react';
import {ReactComponent as TileIcon} from '@assets/icons/Table/Tile.svg';
import {ReactComponent as UploadIcon} from '@assets/icons/Table/Upload.svg';
import {ReactComponent as ArrowLeftIcon} from '@assets/icons/shared/DatePickerArrowLeft.svg';
import {ReactComponent as ArrowRightIcon} from '@assets/icons/shared/DatePickerArrowRight.svg';
import {ReactComponent as SidebarOpenIcon} from '@assets/icons/Sidebar/SidebarOpen.svg';
import {ReactComponent as SidebarCloseIcon} from '@assets/icons/Sidebar/SidebarClose.svg';
import {$isHideSidebar, setIsHideSidebar} from '@models/Sidebar';
import {$tableConfig, $tableQuery, toggleIsTileMode} from '@models/Table';
import Text from '@shared/ui/Text';
import PopupSettings from '@shared/ui/Popups/PopupSettings';
import {onDownloadPackage} from '@models/Media';

import styles from './GridToolbar.module.scss';
import {IGridToolbar} from './interfaces';
import {$currentPackage} from '@models/Sidebar/Packages';

const GridToolbar: FC<IGridToolbar> = ({isTileMode, isOpen, list, onClose, onOpen, onChange}) => {
    const isHideSidebar = useStore($isHideSidebar);
    const categoryName = useStore($tableConfig).name;
    const currentPackage = useStore($currentPackage);

    return (
        <div className={styles.container}>
            <div className={styles.titleWrapper}>
                <div className={styles.sidebarIcon} onClick={setIsHideSidebar}>
                    {isHideSidebar ? <SidebarOpenIcon/> : <SidebarCloseIcon/>}
                </div>
                <Text type={'title'} className={styles.headline}>{categoryName}</Text>
            </div>

            <div className={styles.block}>
                {currentPackage !== -1 && (
                    <div
                        className={styles.button}
                        onClick={onDownloadPackage}
                    >
                        <UploadIcon/>
                    </div>
                )}
                {/*<div className={`${styles.button} ${isTileMode ? styles.activeButton : ''}`} onClick={toggleIsTileMode}>*/}
                {/*    <TileIcon/>*/}
                {/*</div>*/}
                {!!list.length && (
                    <div className={styles.popupWrapper}>
                        <div className={`${styles.button} ${styles.lastButton}`} onClick={isOpen ? onClose : onOpen}>
                            {isOpen ? <ArrowRightIcon/> : <ArrowLeftIcon/>}
                        </div>

                        {isOpen && (
                            <div className={styles.popup}>
                                <PopupSettings
                                    list={list}
                                    popupName={'Настройка столбцов'}
                                    onChange={onChange}
                                    onClose={onClose}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default memo(GridToolbar);
