import React, {FC, memo} from 'react';
import {createRange} from '@shared/utils/generators';

import styles from './TileLoader.module.scss';
import {ITileLoader} from './interfaces';

const TileLoader: FC<ITileLoader> = ({count = 4}) => {
    return (
        <div className={styles.container}>
            {createRange(count).map(tile =>
                <div key={`tile-${tile}`} className={styles.tile}/>
            )}
        </div>
    );
};

export default memo(TileLoader);
