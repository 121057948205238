import React, {memo, FC, useState} from 'react';
import LogoPlug from '@shared/ui/LogoPlug';

import styles from './Logo.module.scss';
import {ILogo} from './interfaces';

const Logo: FC<ILogo> = ({src, name, isCircle = true, scale = 'sm'}) => {
    const [isUseFakeLogo, setIsUseFakeLogo] = useState<boolean>(true);
    const onError = () => setIsUseFakeLogo(true);
    const onLoad = () => setIsUseFakeLogo(false);

    return (
        <React.Fragment>
            {isUseFakeLogo && <LogoPlug name={name} scale={scale} isCircle={isCircle}/>}
            <div className={`${styles.container} ${styles[scale]} ${!isCircle ? styles.box : ''} ${isUseFakeLogo ? styles.hide : ''}`}>
                <img src={src} alt={'Логотиип'} onError={onError} onLoadCapture={onLoad} draggable={false}/>
            </div>
        </React.Fragment>
    );
};

export default memo(Logo);
