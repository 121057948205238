import React, {FC, memo, useEffect, useState} from 'react';

import {getIndexes} from './utils';
import PopupRow from './components/PopupRow';
import {IGenericIndexes, IPopupActions} from './interfaces';
import styles from './PopupActions.module.scss';

const PopupActions: FC<IPopupActions> = ({list, onClick, refObj, isLeftMode}) => {
    const [startCategoryTabs, setStartCategoryTabs] = useState<IGenericIndexes>({});

    useEffect(() => {
        setStartCategoryTabs(getIndexes(list));
    }, [list]);

    return (
        <div ref={refObj} className={styles.list}>
            {list.map((row, index) => (
                <PopupRow
                    key={`popup-${row.title}`}
                    row={row}
                    index={index}
                    isLeftMode={isLeftMode}
                    startCategoryTabs={startCategoryTabs}
                    onClick={onClick}
                />
            ))}
        </div>
    );
}

export default memo(PopupActions);
