import {MouseEvent, RefObject} from 'react';

export enum popupItemTypes {
    ACTION = 'action',
    SELECT = 'selector',
    LINK = 'link',
    VOID = 'void'
}

export interface IPopupChild {
    type: popupItemTypes;
    title: string;
    name: string;
    endCategory: boolean;
    disabled: boolean;
}

export interface IPopupParent extends IPopupChild {
    list: IPopupChild[];
}

export interface IPopupActions {
    list: IPopupParent[];
    refObj: RefObject<HTMLDivElement>;
    isLeftMode: boolean;
    onClick: (target: IPopupParent | IPopupChild) => void;
}

export interface IGenericIndexes {
    [key: string]: boolean;
}

export interface IContextMenuCoords {
    left: string;
    top: string;
}

export interface IGenericPopupData {
    [key: string]: string | number;
}

export interface IUsePopup {
    popupID: string;
    contextMenuCoords: IContextMenuCoords | null;
    containerRef: RefObject<HTMLDivElement>;
    popupRef: RefObject<HTMLDivElement>;
    data: any;
    isLeftMode: boolean;
    onStopPropagation: (e: MouseEvent<HTMLDivElement>) => void;
    onContextMenu: (e: MouseEvent<any>) => void;
    onSelect: (data: any) => void;
    onClose: () => void;
}
