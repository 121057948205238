import {IPopupParent, popupItemTypes} from '@shared/ui/Popups/PopupActions/interfaces';

export const contextMenuFile: IPopupParent[] = [
    {
        type: popupItemTypes.ACTION,
        title: 'Очистить',
        name: 'clear',
        endCategory: false,
        disabled: false,
        list: []
    }
];
