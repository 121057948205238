import React from 'react'
import '@models/init';
import './shared/styles/Fonts.scss';
import './shared/styles/Main.scss';
import ReactDOM from 'react-dom/client'
import App from './App'
import {BrowserRouter} from "react-router-dom";
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: import.meta.env.VITE_APP_SENTRY_TOKEN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <BrowserRouter>
        <App/>
    </BrowserRouter>
)
