export enum navIds {
    Packages = 'packages',
    ContrAgents = 'contrAgents'
}

export type TCategory = 'section' | 'category';

export interface IDocumentSettings {
    parentId: number;
    type: TCategory;
}
