import {IPopupParent, popupItemTypes} from '@shared/ui/Popups/PopupActions/interfaces';
export const contextMenuContrAgents: IPopupParent[] = [
    {
        type: popupItemTypes.ACTION,
        title: 'Изменить',
        name: 'edit',
        endCategory: false,
        disabled: false,
        list: []
    },
    {
        type: popupItemTypes.ACTION,
        title: 'Доступы',
        name: 'access',
        endCategory: false,
        disabled: false,
        list: []
    }
];
