import {sample} from 'effector';
import {contrAgentFormatter} from '@models/Form/utils';
import {$currentContrAgent} from '@models/Sidebar/ContrАgents';

import {
    onChangeForm,
    $form,
    onCreateContrAgent,
    createContrAgentFx,
    onResetForm,
    onEditUser, editUserFx
} from './';

sample({
    clock: onCreateContrAgent,
    source: $form,
    fn: contrAgentFormatter,
    target: createContrAgentFx
});

sample({
    clock: onEditUser,
    source: {form: $form, user: $currentContrAgent},
    fn: ({form, user}) => ({
        formData: contrAgentFormatter(form),
        userId: user?.id ?? 0
    }),
    filter: ({user}) => !!user,
    target: editUserFx
});
