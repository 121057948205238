import {sample} from 'effector';
import {ESettingsPages} from '@pages/Dashboard/components/Settings/interfaces';
import {setIsAdmin} from '@models/User';

import {
    $currentSettingsTab, $settingsConfigString,
    getSettingsConfigFx,
    onChangeSettingsTab,
    saveConfig,
    setDefaultConfig,
    setSettingsConfigFx, SettingsGate
} from './index';
import {saveConfigFn} from './utils';

sample({
    clock: [onChangeSettingsTab, SettingsGate.open],
    source: $currentSettingsTab,
    fn: () => 3,
    filter: (data) => data !== ESettingsPages.SETT,
    target: getSettingsConfigFx
});

sample({
    clock: getSettingsConfigFx.doneData,
    fn: (data) => data.config ? JSON.stringify(data.config, null, '\t') : '',
    target: setDefaultConfig
});

sample({
    clock: saveConfig,
    source: {tab: $currentSettingsTab, config: $settingsConfigString},
    fn: saveConfigFn,
    target: setSettingsConfigFx
});
