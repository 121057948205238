import {createEffect, createEvent, createStore} from 'effector';
import {ChangeEvent} from 'react';
import {changePassword} from '@shared/api/endpoints/user';

import {IChangePasswordPayload} from './interfaces';

export const setNewPassword = createEvent<any>();
export const onChangePassword = createEvent<any>();
export const onClosePopup = createEvent<any>();

export const changePasswordFx = createEffect<IChangePasswordPayload, void>(changePassword);

export const $passwordForm = createStore({password: ''})
    .on(setNewPassword, (s, {key, value}) => ({
        ...s,
        [key]: value})
    );

export const passwordChange = setNewPassword.prepend((e: ChangeEvent<HTMLInputElement>) => ({
    key: e.target.name,
    value: e.target.value,
}));
