import {ITableData, ITableConfig, ITableQuery} from '@models/Table/interfaces';
import {ISettingsConfig} from '@models/Settings/interfaces';

export const voidGridState: ITableData = {
    body: [],
    currentPage: 0
};

export const voidGridConfig: ITableConfig = {
    head: [],
    sorting: {
        sortingField: 'id',
        sortingType: 0,
    },
    totalAmount: 0,
    name: 'Результат'
};

export const voidSettingsConfig: ISettingsConfig = {
    typeId: null,
    config: []
};

export const voidTableQuery: ITableQuery = {
    head: [],
    body: [],
    currentPage: 0,
    totalAmount: 0,
    sorting: {
        sortingField: 'id',
        sortingType: 0,
    }
};
