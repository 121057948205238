import {useEffect, useState, useCallback} from 'react';
import {getCompressedDate, getDurationFromDate} from '@shared/utils/dateFormat';

import {IUseDatePicker} from '../interfaces';

export const useDatePicker = ({toggleVisibility, defaultValue, onChangeDate}: IUseDatePicker) => {
    const [isError, setIsError] = useState<boolean>(false);
    const [currentDate, setCurrentDate] = useState<Date | undefined>();
    const [dateField, setDateField] = useState<string>('');
    const [durationField, setDurationField] = useState<string>('');
    const [month, setMonth] = useState<Date>(new Date());

    const onDurationInput = useCallback((value: string, date: string | undefined = undefined) => {
        setIsError(false);
        setDurationField(value);

        const updateDate = (newDate: Date) => {
            const copyDate = new Date(newDate);
            const durationSplit = value.split(':');

            copyDate.setSeconds(Number(durationSplit[2]));
            copyDate.setMinutes(Number(durationSplit[1]));
            copyDate.setHours(Number(durationSplit[0]));

            if (!copyDate.getTime()) {
                setIsError(true);
                return undefined;
            }

            setCurrentDate(copyDate);
        }

        if (date) {
            const array = date.split('.');
            const formatDate = new Date(Number(array[2]), Number(array[1]) - 1, Number(array[0]));
            return updateDate(formatDate);
        }

        if (currentDate && value !== '00:00:00') {
            return updateDate(currentDate);
        }
    }, [currentDate]);

    const onDateInput = useCallback((value: string) => {
        setIsError(false);
        setDateField(value);

        if (!value || value === '__.__.____') {
            setIsError(true);
            return undefined;
        }

        const array = value.split('.');
        const validDate = new Date(Number(array[2]), Number(array[1]) - 1, Number(array[0]));
        let newDate = validDate;

        if (currentDate) {
            newDate = new Date(currentDate);
            newDate.setDate(validDate.getDate());
            newDate.setMonth(validDate.getMonth());
            newDate.setFullYear(validDate.getFullYear());
        }

        if (!newDate.getTime()) {
            setIsError(true);
            return undefined;
        }

        setCurrentDate(newDate);
        setMonth(newDate);
    }, [currentDate, durationField]);

    const onSelect = (day: Date | undefined, selectedDay: Date) => {
        onDateInput(getCompressedDate(selectedDay));

        if (day === undefined) {
            onDurationInput(getDurationFromDate(new Date(selectedDay)), getCompressedDate(selectedDay));
        }
        toggleVisibility(false);
    };

    const onMonthChange = (date: Date) => setMonth(date);
    const onClear = useCallback(() => {
        if (!dateField && !durationField) {
            return undefined;
        }

        setCurrentDate(undefined);
        setDateField('');
        setDurationField('');
        setIsError(false);
        toggleVisibility(false);
    }, [dateField, durationField]);

    useEffect(() => {
        if (defaultValue) {
            onSelect(undefined, new Date(defaultValue));
        } else {
            onClear();
        }
    }, [defaultValue]);

    useEffect(() => {
        if (!currentDate || (currentDate && currentDate.getTime() === defaultValue)) {
            return undefined;
        }

        if (currentDate) {
            onChangeDate(currentDate.getTime());
        } else {
            onChangeDate(null);
        }
    }, [currentDate]);

    return {
        currentDate, month, isError,
        dateField, durationField,
        onSelect, onMonthChange, onDurationInput, onDateInput, onClear
    };
};
