import {createEffect, createEvent, createStore} from 'effector';
import {ChangeEvent} from 'react';
import {changeLogin} from '@shared/api/endpoints/user';

import {IChangeLoginPayload} from './interfaces';

export const setNewLogin = createEvent<any>();
export const onChangeLogin = createEvent<any>();
export const onCloseLoginPopup = createEvent<any>();

export const changeLoginFx = createEffect<IChangeLoginPayload, void>(changeLogin);

export const $loginForm = createStore({login: ''})
    .on(setNewLogin, (s, {key, value}) => ({
        ...s,
        [key]: value})
    );

export const loginChange = setNewLogin.prepend((e: ChangeEvent<HTMLInputElement>) => ({
    key: e.target.name,
    value: e.target.value,
}));
