import {useCallback, useMemo, useState} from 'react';
import {useStore} from 'effector-react';
import {usePopup} from '@shared/ui/Popups/PopupActions/hooks/usePopup';
import {IPopupChild, IPopupParent} from '@shared/ui/Popups/PopupActions/interfaces';
import {singleSort} from '@shared/utils/sort';
import {
    $editPackageId,
    onDeletePackage,
    onEditPackage,
    onResetPackageForm,
    onSendPackage,
    setDefaultPackageName, setEditPackageId
} from '@models/Sidebar/Packages';
import {TCategory} from '@models/Sidebar/interfaces';

import {IUsePackageTree, TActionTypes} from './interfaces';

export const usePackageTree = ({packages, newPackage}: IUsePackageTree) => {
    const [modalType, setModalType] = useState<TActionTypes>('');
    const packageId = useStore($editPackageId);

    const {
        containerRef, contextMenuCoords, data, popupRef, isLeftMode, popupID,
        onStopPropagation, onContextMenu, onSelect, onClose
    } = usePopup();

    const onPopupAction = useCallback((target: IPopupParent | IPopupChild) => {
        switch (target.name) {
            case 'delete':
                const isAccess = confirm('Вы действительно хотите удалить пакет?');
                if (isAccess) {
                    onDeletePackage(data.id);
                }
                break;
            case 'edit':
                setDefaultPackageName(data.name);
                setEditPackageId(data.id);
                setModalType(target.name);
                break;
            default: break;
        }
        onClose();
    }, [data]);

    const onOpenModal = (modal: TCategory, data: any) => {
        setModalType('create');
    };

    const onCloseModal = () => {
        onResetPackageForm();
        setEditPackageId(-1);
        setModalType('');
    };

    const onSuccessModal = useCallback(() => {
        if (newPackage.length) {
            if (modalType === 'create') {
                onSendPackage();
            }

            if (modalType === 'edit') {
                onEditPackage();
            }

            onCloseModal();
        }
    }, [newPackage, modalType, packageId]);

    const sortedData = useMemo(() => singleSort(packages, 'name'), [packages]);

    return {
        sortedData, modalType,
        containerRef, contextMenuCoords, popupRef, isLeftMode, popupID,
        onStopPropagation, onContextMenu, onSelect, onPopupAction,
        onOpenModal, onCloseModal, onSuccessModal
    };
};
