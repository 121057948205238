import React, {useEffect} from 'react';
import {setAlert} from "@models/Alert";

const useAlert = () => {
    useEffect(() => {
        setTimeout(() => {
            setAlert(null);
        }, 5000)
    }, [])
};

export default useAlert;
