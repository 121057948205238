import {useEffect} from 'react';

export const useNoSelect = (callback: () => void, isMouseDown: boolean | number | null | string) => {
    useEffect(() => {
        const noSelect = (e: MouseEvent | Event) => e.preventDefault();

        if (isMouseDown) {
            document.body.addEventListener('mouseup', callback);
            document.body.addEventListener('dragstart', noSelect);
            document.body.addEventListener('selectstart', noSelect);
        }

        return function clear () {
            document.body.removeEventListener('mouseup', callback);
            document.body.removeEventListener('dragstart', noSelect);
            document.body.removeEventListener('selectstart', noSelect);
        };
    }, [isMouseDown]);
};
