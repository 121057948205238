import {createEffect, createEvent, createStore} from 'effector';
import {createGate} from 'effector-react';
import {changeUserAccess, getAvailablePackages} from '@shared/api/endpoints/sidebar';
import {
    IChangeAccessPayload, IGetAvailablePackages,
    ISelectedPackage,
    ISelectedPackageEvent, IUserPackages
} from '@models/Sidebar/ContrАgents/Accesses/interfaces';

export const changeUserAccessFx = createEffect<IChangeAccessPayload, any, Error>(changeUserAccess);
export const getAvailablePackagesFx = createEffect<IGetAvailablePackages, IUserPackages[], Error>(getAvailablePackages);

export const onAllPackagesQuery = createEvent<string>();
export const onSelectPackage = createEvent<ISelectedPackageEvent>();
export const onResetAccesses = createEvent<void>();

export const AccessesGate = createGate();

export const $allPackages = createStore<IUserPackages[]>([])
    .on(getAvailablePackagesFx.doneData, (_, packages) => packages);

export const $allPackagesQuery = createStore<string>('')
    .on(onAllPackagesQuery, (_, value) => value);
export const $selectedPackages = createStore<ISelectedPackage>({})
    .on(onSelectPackage, (prevState, {id, value}) => ({...prevState, [id]: value}));
