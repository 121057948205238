import {FC, memo} from 'react';
import {ReactComponent as DropdownIcon} from '@assets/icons/Sidebar/InlineDropDownBottom.svg';
import {ReactComponent as SearchIcon} from '@assets/icons/Sidebar/Search.svg';
import {ReactComponent as SelectIcon} from '@assets/icons/shared/Select.svg';
import Input from '@shared/ui/Inputs/Input';

import useSelector from './useSelector';
import styles from './Selector.module.scss';
import {SelectorProps} from './interfaces';

const Selector: FC<SelectorProps> = ({
  className = '',
  onSelect = () => {},
  options = [],
  value = {id: 0, title: 'Option'},
  scale = 'lg',
  filter = false,
  isScrollMode = false,
  disabled = false
}) => {
    const {
        filterRef, listRef, inputRef, filteredData,
        isOpened, isUpMode, search, onChange
    } = useSelector({data: options, filter, disabled});

    return (
        <div className={`${styles.container} ${className} ${styles[scale]}`} ref={filterRef}>
            <div className={`${styles.input} ${disabled ? styles.disabled : ''}`}>
                <p>{value.title || 'Нет значения'}</p>
                {(options.length > 1 && !disabled) && <DropdownIcon style={{transform: `rotate(${isOpened ? '180' : '0'}deg)`}}/>}
            </div>
            <div
                ref={listRef}
                className={`${styles.list} ${isUpMode ? styles.upList : ''} ${isScrollMode ? styles.scrollMode : ''}`}
                style={{display: isOpened ? 'block' : 'none', minWidth: filterRef.current?.clientWidth}}
            >
                {filter && (
                    <div ref={inputRef}>
                        <Input
                            placeholder={'Поиск'}
                            name={'filter'}
                            icon={<SearchIcon/>}
                            className={styles.filter}
                            value={search}
                            onChange={onChange}
                        />
                    </div>
                )}
                {filteredData.map(item => (
                    item.title && (
                        <div
                            key={`selector-item-${item.title}-${item.id}`}
                            id={item.title}
                            className={`${styles.item} ${styles[`${scale}Item`]}`}
                            onClick={() => onSelect(item)}
                        >
                            <p>{item.title}</p>
                            {item.id === value.id && <SelectIcon/>}
                        </div>
                    )
                ))}
            </div>
        </div>
    );
};

export default memo(Selector);
