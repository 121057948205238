import React, {FC, memo, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {ReactComponent as ArrowRightIcon} from '@assets/icons/shared/DatePickerArrowRight.svg';
import {ReactComponent as ArrowLeftIcon} from '@assets/icons/shared/DatePickerArrowLeft.svg';
import Text from '@shared/ui/Text';

import {getIndexes} from '../../utils';
import styles from '../../PopupActions.module.scss';
import {IGenericIndexes, IPopupChild, popupItemTypes} from '../../interfaces';

import SubPopupRow from './components/SubPopupRow';
import {IPopupRow} from './interfaces';

const PopupRow: FC<IPopupRow> = ({row, onClick, index, startCategoryTabs, isLeftMode}) => {
    const [isHover, setIsHover] = useState<boolean>(false);
    const [selected, setSelected] = useState<IPopupChild | null>(null);
    const [subStartCategoryTabs, setSubStartCategoryTabs] = useState<IGenericIndexes>({});
    const [offsetTop, setOffsetTop] = useState({top: '0'});

    const subRowRef = useRef<HTMLDivElement>(null);

    const isSubList = useMemo(() => (!!row.list.length && !row.disabled), [row]);

    const onMouseOver = useCallback(() => {
        if (isSubList) {
            setIsHover(true);
        }
    }, [isSubList]);

    const onMouseLeave = useCallback(() => {
        if (isSubList) {
            setOffsetTop({top: '0'});
            setIsHover(false);
        }
    }, [isSubList]);

    const onTarget = (subRow: IPopupChild) => {
        if (subRow.type === popupItemTypes.SELECT) {
            setSelected(subRow);
            localStorage.setItem(`popup-action-${row.name}`, JSON.stringify(subRow));
        }

        onClick(subRow);
    };

    const rowClasses = useMemo((): string => {
        let classes = `${styles.listRow}`;

        if (isSubList) {
            classes += ` ${styles.listTab}`;
        }

        if (row.endCategory) {
            classes += ` ${styles.paddingBottom}`;
        }

        if (startCategoryTabs[`id_${index}`]) {
            classes += ` ${styles.paddingTop}`;
        }

        if (row.disabled) {
            classes += ` ${styles.disabled}`;
        }

        return classes;
    }, [row, isSubList, index, startCategoryTabs]);

    useEffect(() => {
        if (isSubList && row.list[0].type === popupItemTypes.SELECT) {
            setSelected(row.list[0]);
        }

        if (isSubList) {
            setSubStartCategoryTabs(getIndexes(row.list));
        }
    }, [row, isSubList]);

    useEffect(() => {
        if (subRowRef.current && isHover && isSubList) {
            const subListBottom = subRowRef.current.getBoundingClientRect().bottom;
            const documentHeight = document.body.getBoundingClientRect().height;

            if (subListBottom > documentHeight) {
                setOffsetTop({top: `-${subListBottom - documentHeight}px`})
            }
        }
    }, [subRowRef, isHover, isSubList]);

    useEffect(() => {
        const selected = localStorage.getItem(`popup-action-${row.name}`);

        if (selected) {
            setSelected(JSON.parse(selected));
            onClick(JSON.parse(selected));
        }
    }, []);

    return (
        <>
            <div
                className={rowClasses}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
                onClick={() => (row.disabled || row.name === '') ? {} : onClick(row)}
            >
                <Text type={'body2'}>{row.title}</Text>
                {isSubList && (
                    <>
                        <div className={`${styles.arrowIcon} ${isHover ? styles.rotate : ''}`}>
                            {!isLeftMode ? <ArrowLeftIcon/> : <ArrowRightIcon/>}
                        </div>
                        {isHover && (
                            <div
                                ref={subRowRef}
                                className={`${styles.subList} ${!isLeftMode ? styles.rightMode : ''}`}
                                style={offsetTop}
                            >
                                <div className={styles.plug}/>
                                <div className={styles.subContainer}>
                                    {row.list.map((subRow: IPopupChild, id) => (
                                        <SubPopupRow
                                            key={`sub-popup-row-${row.title}-${subRow.title}`}
                                            subRow={subRow}
                                            id={id}
                                            selected={selected}
                                            subStartCategoryTabs={subStartCategoryTabs}
                                            onTarget={onTarget}
                                        />
                                    ))}
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
            {row.endCategory && <div className={styles.listLine}/>}
        </>
    );
};

export default memo(PopupRow);
