import {useCallback} from 'react';
import {IPopupChild, IPopupParent, popupItemTypes} from '@shared/ui/Popups/PopupActions/interfaces';

import {IUsePopupData} from '../interfaces';

export const usePopupAction = ({popupData, onClose, contextMenuActions}: IUsePopupData) => {
    const onClick = useCallback((target: IPopupParent | IPopupChild) => {
        if (contextMenuActions && target.type !== popupItemTypes.VOID) {
            contextMenuActions[target.name](popupData);
            onClose();
        }
    }, [popupData, contextMenuActions, onClose]);

    return {onClick};
};
