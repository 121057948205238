import React, {FC, memo} from 'react';
import Text from '@shared/ui/Text';

import {contrAgentsTabsItem} from '../../interfaces';

import styles from './ContrAgentTabs.module.scss';
import {IContrAgentTabsTabs} from './interfaces';

const ContrAgentTabs: FC<IContrAgentTabsTabs> = ({isEditMode, tab, toggleTab}) => {
    return (
        <div className={styles.container}>
            <div
                onClick={() => toggleTab(contrAgentsTabsItem.MAIN)}
                className={`${styles.menuItem} ${tab === contrAgentsTabsItem.MAIN ? styles.currentItem : ''}`}
            >
                <Text type={'body2'}>{'Основная информация'}</Text>
            </div>
            {isEditMode && (
                <div
                    onClick={() => toggleTab(contrAgentsTabsItem.ACCESS)}
                    className={`${styles.menuItem} ${tab === contrAgentsTabsItem.ACCESS ? styles.currentItem : ''}`}
                >
                    <Text type={'body2'}>{'Доступы к пакетам'}</Text>
                </div>
            )}
        </div>
    );
};

export default memo(ContrAgentTabs);
