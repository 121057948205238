import React, {memo, FormEvent, useRef, useCallback} from 'react';
import {useStore} from 'effector-react';
import {ReactComponent as LogoIcon} from '@assets/icons/Header/Logo.svg';
import InputSearch from '@shared/ui/Inputs/InputSearch';
import {$activeSearchInput, $tableQuery, deleteGrid, onChangeTableSearch} from '@models/Table';
import {$isOpenSettings, toggleOpenSettings} from '@models/Settings';
import useUpdateEffect from '@shared/hooks/useUpdateEffect';

import Navigation from './components/Navigation';
import styles from './Header.module.scss';

const Header = () => {
    const search = useStore($tableQuery);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const activeSearchInput = useStore($activeSearchInput);
    const isOpenSettings = useStore($isOpenSettings);

    const onInput = (e: FormEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        const parentNode = target.parentNode as HTMLDivElement;
        parentNode.style.boxShadow = 'none';
        onChangeTableSearch(target.value);
        if (!target.value) {
            deleteGrid();
        }
    };

    const onSetGlow = useCallback(() => {
        const node = inputRef.current;
        if (node) {
            const parentNode = node.parentNode as HTMLDivElement;
            node.focus();
            parentNode.style.boxShadow = '0 4px 100px rgba(255, 95, 0, 0.4)';

            setTimeout(() => parentNode.style.boxShadow = 'none', 3000);
        }
    }, [inputRef.current]);

    useUpdateEffect(() => onSetGlow(), [activeSearchInput]);

    return (
        <header className={styles.container}>
            <div className={styles.main}>
                <div className={styles.left}>
                    <div
                        className={`${isOpenSettings ? styles.logo : ''}`}
                        onClick={isOpenSettings ? toggleOpenSettings : undefined}
                    >
                        <LogoIcon/>
                    </div>
                    <InputSearch
                        inputRef={inputRef}
                        name={'main-search-filter'}
                        placeholder={'Поиск по всем материалам'}
                        className={styles.search}
                        value={search}
                        onChange={onInput}
                    />
                </div>
                <div className={styles.right}>
                    <Navigation/>
                </div>
            </div>
        </header>
    );
};

export default memo(Header);
