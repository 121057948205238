import {useCallback, useEffect, useRef, useState, MouseEvent as ReactMouseEvent, useMemo} from 'react';
import {useStore} from 'effector-react';
import {deepCopy} from '@shared/utils/deepCopy';
import {useNoSelect} from '@shared/hooks/useNoSelect';
import {useElementRect} from '@shared/hooks/useElementRect';
import {ITableSorting} from '@models/Table/interfaces';
import {defaultSortState} from '@models/Table/utils';
import {$sideBarWidth} from '@models/Sidebar';

import {minColSize} from '../utils';
import {IColsWidth, IUseSlickTableProps} from '../interfaces';

export const useSlickTable = ({head, availableCols, tableName, sorting, isOpenSidebar, setSorting}: IUseSlickTableProps) => {
    const [colsWidth, setColsWidth] = useState<{[key: string]: number}>({});
    const [tableOffset, setTableOffset] = useState<number>(0);
    const [tableWidth, setTableWidth] = useState<IColsWidth | null>(null);
    const [targetWidget, setTargetWidget] = useState<number | null>(null);
    const [sortBy, setSortBy] = useState<ITableSorting>(defaultSortState);
    const sideBarWidth = useStore($sideBarWidth);

    const tableRef = useRef<HTMLTableElement>(null);
    const windowResize = useElementRect(tableRef, 'width', false);

    const indexes = useMemo(() => {
        const ids: {[key: string]: number} = {};
        let i = 0;

        head.forEach(({id}) => {
            if (!availableCols || (!id || availableCols[id])) {
                ids[id] = i;
                i++;
            }
        });

        return ids;
    }, [head, availableCols]);

    const setWidth = useCallback((width: number) => {
        if (targetWidget) {
            const cols = deepCopy(colsWidth);
            let currentColPosition = tableOffset;
            let currentWidget = '';

            for (let key in indexes) {
                currentColPosition += cols[key];

                if (!cols[key]) {
                    continue;
                }

                if (indexes[key] === targetWidget - 1) {
                    currentWidget = key;
                    break;
                }
            }

            cols[currentWidget] -= currentColPosition - width;

            if (cols[currentWidget] < minColSize) {
                cols[currentWidget] = minColSize;
            }

            localStorage.setItem(`${tableName}-colsWidth`, JSON.stringify(cols));
            setColsWidth(cols);
        }
    }, [colsWidth, targetWidget, tableOffset, indexes, tableName]);

    const onMouseDown = useCallback((id: number) => {
        setTargetWidget(id);
        if (tableRef && tableRef.current) {
            setTableOffset(tableRef.current.getBoundingClientRect().left - tableRef.current.scrollLeft);
        }
    }, [tableRef]);
    const onMouseUp = () => {
        setTargetWidget(null);
        setTableOffset(0);
    };
    const onMouseMove = useCallback((event: ReactMouseEvent<EventTarget>) => {
        if (targetWidget) {
            setWidth(event.clientX - 6); // 6px - Widget width
        }
    }, [targetWidget]);

    const onSort = useCallback((sortingField: string) => {
        let newSortPayload = {sortingField, sortingType: 1};

        if (sortBy.sortingField === sortingField && sortBy.sortingType === 1) {
            newSortPayload = {sortingField, sortingType: -1};
        }

        if (sortBy.sortingField === sortingField && sortBy.sortingType === -1) {
            newSortPayload = {sortingField: 'id', sortingType: 0};
        }

        setSorting(newSortPayload);
    }, [sortBy]);

    const updateColsWidth = useCallback(() => {
        if (availableCols) {
            const cols = deepCopy(colsWidth);
            const storage = JSON.parse(localStorage.getItem(`${tableName}-colsWidth`) || '{}');
            const collect = Object.keys(availableCols);

            collect.forEach((key) => {
                if (!availableCols[key]) {
                    cols[key] = 0;
                } else if (availableCols[key] && !cols[key]) {
                    cols[key] = (storage[key] || 200);
                }
            });

            setColsWidth(cols);
        }
    }, [colsWidth, availableCols, tableName]);

    useNoSelect(onMouseUp, targetWidget);

    useEffect(() => updateColsWidth(), [availableCols]);

    useEffect(() => {
        const storage = JSON.parse(localStorage.getItem(`${tableName}-colsWidth`) || '{}');
        let result: {[key: string]: number} = {};

        head.forEach((col) => {
            if (storage[col.id]) {
                result[col.id] = storage[col.id];
            } else {
                result[col.id] = col.title ? 200 : minColSize
            }
        });

        if (head.length) {
            localStorage.setItem(`${tableName}-colsWidth`, JSON.stringify(result));
        }
        setColsWidth(result);
    }, [head, tableName]);

    useEffect(() => {
        const currentWidth = tableRef.current?.getBoundingClientRect().width || 0;
        let width = 0;
        for (let key in colsWidth) {
            width += colsWidth[key];
        }
        setTableWidth({minWidth: `${Math.max(width, currentWidth)}px`});
    }, [colsWidth, tableRef.current, windowResize, sideBarWidth, isOpenSidebar]);

    useEffect(() => setSortBy(sorting || defaultSortState), [sorting]);

    return {
        tableRef, targetWidget, indexes,
        colsWidth, tableWidth, sortBy,
        onMouseDown, onMouseMove, onSort
    };
};
