import {useEffect, useRef, useState, MouseEvent as ReactMouseEvent, useCallback} from 'react';
import {uuid} from '@shared/utils/generators';
import {setPopupID} from '@models/PopupActions';
import {maxPopupWidth} from '@shared/ui/Popups/PopupActions/utils';

import {IUsePopup, IContextMenuCoords, IGenericPopupData} from '../interfaces';

export const usePopup = (): IUsePopup => {
    const [contextMenuCoords, setContextMenuCoords] = useState<IContextMenuCoords | null>(null);
    const [data, setData] = useState<any>(null);
    const [popupID] = useState<string>(uuid());
    const [isLeftMode, setIsLeftMode] = useState<boolean>(true);

    const containerRef = useRef<HTMLDivElement>(null);
    const popupRef = useRef<HTMLDivElement>(null);

    const onContextMenu = useCallback((e: ReactMouseEvent<HTMLDivElement>) => {
        if (popupRef.current) {
            setIsLeftMode(true);
            setPopupID(popupID);

            e.preventDefault();
            e.stopPropagation();

            const {clientX, clientY} = e;
            const popupWidth = popupRef.current.clientWidth;
            const popupHeight = popupRef.current.clientHeight;
            const {width, height} = document.body.getBoundingClientRect();
            let offsetLeft = clientX;
            let offsetTop = clientY;

            if (clientX + popupWidth > width) {
                offsetLeft = width - popupWidth;
            }

            if (clientX + maxPopupWidth > width) {
                setIsLeftMode(false);
            }

            if (clientY + popupHeight > height) {
                offsetTop = height - popupHeight;
            }

            setContextMenuCoords({
                left: `${offsetLeft}px`,
                top: `${offsetTop}px`
            });
        }
    }, [popupRef, popupID]);

    const onStopPropagation = (e: ReactMouseEvent<HTMLDivElement>) => e.stopPropagation();
    const onSelect = (data: any) => setData(data);
    const onClose = () => {
        setContextMenuCoords(null);
        setData(null);
        setIsLeftMode(true);
        setPopupID('');
    };

    useEffect(() => {
        const resetEvent: {(event: MouseEvent): void} = (event: MouseEvent) => {
            if (containerRef.current) {
                const target = event.target as HTMLDivElement;
                if (!containerRef.current.contains(target)) {
                    onClose();
                }
            }
        };

        if (contextMenuCoords) {
            document.body.addEventListener('click', resetEvent);
            document.body.addEventListener('contextmenu', resetEvent);
        }

        return function clear () {
            document.body.removeEventListener('click', resetEvent);
            document.body.removeEventListener('contextmenu', resetEvent);
        };
    }, [contextMenuCoords, containerRef]);

    return {
        contextMenuCoords, containerRef, data, popupRef, isLeftMode, popupID,
        onStopPropagation, onContextMenu, onSelect, onClose
    };
};
