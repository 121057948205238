import {MouseEvent, useCallback, useEffect, useState} from 'react';
import {$sideBarWidth, setSideBarWidth} from '@models/Sidebar';
import {useElementRect} from '@shared/hooks/useElementRect';
import {useNoSelect} from '@shared/hooks/useNoSelect';
import {useStore} from 'effector-react';

import {minSideBarWidth, maxTranslate} from '../utils';

export const useSlickSidebar = () => {
    const sideBarWidth = useStore($sideBarWidth);
    const [isMouseDown, setIsMouseDown] = useState<boolean>(false);

    const containerWidth = useElementRect({current: document.body}, 'width', false);
    const onMouseDown = () => setIsMouseDown(true);
    const onMouseUp = () => setIsMouseDown(false);

    const onMouseMove = useCallback((event: MouseEvent<EventTarget>) => {
        if (!isMouseDown) {
           return undefined;
        }

        // 8 - widgets width
        const x = event.clientX;
        if (x >= minSideBarWidth && x <= containerWidth * maxTranslate && x <= (containerWidth - 8)) {
            setSideBarWidth(Math.floor(x));
        }
    }, [isMouseDown, containerWidth]);

    useNoSelect(onMouseUp, isMouseDown);

    useEffect(() => {
        if (isMouseDown) {
            document.body.className = 'body';
        } else {
            document.body.className = '';
        }

        return () => {
            document.body.className = '';
        };
    }, [isMouseDown]);

    useEffect(() => {
        const maxWidth = containerWidth * maxTranslate;
        if (maxWidth && maxWidth < sideBarWidth && maxWidth >= minSideBarWidth) {
            setSideBarWidth(maxWidth);
        }
    }, [containerWidth, sideBarWidth]);

    return {
        sideBarWidth,
        onMouseDown, onMouseMove
    };
};
