import {useEffect} from 'react';
import {useStore} from 'effector-react';
import {SelectorItem} from '@shared/components/Selector/interfaces';
import {$form, onChangeForm} from '@models/Form';

import {IUseField} from '../interfaces';

export const useFieldSelect = ({id, value, formKey, onSave}: IUseField) => {
    const localValue = (useStore($form)?.[formKey]?.[id]  as SelectorItem);

    const onSelect = (option: SelectorItem) => {
        if (onSave) {
            onSave({value: option, fieldId: id});
        }

        onChangeForm({form: formKey, field: id, value: option});
    };

    useEffect(() => {
        const newValue = (value ? value : {id: -1, title: ''}) as SelectorItem;
        onChangeForm({form: formKey, field: id, value: localValue ?? newValue});
    }, [value, localValue]);

    return {localValue, onSelect};
};
