import React, {memo, FC, useRef} from 'react';
import {useStore} from 'effector-react';
import {$isHideSidebar, $sectionId} from '@models/Sidebar';
import {createTemplateWidth} from '@shared/utils/generators';
import {$isAdmin} from '@models/User';

import Header from './components/Header';
import {ISideBar} from './interfaces';
import {navList} from './utils';
import styles from './Sidebar.module.scss';

const Sidebar: FC<ISideBar> = ({sideBarWidth, onMouseDown}) => {
    const isHideSidebar = useStore($isHideSidebar);
    const sectionId = useStore($sectionId);
    const sectionRef = useRef<HTMLDivElement>(null);
    const isAdmin = useStore($isAdmin);

    return (
        <div className={`${styles.container} ${isHideSidebar ? styles.hide : ''}`}>
            <div className={styles.sidebar} style={createTemplateWidth(sideBarWidth)}>
                {isAdmin && <Header/>}
                <div
                    className={styles.section}
                    ref={sectionRef}
                >
                    {navList[sectionId]}
                </div>
            </div>

            <div className={styles.widget} onMouseDown={onMouseDown}/>
        </div>
    );
};

export default memo(Sidebar);
