import React, {memo} from 'react';
import {ReactComponent as PackageIcon} from '@assets/icons/Sidebar/Packages.svg';
import {navIds} from '@models/Sidebar/interfaces';

import List from '../List';
import PackageTree from '../PackageTree';
import {useList} from '../List/hooks/useList';

const Packages = () => {
    const {lists, toggleOpen} = useList();

    const toggleList = (key: string) => toggleOpen(navIds.Packages, key);

    return (
        <>
            <List
                name={'myPackages'}
                isOpen={lists[navIds.Packages] === 'myPackages'}
                toggleOpen={toggleList}
                title={'Мои пакеты'}
                icon={<PackageIcon/>}
            >
                <PackageTree/>
            </List>
        </>
    );
};

export default memo(Packages);
