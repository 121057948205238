import {useCallback, useEffect} from 'react';
import {deepCopy} from '@shared/utils/deepCopy';
import {$form, onChangeForm} from '@models/Form';
import {useStore} from 'effector-react';

import {IUseField} from '../interfaces';

export const useFieldMultipleDate = ({id, value, formKey, onSave}: IUseField) => {
    const localValue = (useStore($form)?.[formKey]?.[id]  as number[] | null[] | undefined);

    const onChange = useCallback((ms: number | null, widgetId: number) => {
        if (!localValue) {
            return undefined;
        }

        const state = deepCopy(localValue);
        state[widgetId] = ms;

        if (onSave) {
            onSave({value: state, fieldId: id});
        }
        onChangeForm({form: formKey, field: id, value: state});
    }, [localValue, id]);

    const onAdd = useCallback(() => {
        if (!localValue) {
            return undefined;
        }

        const state = deepCopy(localValue);
        onChangeForm({form: formKey, field: id, value: [...state, new Date().getTime()]});
    }, [localValue, id]);

    const onDelete = useCallback((widgetId: number) => {
        if (!localValue) {
            return undefined;
        }

        const state: number[] = deepCopy(localValue);
        const newState: number[] = state.filter((_, index) => index !== widgetId);

        if (onSave) {
            onSave({value: newState, fieldId: id});
        }
        onChangeForm({form: formKey, field: id, value: newState});
    }, [localValue, id]);

    useEffect(() => {
        const newValue = !value ? [null] : (value as any).map((date: number) => Number(date));
        onChangeForm({form: formKey, field: id, value: localValue ?? newValue});
    }, [value]);

    return {localValue, onChange, onAdd, onDelete};
};
