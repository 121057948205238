import {ChangeEvent, DragEvent, useEffect, useState} from 'react';
import {toBase64} from '@shared/utils/generators';
import {onChangeForm} from '@models/Form';

export const useUploadLogo = (value: string | null) => {
    const [localUrl, setLocalUrl] = useState<string>('');
    const [isActive, setIsActive] = useState<boolean>(false);

    const onSave = (file: File) => {
        const getLocalUrl = async () => {
            const base64 = await toBase64(file);
            setLocalUrl(base64);
        };
        getLocalUrl();
        onChangeForm({form: 'contr-agent-form', field: 'file', value: file});
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        if (target.files && target.files[0]) {
            onSave(target.files[0]);
        }
        setIsActive(false);
    };

    const onOver = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsActive(true);
    };

    const onLeave = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsActive(false);
    };

    const onClick = () => setIsActive(true);

    const onDrop = (e: DragEvent) => {
        e.preventDefault();
        const file = e.dataTransfer?.files[0];
        setIsActive(false);

        if (file) {
            onSave(file);
        }
    };

    const onCLear = () => {
        setLocalUrl('');
        setIsActive(false);
        onChangeForm({form: 'contr-agent-form', field: 'file', value: 'null'});
    };

    useEffect(() => {
        if (value) {
            setLocalUrl(value);
        }
    }, []);

    return {
        isActive, localUrl,
        onDrop, onLeave, onOver, onChange, onClick, onCLear
    };
};
