import {sample} from 'effector';
import {$currentPackage} from '@models/Sidebar/Packages';

import {
    downloadPackageFx,
    downloadVideoFx,
    onDownloadPackage,
    onDownLoadVideo,
} from './index';

sample({
    clock: onDownLoadVideo,
    target: downloadVideoFx
});

sample({
    clock: onDownloadPackage,
    source: $currentPackage,
    target: downloadPackageFx
});
